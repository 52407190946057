// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap {
  width: 1838px;
  padding: 34px 64px 64px;
}

.headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;

  .title {
    font-size: 20px;
    font-family: Poppins-SemiBold;
    color: #3A574E;
  }

  .unassigned {
    flex-shrink: 0;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px 0 16px;
    // min-width: 130px;
    height: 31px;
    border-radius: 47px;
    background: #fff5f5;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    color: #3A574E;
    font-size: 14px;
    font-weight: 600;

    .badge {
      margin-left: 8px;
      padding: 0 2px;
      border-radius: 55px;
      line-height: 22px;
      min-width: 22px;
      text-align: center;
      background: #ff6161;
      color: #fff;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .budget {
    display: flex;
    align-items: center;
    margin-left: 14px;
    padding: 0 10px;
    height: 31px;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 6px 22px 0px rgba(58, 87, 78, 0.1);
    font-size: 14px;
    font-weight: 600;

    > i {
      margin: 0 10px;
      width: 1px;
      height: 20px;
      background: rgba(58, 87, 78, 0.1);
    }

    .currency {
      font-size: 18px;
      font-weight: 700;
    }
  }
}
