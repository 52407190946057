// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.select-suffix {
  position: relative;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 12px solid #fff;
    position: relative;
    margin-left: 70%;
    display: block;
    z-index: 1;
  }

  .rc-virtual-list {
    overflow: hidden;
    position: relative;
    background: #fff;
    border-radius: 0.09896rem;
    box-shadow: 0 0.01563rem 0.03125rem -0.02083rem rgba(0, 0, 0, 0.12),
      0 0.03125rem 0.08333rem 0 rgba(0, 0, 0, 0.08),
      0 0.04688rem 0.14583rem 0.04167rem rgba(0, 0, 0, 0.05%);
  }
}

.custom-select-dropdown {
  * {
    pointer-events: auto !important;
  }
  .selection-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px 0 14px;
    height: 36px;
    font-size: 18px;

    svg {
      display: none;
      margin-left: 14px;
      width: 26px;
    }
  }
  .ant-select-item-option {
    padding-left: 20px;
    line-height: 36px;
    font-size: 18px;
  }
  .ant-select-item-option-active {
    background: rgba(48, 217, 136, 0.1) !important;

    &:not(.ant-select-item-option-selected) {
      .selection-item svg {
        display: block;
      }
    }
  }

  .ant-select-item-option-state {
    margin-right: 14px;
    font-size: 20px;
  }
}

.custom-modal {
  width: fit-content !important;

  .ant-modal-content {
    border-radius: 20px;
    overflow: hidden;
  }
  .ant-modal-body {
    padding: 0;
  }

  &-title {
    position: relative;
    display: inline-block;
    font-size: 31px;
    font-weight: 600;
    line-height: 1;

    > span {
      position: relative;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -2px;
      right: -2px;
      height: 14px;
      background: var(--brand-color);
    }
  }
}

.ant-modal-centered .ant-modal {
  transform-origin: 50% 50% !important;
}

.ant-modal-confirm-custom {
  width: 874px !important;

  &.no-footer {
    .ant-modal-confirm-btns {
      display: none;
    }
  }

  .ant-modal-content {
    padding: 80px 100px 40px;
    border-radius: 20px;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-confirm-title {
    line-height: 35px;
    font-size: 30px;
    font-weight: 900;
    color: #344438;
  }
  .ant-modal-confirm-content {
    margin-top: 20px;
    line-height: 23px;
    font-size: 20px;
    color: #6e746f;
  }
  .ant-modal-footer,
  .ant-modal-confirm-btns {
    border: none;
    margin-top: 60px;
    padding: 0;
    .ant-btn {
      padding: 0 40px;
      height: 40px;
      border-radius: 40px;
      font-size: 16px;
      font-weight: 700;
      + .ant-btn {
        margin-left: 12px;
      }
    }
    .ant-btn-default {
      border: none;
      background: #efeff3;
      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
  }
}

.confirm-dailog__custom {
  width: 544px !important;
  background-color: #fff;
  padding-bottom: 0;
  border-radius: 27px;
  overflow: hidden;

  &.confirm-dailog__delete {
    .ant-modal-content {
      background: rgba(240, 95, 95, 0.1) !important;
    }

    .ant-btn.ant-btn-primary {
      margin-left: 0;
      background-color: #f05f5f;
    }
  }

  &.confirm-dailog__warn {
    .ant-modal-content {
      background: rgba(245, 181, 68, 0.1) !important;
    }

    .ant-btn.ant-btn-primary {
      margin-left: 0;
      background-color: #f5b544;
    }
  }

  &.confirm-dailog__success {
    .ant-modal-content {
      background: rgba(0, 185, 124, 0.1) !important;
    }

    .ant-btn.ant-btn-primary {
      margin-left: 0;
      background-color: #00b97c;
    }
  }

  .ant-modal-body {
    padding: 27px;

    .ant-modal-confirm-body {
      display: flex;

      > img {
        margin-top: 7px;
        @include wh(27px, 27px);
      }
    }

    .ant-modal-confirm-content {
      margin-top: 0;
      margin-left: 35px;

      .confirm-dailog__title {
        font-size: 24px;
        font-family: Poppins-SemiBold;
        font-weight: bold;
        color: #171625;
      }

      .confirm-dailog__subtitle {
        margin-top: 4px;
        font-size: 20px; //18px
        font-family: Inter-Regular, Inter;
        font-weight: 400;
        color: #5a6771;
      }
    }
  }

  .ant-modal-confirm-btns {
    float: none;
    margin-left: 0;
    margin-top: 28px;
    display: flex;
    flex-direction: row-reverse;
    transform: translate(-21%, 0);
    .ant-btn {
      margin-right: 13px;
      border-radius: 10px;
      border: none;
      color: #171625;
      height: auto;
      font-size: 24px;
      font-family: Poppins-SemiBold;
      line-height: 40px;
      background-color: #fff;
      width: 120px;
      &:hover {
        color: #171625;
      }

      &.ant-btn-primary {
        color: #fff;
      }
    }
  }
}

.antd-message__custom {
  .ant-message-notice-content {
    background-color: #44444f;
    min-width: 449px;
    padding: 26px;
    border-radius: 10px;
  }

  .ant-message-custom-content {
    @include sizeColor(24px, #e2e2ea);
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    line-height: 41px;
    display: flex;
    align-items: center;

    img {
      margin-right: 27px;
      @include wh(27px, 27px);
    }
  }
}

.ant-modal-close {
  top: 8px;
  right: 12px;
  opacity: 0.6;
  transition: all 0.3s;
  &:hover {
    opacity: 1;
  }
}

.ant-message {
  z-index: 1055;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 185, 124, 0.85);
  font-weight: 600;
  background: none !important;
}

.ant-modal-close-icon svg {
  display: none;
}

.ant-modal-close-icon {
  background-image: url('../assets/images/dashboard/close.png');
  background-size: 100%;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 24px;
  right: 18px;
}

.ant-spin {
  max-height: 100vh !important;
}

.ant-upload-list,
.ant-upload-list-text {
  display: none !important;
}

.ant-picker-panel-container {
  border-radius: 16px;
}

.ant-picker-header-view button {
  font-weight: bold;
  transform: translateY(2px);
}

.ant-picker-cell .ant-picker-cell-inner {
  font-family: Inter-Regular, Inter;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: rgba(0, 185, 124, 0.1);
}

.ant-picker-cell {
  padding: 6px 0;
  font-size: 14px;
}

.ant-form-vertical .ant-form-item-row {
  display: flex;
  flex-direction: column;
}

.ant-input {
  font-size: 14px;
}

.ant-empty-normal {
  padding: 29px 0;
  border: none;
}

.ant-modal-wrap,
.ant-modal-mask {
  z-index: 1051;
}

.ant-modal-mask{
  background: rgba(80,88,135,0.42);

}

.ant-upload {
  font-size: inherit;
}

.ant-radio-checked .ant-radio-inner:after {
  transform: scale(1);
}
.ant-radio-inner::after {
  margin-top: 0;
  margin-left: 0;
}

.ant-checkbox-inner {
  width: 22px;
  height: 22px;
}

.ant-form-item-label {
  overflow: visible;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: absolute;
  left: -10px;
}

.ant-progress-success-bg, .ant-progress-bg {
  background-color: #00b97c;
}

.ant-btn > .ant-btn-loading-icon {
  vertical-align: middle;

  .anticon {
    display: block;
  }
}

.ad-frame-wrap {
  width: 520px;
  height: 100%;
}

.ads-modal {
  .ant-modal-content{
    border-radius: 20px;
    padding:50px;
    .ant-modal-body{
      padding: 0;
    }
    .ant-modal-close{
      top: 50px;
      right: 50px;
      .ant-modal-close-x{
        width: 20px;
      }
    }
  }
}

.ant-btn > .ant-btn-loading-icon {
  vertical-align: middle;

  .anticon {
    display: block;
  }
}

.ant-spin-blur {
  opacity: 1;
}

.ant-btn[disabled] {
  background: #D9D9D9 !important;
  border-color: #D9D9D9 !important;
  color: #fff !important;
}
