// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap {
  display: flex;
  // align-items: center;
  height: 100px;
  margin-top: 60px;
  line-height: 1;

  > * {
    flex: 1;
  }
}

.platform {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 35px;
  // height: 128px;
  border-radius: 12px;
  border: 1px solid #e7eaf1;
  color: #333;
  font-size: 16px;
  font-weight: 700;
  font-family: Poppins-SemiBold;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    .recommend,
    .current {
      display: none;
    }
  }

  &.active {
    border-color: #3dd994;
    background: rgba(61, 217, 148, 0.1);

    .check {
      display: block;
    }
  }

  .check {
    display: none;
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    width: 20px;
  }

  .name {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    font-family: Poppins-SemiBold;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: #f8fafc;

    svg {
      width: 20px;
    }
  }

  .recommend {
    margin-left: auto;
    font-size: 12px;
    font-weight: 600;
    color: #a23fee;
  }

  .total {
    margin-top: 16px;
    font-size: 32px;
  }

  .current {
    margin-top: 7px;
    font-size: 12px;
    color: #000;
  }
}

.items {
  // margin-left: 120px;
  display: flex;
}

.item {
  position: relative;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 35px;
  // width: 144px;
  // height: 128px;
  border-radius: 8px;

  &:nth-of-type(1) {
    margin-left: 0;
    background: #ebfff6;
    color: var(--brand-color);
  }
  &:nth-of-type(2) {
    background: #fffbeb;
    color: #ffd234;
  }
  &:nth-of-type(3) {
    background: #ffefef;
    color: #ff6161;
  }
  &:nth-of-type(4) {
    background: #e8e8e8;
    color: #999;
  }

  .dot {
    position: absolute;
    top: 10px;
    right: 8px;
    height: 8px;
    cursor: pointer;
  }

  .data {
    // margin: 26px 0 0 26px;
    // display: flex;
    // align-items: flex-end;
    // transform: translateX(-50px);
    margin-right: 10px;

    strong {
      font-size: 24px;
      font-family: Poppins-SemiBold;
    }

    .trend {
      margin-left: 12px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 700;
      transform: translateY(60%);

      &.up {
        color: var(--brand-color);
      }
      &.down {
        color: #ff6161;
      }

      svg {
        margin-right: 2px;
        width: 8px;
        transform: rotateY(180deg);
      }
    }
  }

  .title {
    // margin-top: 8px;
    font-size: 16px;
    font-weight: 700;
    font-family: Poppins-SemiBold;
    color: #3a574e;
    text-align: center;
  }
}

.popover {
  :global {
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      border-radius: 12px;

      &-content {
        padding: 0;
      }
    }
  }
}

.prompt {
  padding: 24px 24px 18px;
  width: 420px;

  .headline {
    display: flex;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #ffe9d9;

      &.good::before {
        content: '👍';
        font-size: 40px;
        transform: translate(0, -5px) rotate(-20deg);
      }
      &.bad::before {
        content: '👎';
        font-size: 40px;
        transform: translate(0, 5px) rotate(-20deg);
      }
    }
  }

  .title {
    margin: 8px 0;
    line-height: 1;
    font-size: 16px;
    font-weight: 700;
    color: #333;
  }

  .subtitle {
    min-height: 24px * 1.4;
    line-height: 1.4;
    font-size: 12px;
    color: #666;
  }

  .content {
    margin-top: 13px;
    padding-left: 56px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #666;

    em {
      font-size: 16px;
      font-weight: 700;
      color: #333;
    }
  }

  .ipt {
    margin: 0 14px;
    width: 80px;

    :global {
      .ant-input-number {
        width: 100%;
        height: 32px;
        border-radius: 8px;
        border: 0;
        background: #f5f5f5;
      }

      .ant-input-number-input,
      .ant-input-number-input-wrap {
        height: 100%;
        color: #000;
        font-size: 16px !important;
        font-weight: 700 !important;
      }
    }
  }

  .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    :global {
      .ant-btn {
        height: 32px;
        border-radius: 8px;
        font-size: 16px;

        &-default {
          margin-right: 12px;
          background: #f5f5f5;
          border-color: #f5f5f5;
          color: #333;
        }
      }
    }
  }
}
