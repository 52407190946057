// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1.3fr 0.625fr;
  grid-template-rows: 214px;
  column-gap: 16px;
}

.item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 13px 0 18px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 6px 22px 0px rgba(58, 87, 78, 0.1);

  .title {
    font-size: 20px;
    font-weight: 600;
  }

  .desc {
    font-size: 18px;
    color: rgba(137, 137, 137, 0.6);
  }

  &:nth-child(n + 1) {
    flex-direction: column;
    align-items: stretch;
    padding-top: 18px;
    padding-bottom: 30px;

    .title {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 700;

      svg {
        margin-right: 12px;
        width: 48px;

        [fill='#3a574e'] {
          fill: var(--primary-color, var(--brand-color));
        }

        .autoMode & [fill='#EFEFEF'] {
          fill: #EBFFF6;
        }
      }
    }

    .details {
      display: flex;
    }

    .part {
      flex: 1;
      text-align: center;

      &:first-child {
        .value {
          color: var(--primary-color, var(--brand-color));
        }
      }
      &.hidden {
        flex: 0;
        width: 0;
        opacity: 0;
        white-space: nowrap;
        overflow: hidden;
      }

      + .part {
        border-left: 1px dashed #dadada;
      }

      &:last-child {
        .value {
          color: #b6b6b6;
        }
      }

      &.pr {
        position: relative;

        .label {
          position: absolute;
          bottom: 0;
          width: 100%;
          text-align: center;
        }
      }
    }

    .value {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      font-weight: 700;
      line-height: 28px;
      height: 28px;
    }

    .label {
      margin-top: 32px;
      margin-bottom: 5px;
      line-height: 1;
      font-size: 20px;
      font-weight: 500;
      color: rgba(137, 137, 137, 0.6);
    }

    .auto {
      margin-left: 12px;
      width: 22px;
    }
    .lock,
    .manual {
      margin-left: 15px;
      width: 16px;
    }
  }
  &:last-child {
    .details {
      margin-bottom: 22px;
      margin-left: 8px;
    }
  }
}

.dropdownTrigger {
  display: flex;
  align-items: center;
  padding: 0 9px 0 12px;
  height: 44px;
  border-radius: 8px;
  // background: rgba(61, 217, 148, 0.1);
  background: rgba(58, 87, 78, 0.1);
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-color, var(--brand-color));
  cursor: pointer;

  .autoMode & {
    background: rgba(61, 217, 148, 0.1);
  }

  .icon [fill],
  .arrow [fill] {
    fill: currentColor;
  }
  .icon {
    width: 18px;
  }
  .arrow {
    width: 12px;
  }
  .text {
    margin: 0 12px 0 16px;
  }

  .meta {
    width: 27px;
    + .google {
      margin-left: 2px;
    }
  }
  .google {
    width: 22px;
  }
}
.unusable .dropdownTrigger {
  background: #ebeeed;
  color: #b6b6b6;
  cursor: not-allowed;
}

.dropdown {
  :global {
    .ant-dropdown-menu {
      padding: 6px 0;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(150, 150, 150, 0.25);

      &-item {
        padding: 8px 20px;
        color: var(--primary-color, var(--brand-color));

        &-disabled {
          color: #b6b6b6 !important;
        }
      }
    }
  }

  .menuItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
  }

  .auto {
    width: 18px;
  }

  .manual {
    // margin-right: 2px;
    width: 16px;
  }
}

.editable {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 39px;
  min-width: 118px;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 0px rgba(220, 220, 220, 0);
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: #fff;
    box-shadow: 0px 4px 4px 0px #dcdcdc;
  }

  &.disabled {
    pointer-events: none;
    color: #b6b6b6 !important;
  }

  &Input {
    height: 39px;
    border-radius: 20px;
    font-size: 20px !important;

    :global {
      .ant-input-number-input-wrap,
      .ant-input-number-input {
        height: 100%;
      }
    }
  }
}

.allocation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  width: 257px;
  height: 66px;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 0px rgba(220, 220, 220, 0);
  transition: all 0.3s;

  &:hover {
    background: #fff;
    box-shadow: 0px 4px 4px 0px #dcdcdc;

    :global {
      .ant-slider-handle {
        opacity: 1;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    color: #b6b6b6;

    .allocationHint > span {
      color: #b6b6b6;
    }

    :global {
      .ant-slider-rail {
        background: #e9e9e9 !important;
      }
      .ant-slider-track {
        background-color: #3a574e !important;
      }
    }
  }

  &Hint {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;

    > span:last-child {
      color: #3a574e;
    }

    > svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-left: 0 !important;

      &.lock {
        cursor: pointer;
      }
    }
  }

  &Slider {
    margin: 0;
    padding: 0;
    height: 11px;

    :global {
      .ant-slider-rail,
      .ant-slider-track {
        height: 100%;
        border-radius: 8px;
      }
      .ant-slider-rail {
        background: #E5F2EC !important;
      }
      .ant-slider-track {
        background-color: var(--primary-color, var(--brand-color)) !important;
      }
      .ant-slider-handle {
        opacity: 0;
        margin-top: -4px;
        width: 19px;
        height: 19px;
        border: solid 4px var(--primary-color, var(--brand-color)) !important;
        box-shadow: none;
      }
    }
  }
}

.modal {
  width: 1008px !important;

  :global {
    .ant-modal-content {
      border-radius: 20px;
    }

    .ant-modal-body {
      padding: 98px 0 93px;
    }

    .ant-modal-confirm-body,
    .ant-modal-confirm-btns {
      text-align: center;
    }

    .ant-modal-confirm-title {
      line-height: 1;
      font-size: 42px;
      font-weight: 600;
      color: #374551;
    }

    .ant-modal-confirm-content {
      margin-top: 30px;
      margin-left: 0;
      line-height: 1;
      font-size: 18px;
      font-style: italic;
      color: #a3a9af;
    }

    .ant-modal-confirm-btns {
      margin-top: 52px;

      .ant-btn {
        width: 184px;
        height: 58px;
        border-radius: 58px;
        font-size: 24px;
        font-weight: 500;

        &-default {
          background: #e7e8e9;
          border-color: #e7e8e9;
          color: #3a574e;
          box-shadow: none;
        }

        + .ant-btn {
          margin-left: 42px;
        }
      }
    }
  }
}

.dailyBudget {
  padding: 89px 222px 79px;
  width: 1066px;
  line-height: 1;

  .title {
    font-size: 42px;
    font-weight: 600;
  }

  .subtitle {
    margin-top: 25px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-style: italic;
    color: #a3a9af;

    .current {
      margin: 0 4px;
      padding: 0 10px;
      line-height: 30px;
      border-radius: 8px;
      background: #000;
      box-shadow: 0px 1px 15px 0px rgba(157, 157, 157, 0.25);
      font-size: 20px;
      font-weight: 700;
      color: #fff;
    }
  }

  .content {
    margin-top: 35px;
    display: flex;
    align-items: center;
    padding: 0 45px 0 83px;
    height: 173px;
    border-radius: 12px;
    border: 2px solid #3dd994;

    .value {
      display: block;
      padding: 0;
      width: 200px;
      height: 48px;
      font-size: 48px;
      font-weight: 700;
      color: #8F8F8F;
    }

    .suggested {
      margin-top: 18px;
      font-size: 18px;
      font-weight: 600;
      color: #C9D4E4;
    }

    .actions {
      margin-left: auto;
    }

    svg {
      width: 52px;

      &:first-of-type {
        cursor: pointer;
      }
      &:last-of-type {
        margin-left: 30px;
      }
    }
  }

  .confirm {
    display: block;
    margin: 76px auto 0;
    width: 184px;
    height: 58px;
    border-radius: 58px;
    font-size: 24px;
    font-weight: 700;
  }

  :global {
    .ant-input-number-input-wrap,
    .ant-input-number-input {
      display: block;
      padding: 0;
      height: 100%;
      font-weight: 700;
    }
  }
}
