// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.calendar {
  margin-right: -10px;
  width: 24px;

  [fill="#3A574E"] {
    fill: var(--primary-color, var(--brand-color));
  }
}

.picker {
  display: flex;
  align-items: center;

  :global {
    .ant-picker-input > input {
      opacity: 0;
      font-size: 14px;
      font-weight: bold;
      color: #3A574E;
      text-align: center;
    }
    .ant-picker-range-separator {
      padding: 0;
      color: #3A574E;
      font-weight: bold;
    }
  }
}

.label {
  position: relative;
  line-height: 1;
  left: 10px;
  font-size: 20px;
  font-weight: bold;
}

.value {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 0.05729rem;
  display: flex;
  align-items: center;
  color: var(--primary-color, var(--brand-color));

  > span {
    width: 0.775rem;
    line-height: 1;
    font-size: 14px;
    font-weight: bold;
    text-align: center;

    + span {
      margin-left: 4px;
    }
  }
}

.dropdown :global(.ant-picker-range-arrow) {
  display: none;
}

.panel {

  .body {
    display: flex;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;

    .date {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #000;

      > span {
        width: 7em;
        text-align: center;
      }
    }

    .empty {
      color: #ccc;
    }

    button {
      border-radius: 8px;
      // width: 75px;
      // height: 38px;
      // font-size: 14px;

      &:first-of-type {
        margin-left: 13px;
        border: none;
        background: #f2f2f2;
        color: #272829;
        &::after {
          display: none;
        }
      }

      &:last-of-type {
        margin-left: 22px;
      }

      &[disabled] {
        pointer-events: none;
      }
    }
  }

  :global {
    .ant-picker-panel {
      border: 0;
    }

    .ant-picker-header {
      border: 0;
    }

    .ant-picker-footer {
      position: relative;
      width: 160px;
      padding-top: 8px;
      min-width: auto;

      &::before {
        content: 'Date Presets';
        display: block;
        padding-left: 37px;
        line-height: 30px;
        text-align: left;
        font-size: 15px;
        font-weight: bold;
        color: #000;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 13px;
        bottom: 0;
        border-right: 1px solid #dedede;
      }
    }

    .ant-picker-ranges {
      padding-left: 0;
      padding-right: 0;

      .ant-picker-preset {
        position: relative;
        display: block;

        &:hover {
          background: #eee;
        }
      }

      .ant-tag {
        display: block;
        border: none;
        background: none;
        border-radius: 0;
        margin: 0;
        padding-left: 37px;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        line-height: 33px;
      }
    }

    @for $i from 1 through 5 {
      [data-active-preset='#{$i}'] .ant-picker-preset:nth-child(#{$i}) {
        background: rgba(61, 217, 148, 0.40) !important;

        &::before {
          content: '';
          @include icon('~@/assets/icons/check2.svg', 12px, 8px);
          position: absolute;
          left: 14px;
          top: 13px;
        }
      }
    }
  }
}