// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap {
  position: relative;
  // display: flex;
  // flex-direction: column;
  padding: 0 24px;
  height: 100%;
  overflow: auto;
  transition: all .3s;

  :global {
    .ant-switch-checked {
      background-color: var(--primary-color, var(--brand-color));
    }
  }
}

.content {
  // flex: 1;
  position: relative;
  margin-top: 23px;
  margin-bottom: 16px;
  padding: 20px 42px;
  min-height: calc(100% - 40px);
  border-radius: 20px;
  background: #fff;
}

.spin {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 200px;
  z-index: 10;

  + div {
    filter: blur(2px);
    opacity: 0.5;
    pointer-events: none;
  }
}

.statusBar {
  margin: 10px 0 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.filters {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  height: 24px;
}

.filter {
  position: relative;
  display: inline-block;
  line-height: 24px;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;

  + .filter {
    margin-left: 80px;
  }

  &.active {
    color: var(--brand-color);

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      color: var(--brand-color);
      background: currentColor;
    }
  }
}

.thresholds {
  margin-left: auto;
  margin-right: 24px;
  display: flex;
}

.threshold {
  display: flex;
  align-items: center;
  height: 46px;
  padding: 0 14px;
  border-radius: 12px;
  background: #F1F1F1;
  // box-shadow: 0px 6px 22px 0px rgba(58, 87, 78, 0.1);

  + .threshold {
    margin-left: 19px;
  }

  .label {
    margin-right: 13px;
    padding-right: 13px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    border-right: 1px solid rgba(58, 87, 78, 0.1);
  }

  .value {
    font-size: 18px;
    font-weight: 700;
  }
}

.quene {
  .separator {
    position: relative;
    margin: 40px 0;
    border-top: 1px dashed #d9d9d9;

    > span {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      padding: 0 24px;
      line-height: 26px;
      font-size: 14px;
      color: rgba(58, 87, 78, 0.5);
      background: #f1f1f1;
      border-radius: 26px;
      z-index: 10;
    }
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    > span {
      margin-right: 28px;
      width: 129px;
      line-height: 44px;
      border-radius: 12px;
      border: 1px solid #d9d9d9;
      background: #f3f3f3;
      color: rgba(137, 137, 137, 0.6);
      font-size: 20px;
      font-weight: 700;
      text-align: center;
    }

    > p {
      font-size: 20px;
      color: #5c5c5c;
    }
  }
}

.dropdown {
  :global {
    .ant-dropdown-menu {
      padding: 6px 0;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(150, 150, 150, 0.25);

      &-item {
        padding: 8px 20px;
        color: #3a574e;

        &-active {
          color: var(--brand-color);
        }
      }
    }
  }

  .menuItem {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
  }

  .icon {
    margin-right: 10px;
    width: 18px;
  }
}

.eventsSelect {
  display: flex;
  align-items: center;
  margin-right: 32px;

  .addonBefore {
    margin-right: 12px;
    font-size: 0;

    svg {
      width: 21px;
    }
  }

  .addonAfter {
    margin-left: 12px;

    button {
      margin-right: 6px;
      border-radius: 8px;
      height: 40px;
      font-weight: bold;
    }
  }

  :global {
    .ant-select {
      width: 300px;
    }
    .ant-select-selector {
      height: 40px !important;
      border-radius: 8px !important;
      &:not(:hover) {
        border-color: #f0f0f0;
      }
    }
    .ant-select-selection-item {
      line-height: 38px !important;
      font-size: 14px !important;
      font-weight: 700 !important;
      color: #000;
    }
    .ant-select-multiple .ant-select-selection-item {
      margin: 0;
      padding: 0 0 0 4px;
      border: 0;
      // padding-left: 13px;
      // padding-right: 8px;
      font-size: 20px;
      font-weight: 700;
      height: 33px;
      line-height: 33px !important;
      // border-radius: 12px;
      // background: #3a574e;
      // color: #fff;
      background: none;
      color: #000;
    }
    .ant-select-selection-item-remove {
      &::after {
        content: '×';
        margin-left: 4px;
        font-size: 22px;
        font-weight: 900;
        color: #fff;
        opacity: 0.8;
      }
      .anticon {
        display: none;
      }
    }
  }
}

.selectDropdown :global {
  .ant-select-item-option {
    position: relative;
    padding-right: 50px;
  }

  .ant-select-item-option-state {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);

    .anticon {
      display: block;
    }
  }
}
