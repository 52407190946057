// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.onboard{
  // max-height: 100%;
  // overflow-y: scroll;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 80px;
  .input{
    position: relative;
    border-radius: 12px;
    border: 1px solid #D9D9D9;
    height: 50px;
    margin-top: 20px;
    input{
      height: 48px;
      @include sizeColor(14px,#000);
      font-family: Poppins-SemiBold;
      padding-right: 60px;
      padding-left: 20px;
    }
    .text{
      padding: 0 18px;
      line-height: 48px;
      height: 100%;
      @include sizeColor(14px,#000);
      font-family: Poppins-SemiBold;
    }
    svg{
      @include abTr(50%,15px);
      @include wh(15px!important,15px);
      transform: translate(0,-50%);
      margin: 0!important;
      cursor: pointer;
    }
  }
 
  .txtDisabled{
    filter: grayscale(1);
    opacity: 0.6; 
    pointer-events: none;
    cursor:not-allowed;
  }
  .done{
    @include abTr(0,3px);
    width: 67px!important;
    margin:0!important;
  }
  .title{
    font-weight: 600;
    font-size: 16px;
  }
  .platform{
    .item{
      @include wh(295px,56px);
      @include flexCenter();
      border-radius: 8px;
      border: 1px solid #C4C4C4;
      @include sizeColor(15px,#000);
      font-family: Poppins-SemiBold;
      margin: 0 auto;
      margin-top: 25px;
      cursor: pointer;
      &.active{
        border: 1px solid var(--brand-color);
        background: #D7FFED;
      }
      
      span{
        width: 195px;
        margin: 0 10px;
        text-align: center;
      }
      svg{
        width: 24px!important;
        margin: 0!important;
      }
    }
  }
  .select{
    .title{
      margin-bottom: 20px;
    }
    &Box{
      @include flexBetween();
    }
    // p{
    //   font-family: Poppins-SemiBold;
    .verifyIcon{
      width: 25px!important;
      margin:0 10px!important;
      vertical-align: middle;

      &.fail{
        color: #ff4d4f;
      }
      &.success{
        color:var(--brand-color);
      }
    }
   
    //   padding-top: 5px;
    // }
    :global{
      .ant-select{
        width: 100%;
        .ant-select-selector{
          height: 50px;
          border-radius: 12px;
          border: 1px solid #D9D9D9;
          background: #FFF;
          .ant-select-selection-item{
            line-height: 48px;
            @include sizeColor(14px,#000);
            font-family: Poppins-SemiBold;
          }
        }
        .ant-select-arrow{
          span{
            display: none;
          }
          @include bgCover('~@img/arrow-2.png');
          @include abTr(50%, 20px);
          transform: translate(0, -50%);
          @include wh(16px, 7px);
          margin-top: 0;
        }
      }
    }
    &Btn{
      @include wh(207px,52px);
      margin: 25px auto 0 auto;
      display: block;
    }
  }
  .about{
 
    .line{
      @include flexStart();
      .remove{
        width: 32px;
        cursor: pointer;
      }
    }
    .info{
      margin-top: 6px;
      border-radius: 10px;
      border: 1px solid #DEE3EA;
      background: #FFF;
      padding: 10px 25px 30px;
      flex: 1;

      :global{
        .ant-form-item{
          margin-bottom: 0;
          flex: 1;
        }
        .ant-form-item-label{
          line-height: 50px;
          width: 90px;
        }

        .ant-input-textarea::after {
          // position: relative;
          // top: -20px;
          // transform: translate(-6px, -24px);
          color: #999;
        }
      }
      input{
        border-radius: 12px;
        border: 1px solid #D9D9D9;
        height: 50px;
      }

      textarea{
        border-radius: 12px;
        border: 1px solid #D9D9D9;
      }

      .website{
        display: flex;
        margin: 30px 0;
      
        button{
          height: 40px;
          margin-top: 5px;
          margin-left: 10px;
        }
      }

      &Lable{
        @include sizeColor(14px,#85919D);
        margin-bottom: 15px;
        em{
          color:#FF6161;
          margin-right: 5px;
          vertical-align: middle;
        }
      }
    }
    .add{
      width: 235px;
      cursor: pointer;
      margin-top: 12px;
    }
  }
  .submitBtn{
    @include wh(207px,52px);
    margin: 25px auto 0 auto;
    display: block;
    svg{
      width: 24px!important;
      margin: 0!important;
    }
  }
  .complete{
    @include wh(207px,52px);
    border-radius: 8px;
    display: block;
    margin:0 auto;
    span{
      @include sizeColor(16px,#fff);
      font-family: Poppins-SemiBold;
    }
  }

}

.launch{
  @include flexCenter();
  @include abLt(50%,50%);
  transform: translate(-50%,-50%);
  flex-direction: column;
  &Title{
    @include sizeColor(24px,#3A574E);
    font-family: Poppins-SemiBold;
    margin: 5px 0;
  }
  &Desc{
    @include sizeColor(16px,#3A574E);
    font-family: Poppins-SemiBold;
  }
  button{
    margin-top: 30px;
    @include wh(125px,50px);
    border-radius: 12px;
    span{
      @include sizeColor(16px,#fff);
      font-family: Poppins-SemiBold;
    }
  }
  svg{
    width: 225px;
  }
}