// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap {
  padding-bottom: 60px;
  width: 342px;
  height: 100%;
  background: #fff;
  overflow: auto;

  &::-webkit-scrollbar {
    display: block; 
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #d9d9d9;
    &:hover {
      background: #999;
    }
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.group {
  $height: 50px;

  padding-bottom: 24px;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 10px;
    height: 52px;
    border-bottom: 1px solid #e8e8e8;
    font-size: 16px;
    font-family: Poppins-SemiBold;
    color: var(--brand-color);

    &::before {
      content: '';
      margin-right: 7px;
      width: 4px;
      height: 12px;
      border-radius: 20px;
      background: #3dd994;
    }

    > svg {
      margin-left: 8px;
      width: 20px;
      cursor: pointer;
    }
  }

  .control {
    display: flex;
    align-items: center;
    padding: 0 14px 0 19px;
    height: $height;
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    font-size: 16px;
    color: #000;

    &.media {
      border-color: transparent;
      background: rgba(61, 217, 148, 0.1);
      cursor: pointer;

      > svg {
        margin-left: 12px;
        width: 16px;
      }
    }

    &.btn {
      cursor: pointer;
      transition: all .3s;

      &:hover,
      &.active {
        border-color: var(--brand-color);
        color: var(--brand-color);

        svg {
          display: block;
        }
      }

      svg {
        display: none;
      }
    }
  }

  .checkboxBtn {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: $height;
    border-radius: 12px;
    border: 1px solid #ECFBF4;
    background: #ECFBF4;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    cursor: pointer;

    &.disabled {
      border-color: #d9d9d9;
      background: none;
      color: #BDC5CC;
      cursor: not-allowed;
    }
  
    > svg:first-child {
      width: 20px;
    }

    > span {
      margin-right: 24px;
      flex: 1;
      text-align: center;
    }

    &Check {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
    }
  }

  .lock {
    display: block;
    height: 16px;
  }

  .arrow {
    margin-left: auto;
    height: 22px;
  }

  .flexBox {
    display: flex;

    &.compact {
      // margin-bottom: 18px;

      > div + div {
        margin-left: 10px;
      }
    }

    > div {
      flex: 1;
      min-width: 0;

      + div {
        margin-left: 18px;
      }
    }
  }

  :global {
    .ant-form {
      padding: 0 16px;
    }

    .ant-form-item {
      margin-bottom: 24px;

      &-label {
        > label {
          font-size: 16px;
          color: #3a574e;
          white-space: nowrap;
        }
      }
    }

    .ant-input,
    .ant-input-number {
      border-radius: 12px !important;
      width: 100%;
      height: $height;
      font-weight: 700;
    }

    .ant-input-disabled {
      color: #000;
    }

    .ant-input-number-input,
    .ant-input-number-input-wrap {
      height: 100%;
      color: #000;
      font-size: 16px !important;
      font-weight: 700 !important;
    }

    .ant-select-selector {
      border-radius: 12px !important;
      min-height: $height !important;
    }
    .ant-select-selection-item {
      line-height: 46px !important;
      font-size: 14px !important;
      font-weight: 700 !important;
      color: #000;
    }

    .ant-select-multiple .ant-select-selection-item {
      margin: 0;
      padding: 0 0 0 4px;
      border: 0;
      // padding-left: 13px;
      // padding-right: 8px;
      font-size: 20px;
      font-weight: 700;
      height: 33px;
      line-height: 33px !important;
      // border-radius: 12px;
      // background: #3a574e;
      // color: #fff;
      background: none;
      color: #000;
    }
    .ant-select-selection-item-remove {
      &::after {
        content: '×';
        margin-left: 4px;
        font-size: 22px;
        font-weight: 900;
        color: #fff;
        opacity: 0.8;
      }
      .anticon {
        display: none;
      }
    }

    .ant-switch {
      margin-left: auto;
    }

    .ant-form-item-explain-error {
      width: fit-content;
      white-space: nowrap;
    }
  }
}

.btns {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  :global {
    .ant-btn {
      padding: 0 10px;
      height: 38px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: bold;

      + .ant-btn {
        margin-left: 15px;
      }
    }
  }
}

.selectDropdown :global {
  .ant-select-item-option {
    position: relative;
    padding-right: 50px;
  }

  .ant-select-item-option-state {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);

    .anticon {
      display: block;
    }
  }
}

.dot {
  margin-left: 8px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #FF6161;
  cursor: pointer;
}

.suffixIcon {
  display: block;
  width: 12px;
  cursor: pointer;
}
