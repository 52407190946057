// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.create{
 
  &.active{
    .createTitle{
      span{
        background: rgba(61, 217, 148, 0.30);
        color: var(--brand-color);
        svg{
          path{
            fill: var(--brand-color);
          }
        }
      }
    }

    .createMain{
      background: var(--brand-color);
      span{
        color: var(--brand-color);
        svg{
          path{
            fill: var(--brand-color);
          }
        }
      }
    }
  }

  button{
    @include wh(186px,42px);
    border-radius: 8px;
    background: #FFD234;
    margin: 28px auto;
    border:none;
    display: block;

    span{
      @include sizeColor(16px,#fff);
      font-family: Poppins-SemiBold;
    }
  }

  &Title{
    margin-bottom: 9px;
    @include flexBetween();
    overflow: hidden;

    .title{
      width: 800px;
      overflow:hidden;
      display: inline-block;
      text-overflow:ellipsis; 
      white-space:nowrap;
      @include sizeColor(18px, #3A574E);
    }

    div{
      @include flexStart();
      span{
        border-radius: 9px;
        background: #F5F5F5;
        padding: 5px 12px;
        @include flexCenter();
        @include sizeColor(14px,#ACACAC);
        svg{
          width: 14px;
          margin-right: 14px;
        }
      }
      > svg{
        width: 32px;
        margin-left: 15px;
      }
    }

   
  }
  .line{
    @include flexBetween();
    .type{
      flex: 1;
      margin-right: 13px;
      + .type{
        margin-right: 0;
      }
    }
  }
  
  .type{
    border-radius: 10px;
    background: #F9F9F9;
    padding: 5px 24px;
    @include flexStart();
    margin-bottom: 15px;

    &Icon{
      @include flexCenter();
      border-radius: 40px;
      font-family: Poppins-SemiBold;
      @include sizeColor(18px,#fff);
      padding: 5px 15px;
      font-style: italic;

      &.idea{
        background: #3DD994;
      }

      &.variant{
        background: #798EFF;
      }

      svg{
        width: 22px;
        margin-right: 15px;
      }
    }

    .text1{
      > svg{
        width: 30px;
        display: block;
        margin: 0 auto 5px auto;
      }
      white-space:nowrap; 
      color:#949494;
      margin-right: 15px;
      width: 70px;
      text-align: center;
      font-size: 12px;
    }

    .text2{
      white-space:nowrap; 
      padding: 7px 15px;
      border-radius: 20px;
      @include flexStart();
      font-style: italic;
      border-radius: 30px;
      background: rgba(61, 217, 148, 0.10);
      color: var(--antd-wave-shadow-color);

      &.testing{
        background: rgba(121, 142, 255, 0.10);
        color:#798EFF;
        svg{
          path{
            fill: currentColor;
          }
        }
      }

      &.pass{
        background: rgba(132, 150, 171, 0.18);
        color: #8496AB;
      }

      svg{
        width: 16px;
        margin-right: 8px;
      }

    }

    > svg{
      width: 136px;
    }

  }

  .txt{
    margin-top: 20px;
    @include sizeColor(14px,#3A574E);
    font-family: Poppins-SemiBold;
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 4; 
    overflow: hidden; 
    text-overflow: ellipsis; 
  }

  .pics{
    margin:20px 0;
    @include flexStart();
    &.pic{
      @include flexCenter();
      div{
        width: 220px;
      }
    }
    div{
      width: 120px;
      border-radius: 16px;
      margin-right: 15px;
      overflow: hidden;
    }
    img{
      object-fit: cover;
      @include wh(100%,100%);
    }
  }

  
  &Main{
    // border-radius: 10px;
    // background: #798EFF;
    // @include sizeColor(14px,#fff);     
    // padding: 9px 10px;        
    // line-height: 36px;

    // span{
    //   border-radius: 20px;
    //   background: #FFF;
    //   padding: 7px 13px;
    //   @include sizeColor(14px,#798EFF);
    //   font-family: Poppins-SemiBold;
    //   font-style: italic;
    //   vertical-align: middle;
    //   margin-right: 10px;

    //   svg{
    //     width: 16px;
    //     margin-right: 10px!important;
    //     vertical-align: middle;
    //   }
    // }
  }
}

.disabled{
  position: relative;
  &::after{
    background: rgba(143,135,135,0.6);
    @include abLt(0,0);
    @include wh(100%,100%);
    content: '';
    border-radius: 12px;
  }
}