// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.modal {
  display: flex;
  flex-direction: column;
  padding: 30px 49px 50px;
  height: 95vh;
  background: #fff;

  &Header {
    margin-bottom: 13px;
    padding-left: 29px;
    line-height: 1;
    font-size: 14px;
    font-weight: 700;
    // color: #121928;

    > span {
      margin-left: 10px;
      font-size: 16px;
      color: #2dcc98;
    }
  }

  &Footer {
    margin-top: 45px;
    display: flex;
    justify-content: center;

    :global {
      .ant-btn {
        width: 207px;
        height: 52px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 600;

        + .ant-btn {
          margin-left: 72px;
        }
      }
    }
  }

  .wrap {
    padding: 0;
    flex: 1;
    height: auto;
    width: 1739px;
    background: #fff;
    box-shadow: 0px 10px 12px 0px rgba(58, 87, 78, 0.1);
    border-radius: 20px;
    overflow: hidden;

    .main {
      border-radius: 20px;
      background: #fff;

      .header {
        padding-left: 40px;
      }
      .medias {
        padding-left: 40px;
      }
    }
  }
}

.embeded {
  height: 100%;

  .wrap {
    padding: 0;
    height: 100%;
  }
}

.normal {
  .wrap {
    margin-top: var(--header-height);
  }
}

.wrap {
  display: flex;
  padding: 0 30px;
  height: calc(100vh - var(--header-height) - 20px);
  overflow: hidden;
}

.plus {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 3px 8px 0px rgba(194, 194, 194, 0.25);

  svg {
    width: 14px;
  }

  &.lg {
    width: 107px;
    height: 107px;

    svg {
      width: 48px;
    }
  }
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 31px;
  width: 326px;
  background: #fff;
  border-right: 1px solid #ededed;
  border-radius: 20px 0 0 20px;

  .new {
    display: flex;
    align-items: center;
    margin-left: 26px;
    margin-bottom: 18px;
    padding-left: 12px;
    width: 268px;
    height: 58px;
    border-radius: 12px;
    background: var(--brand-color);
    color: #fff;
    font-size: 18px;
    font-weight: 700;

    .plus {
      margin-right: 11px;
      color: var(--brand-color);
    }
  }

  .folders {
    flex: 1;
    align-self: stretch;
    margin: 0 4px 0 26px;
    padding-bottom: 30px;
  }

  .folder {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    // margin-left: 42px;
    padding: 0 42px 0 18px;
    // width: 223px;
    width: 268px;
    height: 60px;
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(194, 194, 194, 0.25);
    background: #f3f6f8;
    border: 1px solid #f3f6f8;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

    svg:first-child {
      flex-shrink: 0;
      width: 25px;
      margin-right: 15px;
    }

    &.checked {
      border-color: #ffe483;
      background: #fff8df;

      svg:first-child path {
        fill: #ffe483;
      }
    }
  }

  .check {
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%);
    width: 27px;
  }
}

.main {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fafbfc;
  border-radius: 0 20px 20px 0;

  .spin {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    ~ * {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .header {
    display: flex;
    align-items: center;
    padding: 22px 87px 18px 74px;

    &::before {
      content: '';
      margin-right: 12px;
      width: 5px;
      height: 17px;
      border-radius: 10px;
      background: #3a574e;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
    }

    .youtube {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 11px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0px 10px 12px 0px rgba(58, 87, 78, 0.1);
      cursor: pointer;

      &:hover {
        background: var(--brand-color);

        > svg [fill] {
          fill: #fff;
        }
      }

      > svg {
        width: 17px;
        [fill] {
          fill: var(--brand-color);
        }
      }
    }

    .inputWrap {
      display: flex;
      margin-left: 11px;

      :global {
        .ant-input {
          height: 32px;
          border-radius: 32px;
          width: 500px;
        }

        .ant-btn {
          margin-left: 8px;
          height: 32px;
          border-radius: 32px;
        }
      }
    }
  }

  .medias {
    flex: 1;
    padding-left: 74px;
    // margin-right: 8px;
  }

  .media {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-right: 24px;
    margin-bottom: 16px;
    width: 256px;
    cursor: pointer;

    :global(.ant-spin-blur::after) {
      pointer-events: none;
    }

    &.error {
      .mediaCard {
        border-color: var(--danger-color);
      }

      .mediaName {
        color: var(--danger-color);
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
    }

    &Card {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 256px;
      height: 256px;
      border-radius: 20px;
      border: 2px solid #fff;
      background: #fff;
      box-shadow: 0px 5px 9px 0px rgba(58, 87, 78, 0.1);

      .check {
        position: absolute;
        top: 12px;
        right: 7px;
        width: 20px;

        &Disabled path {
          fill: #e5e5e5;
        }
      }
    }

    &Name {
      margin-top: 18px;
      padding: 0 20px;
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
      color: #bababa;
      text-align: center;
    }

    .inner {
      position: relative;
      width: 192px;
      height: 192px;
      border-radius: 16px;
      background: #f6f6f6;
      overflow: hidden;
    }

    .thumbnail {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .platforms {
      position: absolute;
      left: 8px;
      top: 8px;
      display: flex;

      .platform {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        svg {
          width: 20px;
        }
      }
    }

    .play,
    .clock {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
    }
    .clock {
      width: 74px;
    }

    .resolution {
      position: absolute;
      left: 5px;
      bottom: 9px;
      display: flex;
      align-items: center;
      padding: 0 4px;
      max-width: 150px;
      height: 18px;
      border-radius: 6px;
      background: #3dd994;
      color: #fff;
      font-size: 12px;
      font-weight: 600;

      > svg {
        flex-shrink: 0;
        margin-right: 4px;
        width: 9px;
      }
    }

    .trashcan {
      position: absolute;
      right: 8px;
      bottom: 6px;
      width: 24px;
      cursor: pointer;
      pointer-events: initial;
    }
  }

  .upload {
    cursor: pointer;
  }
}

.fixed {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
  pointer-events: none;

  .back {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
    pointer-events: auto;

    svg {
      width: 7px;
      margin-right: 20px;
    }
  }

  .tip {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 55px;
    width: 454px;
    height: 58px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .img {
    position: absolute;
    left: 20px;
    top: -16px;
    width: 90px;
  }

  .text {
    font-size: 18px;
  }

  .line {
    margin: 0 45px;
    width: 1px;
    height: 32px;
    background: rgba(58, 87, 78, 0.2);
  }

  .btn {
    pointer-events: auto;
    margin-left: 20px;
    padding: 0 16px;
    min-width: 184px;
    height: 58px;
    border-radius: 58px;
    font-size: 24px;
  }
}

.modalBtns {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
  pointer-events: none;

  :global {
    .ant-btn {
      width: 200px;
      height: 40px;
      border-radius: 50px;
      font-size: 18px;
      pointer-events: auto;
    }
  }
}
