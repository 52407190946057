// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap {
  position: relative;
  // display: flex;
  // flex-direction: column;
  height: 100vh;
  width: 100vw;
  padding: 48px 30px;
  border-radius: 0;
  background: #f6f6f6;
  overflow-x: hidden;

  &.imported {
    background: #fffbec;

    :global(.custom-modal-title::before) {
      background: #ffd234;
    }
  }

  .toastWrap {
    position: absolute;
    top: 27px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
    animation-duration: 500ms;
  }
  .toast {
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    height: 36px;
    border-radius: 8px;
    background: #3a574e;
    color: var(--brand-color);
    font-size: 16px;
    font-weight: 700;

    svg {
      margin-right: 8px;
      width: 18px;
    }
  }
}

.modal {
  padding: 50px 55px;
  border-radius: 20px;
  width: 1580px;

  .listHeader {
    margin-top: 50px;

    header {
      border-radius: 10px;
      background: #f3f6f8;
      @include flexBetween();
      padding: 30px;
      line-height: 1;
      span {
        @include sizeColor(22px, #3a574e);
        font-family: Poppins-SemiBold;
        &:nth-of-type(1) {
          flex: 1;
        }
        &:nth-of-type(2) {
          flex: 0.6;
        }
        &:nth-of-type(3) {
          flex: 1.2;
        }
        &:nth-of-type(4) {
          flex: 0.5;
        }
      }
    }
  }
  .listWarp {
    min-height: 100px;
    max-height: calc(100vh - 500px);
    overflow-y: scroll;
  }

  .empty {
    height: 300px;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  // height: 90px;
  // padding: 29px;

  .platform {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    svg {
      width: 26px;
    }
  }

  .status {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-left: 18px;
    padding: 0 6px;
    width: 76px;
    height: 26px;
    border-radius: 9px;
    font-size: 14px;
    font-weight: 700;
    background: #fff;
    color: #acacac;

    &.active {
      background: #b1ffdd;
      color: #3dd994;
    }
    &.paused {
      background: #9b9b9b;
      color: #fff;
    }
    &.error {
      background: #ffe0e0;
      color: #ff6161;
    }
    &.draft {
      background: #fff;
      color: #acacac;
    }

    svg {
      margin-right: 4px;
      width: 11px;
    }

    span {
      flex: 1;
      text-align: center;
    }
  }
}

.body {
  position: relative;
  // margin-right: 43px;
  // padding: 0 24px 23px;

  .alert {
    position: absolute;
    top: 35px;
    right: 260px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 26px;
    border-radius: 9px;
    background: #ffe0e0;
    color: #ff6161;
    font-size: 14px;
    font-weight: 600;
    z-index: 2;

    svg {
      margin-right: 6px;
      width: 12px;
    }
  }
}

// .content {
//   padding: 24px;
//   background: #f6f6f6;
// }

.main {
  display: flex;
  // padding: 24px;
  // background: #f6f6f6;

  .left {
    margin-right: 30px;
    width: 792px;
  }

  .right {
    flex: 1;
  }
}

.btnWrap {
  margin-top: 30px;
  display: flex;
  justify-content: center;

  .cancelBtn,
  .queneBtn,
  .runBtn {
    height: 58px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
  }
  .cancelBtn {
    margin-right: 30px;
    width: 175px;
    // background: #bababa;
    // border-color: #bababa;
    // color: #fff;
  }
  .queneBtn {
    width: 350px;
    background: #ffd234;
    border-color: #ffd234;

    + .queneBtn {
      margin-top: 18px;
    }
  }
  .runBtn {
    margin-left: 10px;
    min-width: 175px;
    background: #ffd234;
    border-color: #ffd234;
  }

  .run {
    // margin-top: 18px;
    position: relative;
    display: flex;

    &Err {
      position: absolute;
      right: -22px;
      top: 50%;
      transform: translate(100%, -50%);
      display: flex;
      align-items: center;
      padding: 0 17px;
      height: 40px;
      border-radius: 12px;
      background: rgba(255, 97, 97, 0.2);
      color: #ff6161;
      font-size: 20px;

      svg {
        margin-right: 11px;
        width: 16px;
      }
    }

    :global {
      .ant-input-number-group-wrapper {
        width: 169px;
      }
      .ant-input-number {
        height: 58px;
        border-radius: 0 8px 8px 0;
        border-left: 0;
        box-shadow: none;
        border-color: #d9d9d9 !important;

        &-input,
        &-input-wrap {
          height: 100%;
          font-size: 18px;
          color: #85919d;
          text-align: center;
        }
      }
      .ant-input-number-group-addon {
        width: 58px;
        border-radius: 8px 0 0 8px;
      }
      .svg-icon {
        width: 23px;
      }
    }

    &.fixed :global {
      .ant-input-number {
        border-color: #ff6161 !important;
      }
      .ant-input-number-group-addon {
        background: #ff6161;
        border-color: #ff6161;
      }
      .ant-input-number-input {
        color: #ff6161;
      }
      .svg-icon [fill] {
        fill: #fff;
      }

      .ant-btn:not([disabled]) {
        background-color: #ff6161 !important;
        border-color: transparent;
        box-shadow: none;
      }
    }
  }
}

.block {
  position: relative;
  padding: 30px;
  background: #fff;
  box-shadow: 0px 6px 22px 0px rgba(58, 87, 78, 0.1);
  border-radius: 10px;

  + .block {
    margin-top: 20px;
  }

  :global {
    $height: 48px;

    .ant-input,
    .ant-input-number,
    .ant-picker {
      height: $height;
      font-size: 16px !important;
      color: #85919d;
    }
    textarea.ant-input {
      height: 133px;
    }
    .ant-input-number-input,
    .ant-input-number-input-wrap {
      height: 100%;
    }
    .ant-input-number,
    .ant-picker {
      width: 100%;
    }

    .ant-input-affix-wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
    .ant-input-show-count-suffix {
      font-size: 18px;
      color: #c4c4c4;
    }

    .ant-form-item {
      margin-bottom: 24px;

      &-label {
        > label {
          font-size: 18px;
          font-weight: 600;
          color: #000;

          em {
            margin-left: 4px;
            font-style: normal;
            font-weight: normal;
            color: #85919d;
          }
        }
      }

      &-explain-error {
        white-space: nowrap;
      }

      &-with-help :local(.logo) {
        background: rgba(255, 97, 97, 0.2);

        svg {
          display: block;
        }
      }
    }

    .ant-select-selector {
      min-height: $height;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 22px;
    }
    .ant-select-selection-overflow-item-suffix {
      top: 4px !important;
    }
    .ant-select-selection-item {
      line-height: 46px !important;
      font-size: 16px;
      color: #74818f;
    }
    .ant-select-multiple .ant-select-selection-item {
      margin: 0;
      padding-left: 13px;
      padding-right: 8px;
      font-size: 20px;
      font-weight: 700;
      height: 33px;
      line-height: 33px !important;
      border-radius: 12px;
      background: #3a574e;
      color: #fff;
    }
    .ant-select-selection-item-remove {
      &::after {
        content: '×';
        margin-left: 4px;
        font-size: 22px;
        font-weight: 900;
        color: #fff;
        opacity: 0.8;
      }
      .anticon {
        display: none;
      }
    }
    .c-target-suggest > svg {
      display: none;
    }

    .ant-radio-wrapper,
    .ant-checkbox-wrapper {
      height: $height;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      color: #3a574e;
      &:not(:last-child) {
        margin-right: 169px;
      }
    }
    .ant-radio,
    .ant-checkbox {
      top: 0;
    }
    .ant-radio-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      &::after {
        position: static;
        width: 12px;
        height: 12px;
      }
    }

    .copy > .ant-row > .ant-form-item-control {
      padding: 16px 23px;
      border-radius: 8px;
      border: 1px solid #dee3ea;
    }
    .copy.highlight > .ant-row > .ant-form-item-control {
      border-color: #d8f7ea;
      background: #d8f7ea;
    }

    .fit-content {
      width: fit-content;
    }

    .ant-spin-nested-loading .ant-spin-dot {
      margin-top: 0.0375rem !important;
    }
  }

  .source {
    position: relative;
    margin-bottom: 25px;
    padding-left: 42px;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;

    > svg {
      left: 0;
      top: 0;
    }
  }
}

.highlight :global {
  .ant-form-item-label > label {
    color: var(--brand-color);
  }

  .ant-input-number {
    background: #d8f7ea;
    color: #3a574e;
    &:not(.ant-input-number-focused) {
      border-color: #d8f7ea;
    }
  }

  .ant-select-selector {
    background: #d8f7ea !important;
  }
  .ant-select:not(.ant-select-focused) .ant-select-selector {
    border-color: #d8f7ea;
  }
  .ant-select-multiple .ant-select-selection-item {
    background: var(--brand-color);
  }
}

// .rangePicker {
//   padding: 12px 10px;
//   border-radius: 10px;
//   background: #f6f6f6;
// }
.pickerWrap {
  margin-bottom: 0 !important;
  display: inline-block;
  vertical-align: top;
  width: 48%;

  + .pickerWrap {
    margin-left: 3.9%;
  }

  :global {
    .ant-picker {
      padding-left: 28px;

      &-input > input {
        font-size: 16px;
        font-weight: 700;
        color: #3a574e;
      }
    }
  }
}

.factor {
  display: flex;
  align-items: center;
  margin-left: 6px;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  color: var(--brand-color);

  > svg {
    margin-right: 8px;
    width: 16px;
  }
}

.creatives :global {
  .ant-form-item-control .ant-form-item-label {
    margin-left: 0;
  }
  .ant-form-item-label {
    margin-left: 42px;

    > label {
      width: 100%;
      // justify-content: space-between;
      font-size: 18px;
      font-weight: 700;

      :local {
        .validators {
          display: flex;
        }

        .validator {
          display: flex;
          align-items: center;
          margin-left: 24px;
          padding: 0 12px;
          height: 24px;
          border-radius: 6px;
          background: #f3f3f3;
          color: #606f7f;
          font-size: 12px;
          font-weight: 600;

          &.success {
            background: var(--brand-color);
            color: #fff;
          }

          svg {
            margin-right: 11px;
            width: 12px;
          }
        }
      }
    }
  }
}

.pass {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 27px;

  &.unready [fill] {
    fill: #d9d9d9;
  }
}

.radioButtons {
  // position: absolute;
  // top: 26px;
  // right: 52px;
  padding: 3px 4px;
  width: 459px;
  height: 50px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(178, 178, 178, 0.25);
  font-size: 18px;
  font-weight: 600;
  text-align: center;

  .inner {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 33.33%;
      height: 100%;
      border-radius: 11px;
      background: #ecfbf4;
      transition: transform 0.3s ease-out;
    }
    &[data-active='1']::before {
      transform: translateX(100%);
    }
    &[data-active='2']::before {
      transform: translateX(200%);
    }
  }

  .button {
    position: relative;
    flex: 1;
    line-height: 44px;
    cursor: pointer;
    transition: color 0.3s ease-out;

    &.active {
      color: var(--brand-color);
    }
  }
  &.disabled .button {
    cursor: not-allowed;
  }
}

.radioButtonGroup {
  display: flex;

  &.disabled .button {
    cursor: not-allowed;
  }

  .button {
    padding: 0 27px;
    min-width: 129px;
    line-height: 46px;
    border-radius: 10px;
    border: 1px solid #dee3ea;
    background: #fff;
    font-size: 16px;
    color: #85919d;
    text-align: center;
    cursor: pointer;

    &.active {
      border-color: var(--brand-color);
      background: var(--brand-color);
      color: #fff;
      font-weight: bold;
    }

    + .button {
      margin-left: 53px;
    }
  }
}

.suggest {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 534px;
  height: 58px;
  border-radius: 8px;
  border: none;
  background: #ffd234 !important;
  color: #fff !important;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;

  svg {
    margin-right: 16px;
    width: 32px;
  }
}

.ageInput {
  display: inline-block;
  vertical-align: top;
  width: 129px;

  :global(.ant-input-number-input) {
    text-align: center;
  }
}

.to {
  margin: 0 16px;
  line-height: 48px;
  font-size: 24px;
  color: #85919d;
}

.genderSlider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 536px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #dee3ea;
  background: #fff;

  .highlight & {
    background: #d8f7ea;
    border-color: #d8f7ea;
  }

  .label {
    font-size: 14px;
    font-weight: 700;
  }

  .value {
    margin: 0 16px;
    width: 60px;
    line-height: 28px;
    border-radius: 28px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(58, 87, 78, 0.2);
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }

  :global {
    .ant-slider {
      padding: 0;
      width: 211px;
      height: 12px;
    }
    .ant-slider-rail {
      background: #efefef;
      height: 100%;
    }
    .ant-slider-handle {
      margin-top: -6px;
      width: 24px;
      height: 24px;
      border: solid 6px #3dd994;
      box-shadow: 0px 2px 4px rgba(58, 87, 78, 0.4);
    }
    .ant-slider-track,
    .ant-slider-step {
      height: 100%;
      border-radius: 21px;
    }
    .ant-slider-track {
      background-color: var(--brand-color);
    }
  }
}

.targetTypeList {
  display: flex;
  justify-content: space-between;
}
.targetType {
  display: inline-flex;
  align-items: center;
  padding: 0 12px;
  height: 48px;
  border-radius: 5px;
  border: 1px solid #3dd994;
  font-family: Poppins-SemiBold;
  @include sizeColor(16px, #3dd994);
  cursor: pointer;

  + .targetType {
    // margin-left: 16px;
  }

  &.active {
    background: #30d988;
    color: #fff;
  }

  svg {
    width: 18px;
    margin-right: 15px;

    [fill] {
      fill: currentColor;
    }
  }
}

.inline :global {
  .ant-form-item-row {
    flex-direction: row;
  }

  .ant-form-item-label {
    margin-left: 42px;
    margin-right: 16px;
    padding-bottom: 0;

    > label {
      // line-height: 48px;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .ant-form-item-control {
    justify-content: center;
    width: 436px !important;
    // flex-grow: 0;

    // &-input::after {
    //   content: '';
    //   @include icon('~@/assets/icons/pencil.svg', 20px);
    //   position: absolute;
    //   right: 15px;
    //   top: 50%;
    //   transform: translateY(-50%);
    // }
  }

  .ant-input {
    padding-right: 42px;
  }
}

.inline.noEdit :global {
  .ant-form-item-label {
    margin-right: 47px;
  }
  .ant-form-item-control-input::after {
    display: none;
  }
}

.textarea :global {
  .ant-form-item-control-input {
    &::after {
      content: '';
      @include icon('~@/assets/icons/pencil.svg', 20px);
      position: absolute;
      right: 15px;
      bottom: 12px;
    }
  }
}

.dropdown :global {
  .selection-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px 0 14px;
    height: 36px;
    font-size: 18px;

    svg {
      display: none;
      margin-left: 14px;
      width: 26px;
    }
  }
  .ant-select-item-option-active {
    background: rgba(48, 217, 136, 0.1) !important;

    &:not(.ant-select-item-option-selected) {
      .selection-item svg {
        display: block;
      }
    }
  }

  .ant-select-item-option-state {
    margin-right: 14px;
    font-size: 20px;
  }
}

.logo {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  border-radius: 8px;
  background: #f6f6f6;
  cursor: pointer;

  &.error {
    background: rgba(255, 97, 97, 0.2);
  }

  img {
    width: 46px;
    height: 46px;
  }

  svg {
    display: none;
    position: absolute;
    right: -5px;
    top: -6px;
    width: 20px;
  }
}

.plus {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 5px 9px rgba(135, 135, 135, 0.15);
  cursor: pointer;

  svg {
    width: 30px;
  }
}
