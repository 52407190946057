// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.item{
  @include flexBetween();
  padding: 30px;
  border-bottom:1px solid #F0F0F0;
  .theme{
    @include sizeColor(24px,#3A574E);
    font-weight: 500;
    div{
      margin-right: 24px;
      span{
        border-radius: 9px;
        background: #3A574E;
        @include sizeColor(24px,#fff);
        // font-family: Poppins-SemiBold;
        @include wh(60px,30px);
        vertical-align: middle;
        margin-right: 5px;
        display: inline-block;
        @include flexCenter();
      }
    }
    button{
      @include wh(110px,35px);
      &.ideas{
        @include flexCenter();
        @include wh(140px,35px);
        background: #FFD234;
        border-color:#FFD234;
        svg{
          @include wh(16px,16px);
          margin-right: 6px; 
        }
      }
      span{
        @include sizeColor(14px,#fff);
        font-family: Poppins-SemiBold;
      }
    }
  }
  .cloum{
    min-width: 0;
    &:nth-of-type(1){
      flex: 1;
    }
    &:nth-of-type(2) {
      flex: 0.6;
    }
    &:nth-of-type(3){
      flex: 1.2;
     
      @include sizeColor(20px,#8A8A8A);
      .below{
        @include sizeColor(20px,#FF6161);
      }
      .above{
        @include sizeColor(20px,#3DD994);
      }
      .average{
        @include sizeColor(20px,#3DD994);
      }
      .desc{
        font-size: 20px;
        font-weight: 400;
        margin-right: 24px;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    &:nth-of-type(4){
      text-align: left;
      flex: 0.5;
      .operate{
        @include flexStart();
        @include sizeColor(24px,#3DD994);
        cursor: pointer;
        svg{
          width: 25px;
          margin-left: 15px;
        }
      }
      .starting{
        @include flexBetween();
        .budget{
          @include sizeColor(22px,#3A574E);
          font-family: Poppins-SemiBold;
        }
      }
      .box{
        @include flexStart();
        span{
          @include sizeColor(20px,#BABABA);
          margin-right: 10px;
        }
        &.checked{
          span{
            color:#3DD994;
          }
        }
      }
      .noAllow{
        @include flexStart();
        @include sizeColor(20px,#BABABA);
        svg{
          width: 22px;
          margin-left: 10px;
        }
      }
    }
  }
}

.themeTag {
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  height: 35px;
  border-radius: 20px;
  background: #F0F0FC;
  color: #798EFF;
  font-size: 16px;
  font-weight: 600;

  &.synced {
    font-weight: 700;
    color: #53E5BC;
    background: #ECF9F6;

    svg {
      margin-left: 11px;
      width: 22px;
    }
  }

  svg {
    margin-left: 6px;
    margin-top: -2px;
    width: 18px;
  }
}