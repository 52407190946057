// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.assistant{
  // max-height: 100%;
  // overflow-y: scroll;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 100px;
  .input{
    position: relative;
    border-radius: 12px;
    border: 1px solid #D9D9D9;
    min-height: 50px;
    margin-top: 20px;
    input{
      height: 48px;
      @include sizeColor(14px,#000);
      font-family: Poppins-SemiBold;
      padding-right: 60px;
      padding-left: 20px;
    }
    textarea{
      line-height: 48px;
      padding: 0px 18px;
    }
    .text{
      padding: 0 18px;
      line-height: 48px;
      height: 100%;
      @include sizeColor(14px,#000);
      font-family: Poppins-SemiBold;
      &.textarea{
        height: 240px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: block; 
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          background: #d9d9d9;
        
        }
        &::-webkit-scrollbar-button {
          display: none;
        }
      }
    }
    svg{
      @include abTr(50%,15px);
      @include wh(15px!important,15px);
      transform: translate(0,-50%);
      margin: 0!important;
      cursor: pointer;
    }
  }
  :gloabl{
    .ant-spin-container::after{
      background: none;
    }
  }
  .loading{
    opacity: 0;
  }
  .title{
    font-weight: 600;
    font-size: 16px;
      svg{
        width: 30px;
        margin-left: 8px;
      }
    
  }
  .search{
    @include flexBetween();
    margin-top: 20px;
    &Input{
      height: 50px;
      border-radius: 8px;
      border: 1px solid #F3F6F8;
      background: #F6F6F6;
      flex: 1;
      @include flexStart();
      padding: 0 17px;
      input{
        @include sizeColor(14px,#3A574E);
      }
      svg{
        @include wh(20px!important,20px);
        margin: 0!important;
      }
    }
    &Check{
      @include flexStart();
      @include sizeColor(14px,#3A574E);
      font-weight: 600;
      margin-left: 24px;
      margin-right: 16px;
      cursor: pointer;
      svg{
        @include wh(24px!important,24px);
        margin-left: 12px!important;
      }
    }
  }
  .campaigns{
    .header{
      @include flexBetween();

      .sizes{
        font-family: Poppins-Regular;
        @include sizeColor(16px,#000);
        font-weight: 500;

        > span:first-child{
          position: relative;
          padding-right: 14px;
          margin-right: 14px;
          &::after{
            content: '';
            @include wh(1px, 20px);
            background: rgba(58, 87, 78, 0.10);
            @include abTr(50%,0);
            transform: translate(0,-50%);
          }
        }
        b{
          font-family: Poppins-SemiBold;
          @include sizeColor(16px,#3DD994);
        }

        em{
          border-radius: 50%;
          background: #FF6161;
          min-width:22px;
          font-style: normal;
          font-size: 14px;
          font-family: Poppins-SemiBold;
          height: 22px;
          text-align: center;
          display: inline-block;
          color:#fff;
          line-height: 22px;
          margin-left: 5px;
          vertical-align: middle;
          border:none;
        }
      }
    }
    .list{
      max-height: 450px;
      overflow-y: scroll;
      margin-top: 17px;
      &Header{
        background: #F6F6F6;
        border-radius: 12px 12px 0 0;
        padding: 16px 20px;
        @include sizeColor(16px,#818181);
        font-family: Poppins-SemiBold;
        span{
          display: inline-block;
          &:nth-of-type(1){
            width: 50%;
          }
          &:nth-of-type(2){
            width: 20%;
          }
          &:nth-of-type(3){
            width: 20%;
          }
          &:nth-of-type(4) {
            width: 10%;
          }
        }
      }
      &::-webkit-scrollbar {
        display: block; 
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #d9d9d9;
        &:hover {
          background: #999;
        }
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      ul{
        li{
          border-radius: 12px;
          border: 1px solid #F3F6F8;
          background: #FFF;
          box-shadow: 0px 6px 8px 0px rgba(216, 213, 213, 0.25);
          // @include flexBetween();
          margin-bottom: 8px;
          padding: 20px ;
          cursor: pointer;
          &:first-of-type{
            border-radius:0 0 12px 12px;
          }

          > div {
            display: inline-flex !important;
            align-items: center;
            vertical-align: top;
            height: 55px;
          }
          .icon{
            margin: 0!important;
            @include wh(24px!important,24px);
          }
          .objective{
            width: 20%;
            @include sizeColor(16px,#818181);
          }
          .stage{
            width: 20%;
            :global{
              .ant-select{
                @include wh(151px,55px);
                .ant-select-selector{
                  @include wh(100%,100%);
                  border-radius: 12px;
                  border: 1px solid #D9D9D9;
                  background: #FFF;
                  .ant-select-selection-item{
                    line-height: 53px;
                    @include sizeColor(16px,#000);
                    font-family: Poppins-SemiBold;
                  }
                }
                .ant-select-arrow {
                  @include bgCover('~@img/arrow-2.png');
                  @include abTr(50%, 20px);
                  transform: translate(0, -50%);
                  @include wh(16px, 7px);
                  margin-top: 0;
                  // background-color: #e2e2ea;
                  // top: 0;
                  // margin-top: 0;
                  // right: 0;
                  // @include flexCenter();
                  // color: #212123;
                  // border-radius: 0 7px 7px 0;
                  span{
                    display: none;
                  }
                }
              }
            }
          }
          .type{
            @include flexStart();
            padding-right: 30px;
            width: 50%;
            svg{
              @include wh(21px!important,21px);
              margin: 0!important;
            }
            .name{
              @include sizeColor(14px,#3A574E);
              margin: 0 12px;
            }
          }
          .assigned {
            width: 10%;
          }
          .status{
            &.active{
              @include sizeColor(16px,#53E5BC);
              background: #ECF9F6;
            }
            @include sizeColor(16px,#BABABA);
            background: #F3F2F2;
            padding: 6px 16px;
            font-family: Poppins-SemiBold;
            border-radius: 20px;
          }
        }
      }
    }
    button{
      @include wh(207px,52px);
      margin: 25px auto 0 auto;
      display: block;
    }
  }
  
  .platform{
    @include flexCenter();
    margin-top: 20px;
    .box{
      border-radius: 12px;
      background: #F3F6F8;
      @include wh(230px,145px);
      border: 2px solid #F3F6F8;
      @include flexCenter();
      flex-direction: column;
      cursor: pointer;
      &.active{
        background: #fff;
        border:2px solid var(--brand-color);
        .name{
          color:var(--brand-color);
        }
      }
      &:first-of-type{
        margin-right:60px; 
      }
      svg{
        @include wh(41px!important,41px);
        margin: 0!important;
      }
      .name{
        @include sizeColor(14px,#8F8F8F);
        font-family: Poppins-SemiBold;
        margin-top: 20px;
      }
    }
  }
  .select{
    :global{
      .ant-select{
        width: 100%;
        margin-top: 20px;
        .ant-select-selector{
          height: 50px;
          border-radius: 12px;
          border: 1px solid #D9D9D9;
          background: #FFF;
          .ant-select-selection-item{
            line-height: 48px;
            @include sizeColor(14px,#000);
            font-family: Poppins-SemiBold;
          }
        }
        .ant-select-arrow{
          span{
            display: none;
          }
          @include bgCover('~@img/arrow-2.png');
          @include abTr(50%, 20px);
          transform: translate(0, -50%);
          @include wh(16px, 7px);
          margin-top: 0;
        }
      }
    }
   
    &Btn{
      @include wh(207px,52px);
      margin: 25px auto 0 auto;
      display: block;
    }
  }
  .complete{
    @include wh(207px,52px);
    border-radius: 8px;
    display: block;
    margin:0 auto;
    span{
      @include sizeColor(16px,#fff);
      font-family: Poppins-SemiBold;
    }
  }
}
.selectDown{
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.18);
  :global{
    .ant-select-item{
      .ant-select-item-option-content{
        padding: 5px 0;
        @include sizeColor(14px,#A1A1A1);
        font-family: Poppins-SemiBold;
      } 
      &.ant-select-item-option-active{
        .ant-select-item-option-content{
          color:#000;
        }
      }
    }
  }
}

.stageDown{
  :global{
    .ant-select-item-option-content{
      text-align: center;
    }
  }
}