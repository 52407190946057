// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.modal{
  :global{
    .ant-modal-content{
      border-radius: 15px;
    }
  }
}

.accounts{
  width: 1400px;
  padding: 10px 30px;
  .actions{
    @include flexBetween();
    margin-top: 25px;
    .connect{
      @include flexStart();
      .desc{
        margin: 0;
      }
      &Btn{
        @include wh(200px, 50px);
        @include flexCenter();
        border-radius: 8px;
        cursor: pointer;
        border: 1px solid #3DD994;
        background: #ECFBF4;
        margin-right: 25px;
        @include sizeColor(16px,rgba(0, 0, 0, 0.80));
        font-weight: 700;
        svg{
          width: 25px;
          &:nth-of-type(2){
            margin:0 10px;
            width: 20px;
          }
        }
      }
    }
    .types{
      @include flexCenter();
      padding: 3px;
      border-radius: 8px;
      border: 1px solid #3DD994;
      background: #FFF;
      width: 290px;
      @include wh(300px,auto);
      span{
        @include sizeColor(18px,#3A574E);
        font-weight: 700;
        cursor: pointer;
        flex: 1;
        text-align: center;
        padding: 8px 0;
        &.active{
          background: #ECFBF4;
          color:#3DD994;
          
        }
      }
    }
  }
  .footer{
    @include flexCenter();
    margin: 80px 0;
    button{
      @include wh(185px,58px);
      background: #3DD994;
      border: 1px solid #3DD994;
      &:first-of-type{
        margin-right: 40px;
        border: 1px solid #3A574E;
        background: #FFF;
        span{
          color:#3A574E;
        }
      }
      &:last-of-type{
        
        &[disabled],&[disabled]:hover, &[disabled]:focus, &[disabled]:active{
          background: #D9D9D9;
          border: 1px solid #D9D9D9;
        }
      }
      span{
        font-size: 24px;
        color: #fff;
        font-weight: 600;
      }
    }
  }
  .item{
    p{
      font-family: Poppins-SemiBold;
      margin-bottom: 20px;
      svg{
        width: 25px;
        margin-right: 5px;
        vertical-align: middle;
      }
      &.fail{
        color: #ff4d4f;
      }
      &.success{
        color:#3DD994;
      }
      padding-top: 5px;
    }
  }
  :global{
    .onboard-select{
      .ant-select-item{
        padding: 8px 10px;
        @include sizeColor(16px,#000);
        font-family: Poppins-Regular, Poppins;
  
        &:hover{
          background: #ECFBF4;
        }
        border:none;
      }
    }
    .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot{
      top:55px;
    }
    .ant-form-item-label > label{
      @include sizeColor(16px,#B6B6B6);
    }
  }
}
.title{
  @include sizeColor(34px,#121928);
  font-weight: 700;
  margin-bottom: 5px;
}
.desc{
  @include sizeColor(22px,#3A574E);
  margin-bottom: 20px;
}
.assets{
  width: 1500px;
  padding: 10px 30px;
  .warp{
    height: 700px;
    overflow-y: scroll;
    ul{
      @include flexStart();
      flex-wrap: wrap;
      li{
        @include wh(340px,340px);
        border-radius: 20px;
        border: 1px solid #3DD994;
        background: #FFF;
        box-shadow: 0px 5px 9px 0px rgba(58, 87, 78, 0.10);
        padding: 40px;
        position: relative;
        cursor: pointer;
        margin:0 25px 50px 0;
        &:nth-of-type(4n){
          margin-right: 0;
        }
        .check{
          width: 27px;
          @include abTr(10px,10px);
        }
        .pic{
          border-radius: 16px;
          position: relative;
          overflow: hidden;
          @include wh(258px,258px);
          svg{
            width: 33px;
            @include abRb(10px,10px);
            cursor: pointer;
          }
          img{
            object-fit: cover;
            @include wh(100%,100%);
          }
        }
        .play{
          @include abLt(50%,50%);
          width: 60px;
          transform: translate(-50%,-50%);
        }
        .name{
          @include abBl(-40px,0);
          text-align: center;
          @include wh(100%,auto);
          @include sizeColor(20px,#BABABA);
          font-weight: 700;
        }
      }
    }
  }
  .btn{
    margin: 20px auto;
    text-align: center;
    .skip{
      text-decoration: underline;
      @include sizeColor(24px,#3DD994);
      display: block;
      margin-top: 10px;
      cursor: pointer;
    }
    button{
      @include wh(247px,58px);
      font-size: 24px;
      b{
        font-weight: 700;
      }
      span{
        font-weight: 600;
        margin:0 5px;
      }
    }
  }
}