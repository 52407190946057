// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap {
  padding: 37px 70px 66px;
  width: 1132px;
}

.form {
  margin-top: 75px;

  :global {
    $height: 46px;

    .ant-input {
      height: $height;
      font-size: 14px !important;
      font-weight: 600 !important;
      color: #3a574e;
    }

    .ant-form-item {
      margin-bottom: 36px;

      &-label {
        > label {
          padding-left: 5px;
          font-size: 16px;
          font-weight: 700;
          color: #505050;
        }
      }
    }

    .ant-select-selector {
      height: $height !important;
    }
    .ant-select-selection-overflow-item-suffix {
      top: 4px !important;
    }
    .ant-select-selection-item {
      margin: 0;
      padding-left: 16px;
      padding-right: 8px;
      font-size: 18px;
      font-weight: 700;
      height: 33px;
      line-height: 33px;
      border-radius: 33px;
      background: var(--brand-color);
      color: #fff;
    }
    .ant-select-selection-item-remove {
      &::after {
        content: '×';
        margin-left: 4px;
        font-size: 22px;
        font-weight: 900;
        color: #fff;
      }
      .anticon {
        display: none;
      }
    }
  }
}

.btnWrap {
  margin-top: 82px;
  font-size: 0;
  text-align: center;
}

.btn {
  width: 284px;
  height: 58px;
  border-radius: 58px;
  font-size: 24px;
}

.dropdown :global {
  .selection-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px 0 24px;
    height: 46px;
    font-size: 16px;
  
    svg {
      display: none;
      margin-left: 14px;
      width: 26px;
    }
  }
  .ant-select-item-option-active {
    background: rgba(48, 217, 136, 0.10) !important;

    &:not(.ant-select-item-option-selected) {
      .selection-item svg {
        display: block;
      }
    }
  }

  .ant-select-item-option-state {
    margin-right: 14px;
    font-size: 20px;
  }
}