// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.strategist{
  width: 80%;
  margin: auto;
  .arrow{
    width: 22px;
    cursor: pointer;
    margin-left: 13px;
    transition: all 0.3s;
    transform: rotate(0deg);
    vertical-align: middle;

    &.active{
      transform: rotate(180deg);
    }
  }
  .pm{
    padding-bottom: 25px!important;
  }

  .mg0{
    margin: 0!important;
  }

  .mt35{
    margin-top: 35px!important;
  }
  
  .date{
    @include flexCenter();
    border-radius: 12px;
    background: #FFF;
    padding: 12px 18px;
    width: 315px;
    margin: 0 auto;

    > svg{
      @include wh(18px,18px);
      margin-right: 5px;
    }

    :global{
      .ant-select{

        .ant-select-selector{
          width: 235px;

          .ant-select-selection-item{
            @include sizeColor(16px, #000);
            font-weight: 600;
          }
        }

        .ant-select-arrow{
          @include bgCover('~@img/arrow-3.png');
          @include wh(14px,7px);
          @include abTr(50%, 0);
          transform: translate(0, -50%);
          margin-top: 0;

          span{
            display: none;
          }
        }
      }
    }
  }

  .box{
    margin:0 auto;
    border-radius: 12px;
    background: #FFF;
    @include sizeColor(20px,#3A574E);
    font-family: Poppins-SemiBold;
    padding: 25px 21px 45px 21px;
    word-wrap:break-word; 
    word-break: keep-all;
    position: relative;
    margin-bottom: 24px;
  }

  .nav{
    background: none;
  }

  .ations{
    @include sizeColor(20px,#3A574E);
    font-family: Poppins-SemiBold;
    text-align: center;
  }
}

.tabs{
  @include flexCenter();
  overflow: hidden;
  margin-bottom: 24px;
  .platform{
    position: relative;
    display: flex;
    align-items: center;
    width: 48%;
    border-radius: 12px;
    border: 2px solid #E7EAF1;
    background: #FFF;
    padding: 24px;
    cursor: pointer;
    &Disbaled{
      cursor: not-allowed;
      opacity: 0.5;
    }
    &.active{
      border: 2px solid #3DD994;
      background: rgba(61, 217, 148, 0.10);

      .check {
        display: block;
      }
      .logo {
        margin-left: 44px;
      }
    }
    &:first-of-type{
      margin-right: 4%;
    }

    .check {
      display: none;
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }

    .logo {
      position: relative;
      width: 32px;
      margin-right: 10px;
      transition: margin-left .3s;
    }

    .name {
      font-family: Poppins-SemiBold;
      @include sizeColor(16px,#333);
    }

    .budget {
      margin-left: auto;
      font-size: 14px;

      p:first-child {
        margin-bottom: 6px;
        color: #A23FEE;
      }
      p:last-child {
        color: #333;
      }
    }
  }
}

.warp{
  // width: 90%;
  // margin: 0 auto;

  .main{
    padding: 0 10px;
  }

  .overview{
    position: relative;

    &Header{
      @include flexBetween();
      border-bottom:1px dashed #E0E0E0;
      padding-bottom: 10px;
      > span{
        @include sizeColor(22px,#000);
        font-family: Poppins-SemiBold;

        + span{
          @include sizeColor(14px,#000);
          font-weight: 700;
        }
      }
    }

    &Data{
      @include flexBetween();
      margin-top: 12px;

      .dl{
        .item{
          @include flexStart();
          margin-bottom: 8px;
          font-size: 18px;
          em{
            @include wh(22px,22px);
            margin-right: 7px;
            @include flexCenter();
            .spend{
              @include wh(22px,22px);
            }
            .purchases{
              @include wh(12px,12px);
            }
            .cpr{
              @include wh(15px,auto);
            }
          }
        
          span{
            @include sizeColor(18px,#3A574E);
            font-family: Poppins-Regular;
            
            &:last-child {
              @include flexCenter();
              border-radius: 30px;
              font-size: 12px;
              font-family: Poppins-SemiBold;
              padding: 2px 6px;
              &.up{
                background: rgba(61, 217, 148, 0.10);
                color: #3DD994;
                svg{
                  width: 9px;
                  margin-right: 4px;
                }
              }
              &.nchanged{
                background: #F9F9F9;
                color: #BABABA;
                svg{
                  width: 10px;
                  margin-right: 4px;
                }
              }
              &.down{
                background: rgba(255, 97, 97, 0.10);
                color: #FF6161;
                svg{
                  width: 8px;
                  margin-right: 4px;
                }
              }
            }
          }
          b{
            font-family: Poppins-SemiBold;
            margin: 0 6px 0 10px;
          }
        }
      }

      .dr{
        @include flexStart();

        .status{
          @include wh(115px, 105px);
          border-radius: 12px;
          line-height: 34px;
          margin-right: 20px;

          &:nth-of-type(1){
            background: #ebfff6;
            > div{
              b{
                color:#3DD994;
              }
            }
          }
          &:nth-of-type(2){
            background: #fffbeb;
            > div{
              b{
                color:#FFD234;
              }
            }
          }
          &:nth-of-type(3){
            background: #ffefef;
            margin-right: 0;
            > div{
              b{
                color:#FF6161;
              }
            }
          }

          > div{
            @include flexStart();
            margin: 15px 0 9px 18px;
            b{
              font-family: Poppins-SemiBold;
              font-size: 34px;
              
            }
            > div{
              @include flexStart();
              transform: translate(10px,5px);
              svg{
                width: 9px;
                margin-right: 4px;
              }
              span{
                font-size: 14px;
              }
            }
            + div{
              margin-top: 5px;
              font-weight: 600;
              font-family: Poppins-Regular;
            }
          }
        }
      }
    }

    .text{
      margin-top: 8px;
      @include sizeColor(18px,#3A574E);
      font-weight: 600;
      padding: 10px 7px;
      border-radius: 8px;
      background: #F5F5F5;
      position: relative;
      z-index: 2;
      font-family: Poppins-Regular;
    }

    .bm{
      @include abBl(-12px,8px);
      width: 20px;
    }
  }

  .recommendations{
    padding: 22px 32px;
    background: #C7EBD7;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 24px;

    .title{
      @include flexBetween();
      margin-bottom: 20px;

      > span{
        @include sizeColor(22px,#000);
        font-family: Poppins-SemiBold;

        b{
          text-decoration: underline;
        }
      }

      .tr{
        @include flexStart();
        span{
          color: #000;
          &:nth-of-type(1){
            @include sizeColor(14px,#000);
            font-family: Poppins-Regular;
            font-weight: 600;
            margin-right: 15px;
            position: relative;
            &::after{
              content: '';
              width: 1px;
              height: 15px;
              background: #D9D9D9;
              position: absolute;
              right: -8px;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          &:nth-of-type(2){
            font-family: poppins-SemiBold;
          }
        }
        svg{
          width: 20px;
          margin-left: 5px;
        }
      }
    }

    .desc{
      @include flexStart();
      border-bottom:1px dashed #3DD994;
      margin-bottom: 20px;
      overflow: hidden;
      padding-bottom: 20px;
      > svg{
        width: 57px;
        margin-right: 25px;
      }
      font-family: Poppins-Regular;
      font-size: 18px;
      color: #5B5B5B;
      font-weight: 600;
      em{
        border-radius: 50%;
        background: #FF6161;
        min-width:21px;
        font-style: normal;
        font-size: 14px;
        font-family: Poppins-SemiBold;
        height: 21px;
        text-align: center;
        display: inline-block;
        color:#fff;
        line-height: 21px;
        margin-left: 5px;
        vertical-align: middle;
        border:none;
      }
    }

    .text{
      transition: all 0.3s;
      overflow: hidden;

      .datas{
        overflow: hidden;
        .item{
          display: inline-block;
          vertical-align: top;
          margin-right: 10px;
          text-align: center;
          &:last-of-type{
            margin-right: 0;
          }
          &:nth-of-type(2), &:nth-of-type(4){
            margin-right: 40px;
          }
          .name{
            @include sizeColor(16px,#3A574E);
          }
          .num{
            border-radius: 12px;
            border: 1px solid #D9D9D9;
            background: #EFEFEF;
            @include sizeColor(16px,#000);
            font-family: Poppins-SemiBold;
            padding: 10px 6px 10px 16px;
            margin: 8px 0;
            width: 110px;
            text-align: left;
            @include flexBetween();
            > svg{
              width: 12px;
            }
          }
          .vary{
            display: inline-block;
            border-radius: 20px;
            background: #FF6161;
            font-family: Poppins-SemiBold;
            padding:7px  12px;
            @include sizeColor(12px,#fff);
          }
        }
      }
    }
  }

  .awareness{
    border-radius: 12px;
    background: #FFF;
    @include flexBetween();
    padding: 22px 32px;
    margin-bottom: 24px;
    &L{
      @include flexStart();
      b{
        @include sizeColor(22px,#000);
        font-family: poppins-SemiBold;
        margin-right: 12px;
      }
    }
    &R{
      font-size: 0.07292rem; 
      color: #000;
      font-weight: 700;
      text-align: right;

      div{

        &:last-of-type{
          color: #A23FEE;
        }
      }
     
    }
  }

  .actionHeader{
      @include flexBetween();

      .tab{
        border-radius: 32px;
        border: 1px solid #DEE3EA;
        background: #FFF;
        @include flexStart();
        span{
          @include sizeColor(12px,#000);
          font-weight: 600;
          padding: 6px 13px;
          display: block;
          border-right: 1px solid #DEE3EA;
          @include flexCenter();
          &:first-of-type{
            color:#3DD994;
            em{
              color:currentColor;
            }
          }
          &:last-of-type{
            border-right: 0;
          }
          
        }
      }
      em{
        border-radius: 50%;
        border:1px solid #DEE3EA;
        background: #fff;
        min-width:21px;
        font-style: normal;
        font-size: 12px;
        height: 21px;
        text-align: center;
        display: inline-block;
        font-weight: 600;
        font-family: Poppins-Regular;
        color:#000;
        line-height: 19px;
        margin-left: 5px;
      }
  }

  .createBtn{
    margin:0 auto 50px auto;
    display: block;
    border-radius: 8px;
    background: #3DD994;
    @include wh(50%,52px);
    span{
      @include sizeColor(16px,#fff);
      font-family: poppins-SemiBold;
    }
  }

  .mlist{
    // margin-left: 97px;
    &.queued{
      height: 0;
      overflow: hidden;
      transition: all 0.3s;
    }
  }
}

.campaignList {
  margin-bottom: 24px;
}
 
.li{
  padding: 22px 32px;
  border-radius: 12px;
  background: #fff;
  position: relative;
  margin-bottom: 15px;
  &:last-of-type{
    margin-bottom: 0;
  }
  &.queuedLi{
    > svg {
      display: none!important;
    }
    .desc{
      border:none;
      margin-bottom: 0;
    }
  }
  
  &:hover{
    > svg {
      display: block;
    }
  }

  > svg {
    @include abTr(-10px,-10px);
    @include wh(22px,22px);
    cursor: pointer;
    display: none;
  }

  .loader {
    @include abTr(-20px,-33px);
    height: 40px;
    cursor: pointer;
  }

  .mheader{
    @include flexBetween();
    .name{
      margin-right: 24px;
      span{
        &:first-of-type{
          display: inline-flex;
          align-items: center;
          @include sizeColor(14px,#3A574E);
          font-weight: 600;
          border-radius: 6px;
          font-family: Poppins-Regular;
          // background: rgba(61, 217, 148, 0.10);
          padding: 0 11px;
          height: 24px;
          transform: translateY(-2px);
          svg{
            width: 7px;
            margin-left: 4px;
          }
          &.up, &.launch,&.revive{
            background: rgba(61, 217, 148, 0.10);                
          }
          &.down, &.pause{
            background: rgba(255, 97, 97, 0.10);
          }
          &.pause,&.launch, &.revive{
            svg{
              width: 12px;
            }
          }
        
        }
        &:last-of-type{
          @include sizeColor(20px,#979DA3);
          line-height: 28px;
          margin-left: 8px;
          font-weight: 600;
          font-family: Poppins-Regular;
        }
        &.mg0{
          margin: 0;
          padding: 0;
        }
      }
      .platform{
        margin-left: 9px;
        width: 18px;
        vertical-align: middle;
        transform: translateY(-2px);
      }
      .new{
        width: 29px;
      }
      b{
        @include wh(20px,20px);
        font-size: 12px;
        font-family: poppins-SemiBold;
        border-radius: 6px;
        @include flexCenter();
        background: #EBFFF6;
        color: #3DD994;
        &.red{
          background: rgba(255, 97, 97, 0.10);
          color: #FF6161;
        }
      }
    }
    button{
      flex-shrink: 0;
      border-radius: 8px;
      @include wh(111px,40px);
      border:none;
      text-shadow:none;
      box-shadow:none;
   
      span{
        font-family: Poppins-SemiBold;
      }
      &.take{
        @include wh(150px,40px);
        background: #FFD234;
      }
      &.open{
        @include wh(150px,40px);
        background: rgba(255, 210, 52, 0.10);
        span{
          color: #FBBC05;
        
        }
      }

      &:not([disabled]) {
        &:hover{
          background: rgba(61, 217, 148, 0.20);
          span{
            color:#3DD994!important;
          }
        }
      }
    }
    
    .error{
      @include flexCenter();
      border-radius: 9px;
      background: rgba(255, 97, 97, 0.10);
      @include wh(95px,40px);
      span{
        @include sizeColor(16px,#FF6161);
        font-family: Poppins-SemiBold;
      }
      svg{
        width: 14px;
        margin-right: 5px;
      }
    }
  }

  .desc{
    @include flexBetween();
    margin-top: 18px;
    // border-bottom: 1px dashed #E0E0E0;
    // padding-bottom: 15px;
    // margin-bottom: 15px;
    > div{
      @include sizeColor(18px,#A23FED);
      font-weight: 600;
      @include flexStart();

      span{
        font-family: Poppins-SemiBold;
        text-decoration: underline;
        margin-left: 3px;
      }
      .arrow{
        width: 22px;
        cursor: pointer;
        margin-left: 13px;
        transition: all 0.3s;
        transform: rotate(0deg);
        &.active{
          transform: rotate(180deg);
        }
      }
      .status{
        border-radius: 20px;
        background: #DBF8EA;
        @include flexStart();
        padding: 5px 12px;
        text-transform:capitalize;
        div{
          @include flexStart();
          svg{
            margin-right: 12px;
          }
        }
        .variant{
          padding-right: 12px;
          position: relative;
          &::after{
            content:"";
            @include wh(1px,17px);
            background: #3DD994;
            @include abTr(50%,0);
            transform: translate(0,-50%);
          }
          > svg{
            width: 18px;
          }
          em{
            font-family: poppins-SemiBold;
            @include sizeColor(16px,#3DD994);
          }
        }
        .like{
          padding-left: 12px;
          > svg{
            width: 18px;
          }
          em{
            @include sizeColor(16px,#3DD994);
          }
        }
      }
    }

    > span{
      @include sizeColor(16px,#3A574E);
      position: relative;
      margin-right: 25px;
      &:after{
        content:'';
        background: #3A574E;
        width: 100%;
        height: 1px;
        @include abBl(2px,0);
      }
    }
  }
  
  .text{
    margin-top: 15px;
    // padding: 0 12px;
    border-radius: 8px;
    background: #F5F5F5;
    @include sizeColor(18px,#3A574E);
    overflow: hidden;
    font-weight: 600;
    font-family: Poppins-Regular;
    &C{
      padding: 8px 12px;
    }
  }
}

.datePopup{
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.18);
  top:45px!important;
  width: 300px!important;
  left:-45px!important;

  .item{
    padding: 8px 0;
    text-align: center;
    font-weight: 600;
    // @include flexBetween();
    
    // span:last-of-type{
    //   border:1px solid #DEE3EA;
    //   background: #fff;
    //   min-width:24px;
    //   height: 24px;
    //   border-radius: 50%;
    //   text-align: center;
    //   display: inline-block;
    //   color:#3DD994;
    //   margin-left: 15px;
    //   line-height: 22px;
    // }
  }

  :global{
    .ant-select-item{
      @include sizeColor(14px, #A1A1A1);
      font-family:  Poppins-Regular;

      &.ant-select-item-option-active{
        color: #000;
        font-family: Poppins-SemiBold;
      }
    }
  }
}

.strategistLoad{
  @include abLt(0,0);
  @include wh(100%,100%);
  z-index: 101;
  &::before{
    content: '';
    @include wh(100%,100%);
    @include abLt(0,0);
    background:rgba(0, 0, 0, 0.1);
  }

  .cont{
    width: calc(100% - 342px - 344px);
    height: 100%;
    margin-left: 344px;
    background: #fff;
    @include flexCenter();
    .text{
      @include flexCenter();
      position: relative;
      flex-direction: column;
      z-index: 102;
      svg{
        width: 60px;
        margin-bottom: 20px;
      }
      color:#000;
      @include sizeColor(20px,#000);
      font-weight: 700;
      font-family: Poppins-SemiBold;
    }
  }
}

.modal2 {
  width: 937px !important;

  :global {
    .ant-modal-content {
      border-radius: 20px;
    }

    .ant-modal-body {
      padding: 0 0 42px;
    }

    .ant-modal-confirm-body,
    .ant-modal-confirm-btns {
      text-align: center;
    }

    .ant-modal-confirm-title {
      display: flex;
      align-items: center;
      padding-top: 12px;
      padding-left: 56px;
      height: 74px;
      line-height: 1;
      font-size: 16px;
      font-weight: 700;

      &::before {
        content: '';
        margin-right: 12px;
        width: 5px;
        height: 17px;
        border-radius: 5px;
        background: currentColor;
      }
    }

    .ant-modal-confirm-content {
      margin: 44px 0 78px;
    }

    .ant-modal-confirm-btns {
      .ant-btn {
        width: 164px;
        height: 44px;
        border-radius: 58px;
        font-size: 16px;
        font-weight: 700;
        box-shadow: none;

        &-default {
          background: #d9d9d9;
          border-color: #d9d9d9;
          color: #fff;
        }

        + .ant-btn {
          margin-left: 42px;
        }
      }
    }
  }

  &success {
    :global {
      .ant-modal-confirm-title {
        background: rgba(61, 217, 148, 0.1);
        color: var(--brand-color);
      }
    }

    .modal2content .status {
      &Icon {
        border: 1px solid #3dd994;
        background: rgba(61, 217, 148, 0.1);
      }

      &Text {
        color: #3dd994;
      }
    }
  }
  &danger {
    :global {
      .ant-modal-confirm-title {
        background: rgba(255, 97, 97, 0.1);
        color: #ff6161;
      }

      .ant-btn-primary {
        border-color: #ff6161;
        background: #ff6161;
      }
    }

    .modal2content .status {
      &Icon {
        border: 1px solid rgba(255, 97, 97, 0.1);
        background: rgba(255, 97, 97, 0.1);
      }

      &Text {
        color: #ff6161;
      }
    }
  }

  &content {
    display: flex;
    justify-content: center;
    align-items: center;

    .status {
      position: relative;
      margin-right: 46px;

      &Icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 142px;
        height: 142px;
        border-radius: 47px;

        svg {
          width: 80px;
        }
      }

      &Text {
        position: absolute;
        bottom: -19px;
        left: 50%;
        transform: translate(-50%, 100%);
        font-size: 16px;
        font-weight: 700;
      }
    }

    .message {
      font-size: 18px;
      font-weight: 600;
      color: #000;
    }
  }
}
