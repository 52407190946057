// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap {
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  width: 1507px;
}

.title {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 50px 34px 24px;
  font-size: 16px;
  font-weight: 700;
  color: #3a574e;

  &::before {
    content: '';
    margin-right: 12px;
    width: 5px;
    height: 17px;
    border-radius: 5px;
    background: #3a574e;
  }
}

.main {
  flex: 1;
  padding: 0 34px 55px;
  overflow-x: hidden;
}

.form {
  display: flex;
  justify-content: space-between;
}

.block {
  padding: 30px 40px;
  width: 708px;
  border-radius: 16px;
  background: #f8f8f8;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .platform {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    &.google {
      margin-bottom: 34px;
    }

    svg {
      width: 26px;
    }
  }

  :global {
    $height: 48px;

    .ant-input-group-addon {
      position: absolute;
      border: 0;
      padding: 0;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
      width: auto;
      z-index: 2;

      button {
        width: 91px;
        height: 37px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 700;
      }
    }
    .ant-input-group .ant-input {
      padding-right: 103px;
    }

    .ant-input,
    .ant-input-number,
    .ant-picker {
      height: $height;
      font-size: 16px !important;
      color: #85919d;
      border-radius: 10px !important;
    }
    textarea.ant-input {
      height: 133px;
    }
    .ant-input-number-input,
    .ant-input-number-input-wrap {
      height: 100%;
    }
    .ant-input-number,
    .ant-picker {
      width: 100%;
    }

    .ant-input-affix-wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
    .ant-input-show-count-suffix {
      font-size: 18px;
      color: #c4c4c4;
    }

    .ant-form-item {
      margin-bottom: 26px;

      &-label {
        > label {
          font-size: 16px;
          font-weight: 600;
          color: #000;

          em {
            margin-left: 4px;
            font-style: normal;
            font-weight: normal;
            color: #85919d;
          }
        }
      }

      &-explain-error {
        white-space: nowrap;
      }

      &-with-help :local(.logo) {
        background: rgba(255, 97, 97, 0.2);

        svg {
          display: block;
        }
      }
    }

    .ant-select-selector {
      border-radius: 12px !important;
      min-height: $height;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 22px;
    }
    .ant-select-selection-overflow-item-suffix {
      top: 4px !important;
    }
    .ant-select-selection-item {
      line-height: 46px !important;
      font-size: 16px;
      color: #74818f;
    }
    .ant-select-multiple .ant-select-selection-item {
      margin: 0;
      padding-left: 13px;
      padding-right: 8px;
      font-size: 20px;
      font-weight: 700;
      height: 33px;
      line-height: 33px !important;
      border-radius: 12px;
      background: #3a574e;
      color: #fff;
    }
    .ant-select-selection-item-remove {
      &::after {
        content: '×';
        margin-left: 4px;
        font-size: 22px;
        font-weight: 900;
        color: #fff;
        opacity: 0.8;
      }
      .anticon {
        display: none;
      }
    }
    .c-target-suggest > svg {
      display: none;
    }

    .ant-radio-wrapper,
    .ant-checkbox-wrapper {
      height: $height;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      color: #3a574e;
      &:not(:last-child) {
        margin-right: 169px;
      }
    }
    .ant-radio,
    .ant-checkbox {
      top: 0;
    }
    .ant-radio-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      &::after {
        position: static;
        width: 12px;
        height: 12px;
      }
    }

    .fit-content {
      width: fit-content;
    }

    .ant-spin-nested-loading .ant-spin-dot {
      margin-top: 0.0375rem !important;
    }
  }
}

.btns {
  margin-top: 45px;
  text-align: center;

  :global {
    .ant-btn {
      width: 164px;
      height: 44px;
      border-radius: 44px;
      font-size: 16px;
      font-weight: 600;

      &-default {
        border-color: #D9D9D9;
        background: #D9D9D9;
        color: #fff;
      }

      + .ant-btn {
        margin-left: 43px;
      }
    }
  }
}

.logo {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  border-radius: 8px;
  background: #f6f6f6;
  cursor: pointer;
  &:hover svg {
    display: block;
  }

  &.error {
    background: rgba(255, 97, 97, 0.2);
  }

  img {
    width: 46px;
    height: 46px;
  }

  svg {
    display: none;
    position: absolute;
    right: -5px;
    top: -6px;
    width: 20px;
  }
}

.plus {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 5px 9px rgba(135, 135, 135, 0.15);
  cursor: pointer;

  svg {
    width: 30px;
  }
}
