// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap{
  :global{
    .ant-modal-body{
      padding: 0;
    }
    .ant-modal .ant-modal-content{
      border-radius: 15px;
    }
  }
  .success{
    padding: 82px 0;
    width: 800px;
    &Main{
      text-align: center;
      .title{
        @include sizeColor(38px,#000);
        font-family: Poppins-SemiBold;
      }
      .desc{
        @include sizeColor(24px,#3A574E);
        font-weight: 600;
      }
      img{
        width: 340px;
      }
    }
  }
  .plan{
    padding: 30px 55px 100px 55px;
    width: 1600px;
    &Title{
      @include flexBetween();
      margin-bottom: 46px;
      border-bottom: 1px dashed #E8E8E8;
      padding-bottom: 8px;
      position: relative;
      &Tab{
        @include flexCenter();
        cursor: pointer;
        border-radius: 6px;
        background: #F9FAFB;
        padding: 8px 12px;
        @include abLt(50%,40%);
        transform: translate(-50%,-50%);
        div{
          font-size: 16px;
          color: #1B202F;
          font-weight: 600;
          margin-right: 40px;
          cursor: pointer;
          padding: 5px 12px;
          &:last-of-type{
            margin-right: 0;
          }

          span{
            border-radius: 18px;
            background: #F2F4F7;
            padding: 2px 12px;
            margin-left: 12px;
            @include sizeColor(14px, var(--brand-color));
          }
          &.active{
            border-radius: 5px;
            background: #FFF;
            box-shadow: 0px 1px 3px 0px rgba(146, 146, 146, 0.25);
            color: #687083;
         
          }
        }
      }
      &Left{
        div{
          @include sizeColor(28px,#000);
          font-family: Poppins-SemiBold;
        }
        span{
          @include sizeColor(20px,#707976);
          font-weight: 600;
        }
      }
      svg{
        width: 25px;
        cursor: pointer;
      }
    }
   
    &List{
      padding: 0 20px;
      min-height: 600px;
      &Price{
        color: var(--brand-color);
        font-size: 32px;
        text-align: center;
        font-family: Poppins-SemiBold;
        margin:10px auto 0 auto;
        span{
          font-size: 52px;
          color:#000;  
        }
      }
      &Line{
        margin:25px 0;
        height: 2px;

        img{
          height: 2px;
          width: 100%;
        }
      }
      &Spend{
        text-align: center;
        @include sizeColor(16px,#3A574E);
        font-weight: 700;
      }
      &Title{
        @include flexCenter();
        @include sizeColor(16px,#000);
        font-family: Poppins-SemiBold;
        img{
          width: 52px;
        }
        // &::before{
        //   content: '';
        //   @include wh(32px,32px);
        //   border-radius: 8px;
        //   background: #3DD994;
        //   margin-right: 15px;
        // }
      }
      ul{
        @include flexBetween();
        li{
          flex: 1;
          padding: 32px 20px;
          cursor: pointer;
          width: 300px;
          height: 620px;
          border-radius: 20px;
          background: #FFF;
          border: 1px solid #fff;
          position: relative;
          box-shadow: 0px 14px 24px 0px rgba(98, 98, 98, 0.20); 
          margin-right: 50px;
          &.active{
            border: 1px solid var(--brand-color);
          }
          &:last-of-type{
            margin-right: 0;
          }
          button{
            display: block;
            margin: 15px auto;
            width: 170px;
            height: 40px;
            @include abBl(20px,50%);
            transform: translate(-50%,0);
            span{
              font-family: Poppins-SemiBold;
            }
          }
          dl{
            padding: 0 20px;
            dt{
              font-family: Poppins-SemiBold;
              @include sizeColor(18px,#000);
              margin-bottom: 20px;
            }
            dd{
              margin-bottom: 20px;
              display: flex;
              align-items: baseline;
              span{
                flex: 1;
                font-weight: 600;
              }
              svg{
                margin: 0 10px 0 0;
                width: 20px;
              }
            }
          }
        }
      }
    }
  }
  .whoops{
    padding: 110px 95px;
    text-align: center;
    h1{ 
      @include sizeColor(38px,#000);
      font-family: Poppins-SemiBold;
      margin-bottom: 46px;
    }
    .desc{
      width: 600px;
      margin: 0 auto;
      @include sizeColor(24px,#3A574E);
      font-weight: 600;
    }
    :global{
      button{
        margin-top: 70px;
        width: 240px;
        height: 40px;
        span{
          font-size: 16px;
          font-family: Poppins-SemiBold;
        }
      }
    }
  }
  .payment{
    border-radius: 20px;
    background: #F2F2F2;
    position: relative;
    &Main{
      @include flexStart();
      width: 1400px;
      height: 800px;
      &Left{
        position: relative;
        @include flexCenter();
        width: 50%;
        height: 100%;
        .leftMain{
          svg{
            width: 160px;
          }
          .leftSub{
            margin: 25px 0 7px 0;
            @include sizeColor(16px,#AEAEAE);
            font-weight: 700;
          }
          .leftPer{
            @include flexCenter();
            span{
              @include sizeColor(46px,#000);
              font-family: Poppins-SemiBold;
            }
            &R{
              @include sizeColor(16px,#AEAEAE);
              margin-left: 65px;
              font-weight: 700;
              line-height: 30px;
            }
          }
          .leftSpend{
            @include sizeColor(16px,#3A574E);
            font-weight: 700;
            margin: 5px 0;
          }
          .leftBill{
            @include sizeColor(16px,#AEAEAE);
            font-weight: 700;
            margin-top: 10px;
          }
        }
        .leftPowered{
          @include abBl(60px,50%);
          transform: translate(-50%,0);
          @include sizeColor(16px,#AEAEAE);
          font-weight: 600;
          b{
            font-family: Poppins-SemiBold;
          }
        }
      }
      &Right{
        border-radius: 20px;
        background: #FFF;
        width: 50%;
        height: 100%;
        @include flexCenter();
        .close{
          @include abTr(30px,30px);
          svg{
            width: 25px;
            cursor: pointer;
          }
        }
        .rightMain{
          width: 80%;
          text-align: center;
          .tips{
            @include sizeColor(16px,#AEAEAE);
            font-weight: 700;
          }
          :global{
            .ant-btn-primary[disabled]{
              border:none;
              span{
                color: #a3a3a3;
              }
            }
          }
          button{
            width: 416px;
            height: 42px;
            margin: 50px auto;
           
            span{
              color:#fff;
              font-size: 16px;
              font-family: Poppins-SemiBold;
            }  
          }
          a{
            text-decoration: underline;
            padding: 0 2px;
          }
          &Agree,a{
            @include sizeColor(15px,#505050);
            font-weight: 600;
            letter-spacing: 1px;
            :global{
              .ant-checkbox-wrapper{
                .ant-checkbox{
                  margin-top: -3px;
                }
                @include flexCenter();
              }
            }
          }
        }
        :global{
          .payment-element{
            border: 1px solid #F2F2F2;
            padding: 15px;
            margin: 45px 0 100px 0;
            width: 98%;
            &_error{
              border: 1px solid #ff4d4f;
            }
          }
        }
        
      }
    }
    .back{
      border-radius: 8px;
      border: 1px solid #C6C6C6;
      background: #FFF;
      @include abLt(40px,25px);
      @include flexCenter();
      padding:5px 10px;
      cursor: pointer;
      z-index: 99;
      svg{
       width: 20px; 
       margin-right: 20px;
      }
    }
  }
}