// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap {
  margin: auto;
  // width: 75%;
  // max-width: 1200px;
  width: 950px;
  line-height: 1;
}

.loader {
  display: block;
  margin: 20px auto;
  width: 160px;
}

.group {
  margin: 35px 0 70px;

  .dialog {
    margin: 0;

    &:first-child .msg {
      border-radius: 12px 12px 0 0;
    }
    &:nth-child(2) .msg {
      border-radius: 0;
    }
  }

  .btns {
    padding: 30px 0;
    background: #fff;
    border-radius: 0 0 12px 12px;

    button {
      height: 46px;
    }
  }
}

.dialog {
  display: flex;
  align-items: flex-start;
  margin: 35px 0 70px;

  > .avatar {
    width: 74px;
    margin-right: 23px;
    margin-top: 4px;
  }

  > .content {
    flex: 1;
    position: relative;

    > svg {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      width: 64px;
    }
  }

  .name {
    margin-bottom: 16px;
    font-size: 16px;
  }

  &.noIcon .msg {
    padding-top: 22px;
  }

  .msg {
    padding: 42px 79px 22px;
    border-radius: 12px;
    background: #fff;

    &Title {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: bold;
      line-height: 1;
    }

    .select {
      // margin-top: 20px;
      width: 100%;

      :global {
        .ant-select {
          margin-top: 20px;
          width: 100%;

          &-selector {
            padding-left: 20px !important;
            height: 50px !important;
            border-radius: 12px !important;
          }

          &-selection-item {
            line-height: 48px !important;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.selectDropdown {
  :global {
    .ant-select-item-option {
      padding-left: 29px;
      line-height: 30px;
      font-size: 14px;
      font-weight: bold;
      color: #a1a1a1;

      &-selected {
        color: #000;
      }
    }
  }
}

.lottie svg {
  width: 100px;
  transform: scale(2);
}

.item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  border-radius: 12px;
  border: 1px solid #e7eaf1;
  min-height: 40px;
  line-height: 38px;
  font-size: 14px;
  color: #333;
  background: #fff;

  > * {
    flex-shrink: 0;
  }

  &.danger {
    background: rgba(255, 97, 97, 0.1);
  }

  &.lg {
    align-items: flex-start;
    padding-top: 13px;
    height: auto;
  }

  .order {
    margin: 0 17px;
    color: #999;
  }

  .plus {
    align-self: center;
    margin-left: auto;
    margin-right: 16px;
    width: 24px;
    cursor: pointer;
  }

  .trashcan {
    margin-top: 12px;
    margin-right: 12px;
    width: 16px;
    cursor: pointer;
  }

  .assets {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
  }

  .asset {
    position: relative;
    margin-right: 13px;
    margin-bottom: 13px;
    width: 68px;
    height: 68px;
    border-radius: 16px;
    border: 1px solid transparent;
    transition: border-color 0.3s;

    &.readonly {
      pointer-events: none;

      &::before {
        display: none !important;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &::before {
      position: absolute;
      right: -5px;
      top: -6px;
      @include icon('~@/assets/icons/delete.svg', 20px);
      cursor: pointer;
    }

    &:hover,
    &.error {
      &::before {
        content: '';
      }
    }

    &:hover {
      border-color: #ff6161;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }

  .upload {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 13px;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 5px 9px rgba(135, 135, 135, 0.15);
    cursor: pointer;

    svg {
      width: 30px;
    }
  }

  :global {
    $height: 30px;

    .ant-input {
      flex: 1;
      margin: 4px 16px 4px 0;
      // height: $height !important;
      border-radius: 10px !important;
      font-size: 14px !important;
      border: none;
      color: #85919d;
    }

    .ant-input-number {
      width: 50px !important;
      height: $height !important;
      border-radius: 10px !important;
      font-size: 14px !important;
      color: #85919d;
    }
    .ant-input-number-input,
    .ant-input-number-input-wrap {
      height: 100%;
      text-align: center;
    }

    // .ant-slider {
    //   width: 150px !important;
    // }

    .c-target-suggest {
      flex: 1;
      margin: 4px 0;
      margin-right: 16px;
      line-height: 1;

      .ant-select {
        width: 100%;

        &-selector {
          margin-right: 0;
          border-radius: 10px !important;
          min-height: $height !important;
        }
      }
      .ant-select-multiple .ant-select-selection-item {
        margin: 2px;
        padding: 0 0 0 4px;
        border: 0;
        padding-left: 13px;
        padding-right: 8px;
        font-size: 14px;
        font-weight: 700;
        height: 24px;
        line-height: 24px !important;
        border-radius: 12px;
        background: rgba(61, 217, 148, 0.1);
        // color: var(--brand-color);
        color: #3a574e;
      }
      .ant-select-selection-item-remove {
        &::after {
          content: '×';
          margin-left: 4px;
          font-size: 22px;
          font-weight: 900;
          color: #3a574e;
          opacity: 0.8;
        }
        .anticon {
          display: none;
        }
      }
    }
  }

  .genderControl {
    flex: 1;
    justify-content: flex-start;
    margin-left: 4px;
    margin-right: 16px;
    border: none;
    height: 100%;
  }

  .miniSelect {
    margin-right: 10px;
    margin-top: 8px;
    width: 106px;

    :global {
      .ant-select-selector {
        padding: 0;
        height: 24px !important;
        border-radius: 24px;
        border: none;
        background: #3a574e;
      }

      .ant-select-selection-item {
        padding: 0;
        line-height: 24px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
      }

      .ant-select-arrow {
        display: none;
      }
    }
  }
}

.miniSelectPopup {
  :global {
    .ant-select-item-option {
      padding-left: 2px;
      padding-right: 2px;
      text-align: center;
    }
  }
}

.strategist {
  margin-top: 44px;
  // padding: 0 37px;

  .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #000;

    > svg {
      width: 38px;
      margin-right: 6px;
    }
  }

  .desc {
    margin-top: 16px;
    font-size: 14px;
    color: #333;
    line-height: 1.4;
  }

  .past {
    position: relative;
    margin-top: 45px;

    .title {
      margin-bottom: 16px;
    }
  }

  .item {
    .highlight {
      margin-left: auto;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
  
      > svg {
        margin-right: 11px;
        width: 20px;
      }
    }

    .status {
      position: relative;
      margin-left: 32px;
      height: 100%;
      width: 88px;
      border-radius: 12px;
      line-height: 38px;
      text-align: center;
      font-family: Poppins-SemiBold;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: var(--progress);
      }

      &.testing {
        background: rgba(255, 199, 0, 0.1);
        color: #ffc700;
        &::before {
          background: rgba(255, 199, 0, 0.1);
        }
      }

      &.winning {
        background: rgba(61, 217, 148, 0.1);
        color: var(--brand-color);
        &::before {
          background: rgba(61, 217, 148, 0.2);
        }
      }
    }
  }
}

.suggestions {
  // margin: auto;
  // width: 672px;
  // padding: 0 37px;

  .theme {
    margin-top: 12px;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid #b9baf4;
    background: #f4f4ff;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 700;
      color: #6667ab;

      svg {
        width: 24px;
        margin-right: 6px;
      }
    }

    .generate {
      margin-top: 6px;
      vertical-align: top;
      font-size: 16px;
      font-weight: 700;
      color: #6667ab;
    }
  }

  .detail {
    margin-top: 54px;

    .title {
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 700;
      color: var(--brand-color);
    }

    .item {
      .error {
        display: inline-block;
        margin-right: 10px;
        width: 76px;
        font-size: 12px;
        color: #ff6161;

        &.hide {
          visibility: hidden;
        }
      }

      .ageRange {
        align-self: center;
        display: flex;
        align-items: center;

        em {
          margin: 0 8px;
          font-style: normal;
          font-size: 14px;
          color: #85919d;
        }
      }
    }

    .btnWrap {
      margin-top: 4px;
      margin-bottom: 50px;
      font-size: 0;
      text-align: right;
    }
    .btn {
      width: 180px;
      height: 40px;
      border-radius: 8px;
      background: rgba(61, 217, 148, 0.1);
      color: var(--brand-color);
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.creative {
  .content {
    display: flex;
    height: 215px;

    > img {
      margin-left: 8px;
      margin-right: 30px;
      height: 100%;
    }

    > p {
      padding-right: 30px;
      height: 100%;
      font-size: 14px;
      color: #333;
      line-height: 1.4;
      overflow: auto;
    }
  }

  .detail {
    margin-top: 28px;

    .title {
      color: #999;
    }
  }

  .trashcan {
    align-self: flex-end;
    margin-bottom: 16px;
  }
}

.btns {
  display: flex;
  justify-content: center;

  :global {
    .ant-btn {
      height: 52px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 700;
    }

    .ant-btn-default {
      width: 180px;
      // border-color: #d9d9d9;
      // background: #d9d9d9;
      // color: #fff;
    }

    .ant-btn-primary {
      margin-left: 20px;
      width: 207px;
    }
  }
}

.result {
  margin: 35px auto 40px;
  padding: 24px;
  width: 952px;
  border-radius: 8px;
  border: 1px solid #b9baf4;
  background: #f4f4ff;

  .headline {
    position: relative;
    margin-bottom: 12px;
  }

  .title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: #A23FEE;

    svg {
      width: 24px;
      margin-right: 6px;
    }
  }

  .item {
    margin-bottom: 16px;
    padding: 0 16px;

    .label {
      align-self: center;
      margin-right: 20px;
      width: 116px;
      text-align: center;
      line-height: 24px;
      border-radius: 24px;
      background: #FCF0FF;
      color: #A23FEE;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .content {
    font-size: 14px;
    line-height: 20px;
    color: #000;
  }

  .float {
    position: absolute;
    right: 4px;
    top: -8px;
    display: flex;
    align-items: center;

    .status {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 95px;
      height: 32px;
      border-radius: 9px;
      background: #f5f5f5;
      color: #acacac;
      font-size: 14px;

      svg {
        margin-right: 14px;
        width: 14px;
      }
    }

    .platform {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      width: 32px;
      height: 32px;
      border-radius: 6px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      svg {
        width: 18px;
      }
    }
  }

  > button {
    display: block;
    margin: 16px auto 0;
    width: 460px;
    height: 48px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 700;
  }
}

.radioButtonGroup {
  display: flex;

  &.disabled .button {
    cursor: not-allowed;
  }

  .button {
    width: 170px;
    line-height: 46px;
    border-radius: 8px;
    border: 1px solid #f6f6f6;
    background: #f6f6f6;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    text-align: center;
    cursor: pointer;

    &.active {
      border-color: var(--brand-color);
      background: rgba(61, 217, 148, 0.1);
      color: var(--brand-color);
    }

    + .button {
      margin-left: 30px;
    }
  }
}
