// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.table :global {
  .ant-table {
    padding: 4px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25) inset;

    // &-header > table {
    //   padding-right: 8px;
    // }
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      display: block;
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: #d9d9d9;
      &:hover {
        background: #999;
      }
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  .ant-table-cell:not(:last-child) {
    border-right: 1px solid #d9d9d9 !important;
  }

  .ant-table-thead .ant-table-cell {
    font-size: 20px;
    font-family: Poppins-SemiBold;
    background: #f9f9f9;
    color: #3a574e;
    border: none;

    &:first-child {
      padding-left: 30px + 34px;
      border-radius: 10px 0 0 10px !important;
    }
    &:last-child {
      border-radius: 0 10px 10px 0 !important;
    }

    &::before {
      display: none;
    }
  }

  .ant-table-tbody tr:last-child .ant-table-cell {
    border-bottom: 0;
  }
  .ant-table-tbody .ant-table-cell {
    font-size: 20px;
    font-weight: 500;
    color: #3a574e;
    border-bottom: 1px dashed #d9d9d9;

    > strong {
      line-height: 35px;

      &:empty + p {
        display: none;
      }
    }

    &:first-child {
      padding-left: 30px;
    }
    &:not(:first-child) {
      padding-right: 30px;
      text-align: right;
    }

    &-row-hover {
      background: rgba(61, 217, 148, 0.1) !important;

      .show-on-hover {
        opacity: 1;
      }

      .success-on-hover {
        color: var(--primary-color, var(--brand-color));
      }

      :local {
        .budget:not(.danger, .disabled) {
          background: #fff;
          box-shadow: 0px 4px 4px 0px #dcdcdc;
          color: var(--primary-color, var(--brand-color));
        }

        // .stage.testing::before {
        //   width: var(--progress);
        // }

        .pauseSpent {
          > strong {
            display: none;
          }

          > span {
            display: inline-flex;
            align-items: center;
            padding: 0 16px;
            height: 35px;
            border-radius: 35px;
            background: #fff;
            box-shadow: 0px 4px 4px 0px #dcdcdc;
            cursor: pointer;
          }

          svg {
            width: 16px;
          }
        }
      }
    }
    &:not(.ant-table-cell-row-hover) {
      .display-on-hover {
        display: none;
      }
    }
  }

  .show-on-hover {
    opacity: 0;
    transition: opacity 0.3s;
  }

  .ant-btn-primary {
    background: var(--primary-color, var(--brand-color));
    border-color: var(--primary-color, var(--brand-color));
  }
}
.table.waiting :global {
  .ant-table {
    box-shadow: none;
  }
  .ant-table-cell {
    border-right-color: transparent !important;
  }
}
.table.unassigned {
  :global {
    .ant-table-cell {
      border-right-color: transparent !important;
    }

    .ant-table-tbody .ant-table-cell {
      padding-top: 25px;
      padding-bottom: 25px;
      &:not(:last-child) {
        text-align-last: left !important;
      }
    }
  }

  .themeTag,
  .text {
    display: none;
    margin: 0;
  }
}
.table .oops :global {
  .ant-table-cell {
    &-row-hover {
      background: #f6f6f6 !important;
    }

    :local {
      .theme,
      .themeTag,
      .text,
      strong {
        color: #d9d9d9;
      }

      .theme [fill] {
        fill: #3a574e;
      }

      .themeTag {
        background: #f9f9f9;

        [fill] {
          fill: currentColor;
        }
      }
    }
  }
}

.success {
  color: var(--primary-color, var(--brand-color));
}

.danger {
  color: #ff6161;
}

.text {
  margin-top: 15px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #3a574e;
  @include oneline();

  &:first-child {
    margin-top: 0;
  }
}

.theme {
  position: relative;
  display: flex;
  align-items: flex-start;

  &Name {
    margin-right: 52px;
    cursor: pointer;
    white-space: normal;
    line-height: 1.2;
  }

  &Move {
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;

    // svg {
    //   display: block;
    //   width: 16px;
    //   transition: transform 0.5s;
    //   transform-origin: center;
    //   cursor: pointer;

    //   &:hover {
    //     transform: scale(1.1);
    //     [fill] {
    //       fill: var(--primary-color, var(--brand-color));
    //     }
    //   }

    //   + svg {
    //     margin-top: 8px;
    //   }

    //   [fill] {
    //     transition: fill 0.5s;
    //   }
    // }

    .hide {
      visibility: hidden;
    }
  }

  > svg {
    flex-shrink: 0;
    width: 22px;
    margin-right: 12px;
    margin-top: 3px;
  }

  &Status {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.error::before {
      content: '!';
      line-height: 1;
      font-size: 20px;
      font-weight: 700;
      color: #EA4335;
    }
    &.dot::before {
      content: '';
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: var(--brand-color);
    }
    &.dot.inactive::before {
      background: #B6B6B6;
    }
  }

  &Tag {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    // margin-left: 4px;
    margin-top: 10px;
    padding: 0 12px;
    height: 32px;
    min-width: 32px;
    border-radius: 20px;
    background: #f0f0fc;
    color: #798eff;
    // font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    font-style: italic;

    &.synced {
      font-size: 16px;
      font-weight: 700;
      color: #53e5bc;
      background: #ecf9f6;
    }

    &.variant {
      background: #dbf8ea;
      color: var(--brand-color);
    }

    > svg {
      margin-right: 9px;
      height: 16px;
    }

    strong {
      font-weight: normal;
      letter-spacing: 1px;
      -webkit-text-stroke: 1px currentColor;
    }

    .divider {
      margin: 0 14px;
      width: 1px;
      height: 16px;
      background: currentColor;
    }
  }
}

.results {
  display: flex;
  align-items: center;
  font-weight: 700;

  .tag {
    margin-left: 14px;
    padding: 0 11px;
    line-height: 26px;
    border-radius: 31px;
    font-size: 14px;
    font-weight: 500;

    &.success {
      background: #fff;
    }
    &.danger {
      background: rgba(255, 97, 97, 0.2);
    }
  }
}

.budget {
  // margin-left: -15px;
  display: inline-flex;
  align-items: center;
  padding: 0 16px;
  height: 35px;
  border-radius: 35px;
  // background: #ecfbf4;
  background: #ebeeed;
  color: #3a574e;
  font-weight: 700;
  cursor: pointer;

  .oops & {
    background: none;
    color: #d9d9d9;
  }

  &.manual {
    background: #ebeeed;
    color: #3a574e;

    .auto {
      width: 16px;
    }
  }

  &.fixed {
    background: #b1b1b1;
    color: #fff;

    .status {
      margin-right: 0;
    }
  }

  &.disabled {
    pointer-events: none;

    .status {
      display: none;
    }
  }

  &.danger {
    pointer-events: none;
    background: rgba(255, 97, 97, 0.20);
    color: #FF6161;
  }

  .status {
    margin-left: 12px;
    // margin-right: 13px;
    width: 14px;
    text-align: center;
    font-size: 0;
  }

  .lock {
    width: 12px;
  }
  .auto {
    width: 14px;
  }
  .arrow {
    margin-left: 6px;
    width: 8px;
  }

  [fill] {
    fill: currentColor;
  }
}

.reason {
  display: inline-flex;
  margin-right: 29px;
  padding: 0 9px;
  line-height: 31px;
  border-radius: 5px;
  background: #d9d9d9;
  color: #434368;
  font-size: 22px;
  font-weight: 600;

  &.plain {
    background: none;
  }
}

.stage {
  display: inline-flex;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  padding: 0 9px;
  line-height: 35px;
  border-radius: 8px;
  background: rgba(58, 87, 78, 0.1);
  overflow: hidden;

  &.fixed {
    background: rgba(137, 137, 137, 0.6);
    color: #fff;
  }
  &.scheduled {
    background: rgba(121, 142, 255, 0.1);
    color: #798eff;
  }
  &.testing {
    position: relative;
    background: #fff2c2;
    color: #ffc700;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: var(--progress);
      border-radius: 8px;
      background: #ffe99b;
      transition: width 0.3s;
    }
  }
  &.winning {
    background: rgba(61, 217, 148, 0.1);
    color: #3dd994;
  }
  &.losing,
  &.paused,
  &.error {
    background: #fdedee;
    color: #ff6161;
  }
  &.quene {
    background: rgba(217, 217, 217, 0.3);
    color: rgba(137, 137, 137, 0.6);
  }
  &.incomplete {
    font-size: 18px;
  }
}
.stageTip {
  margin: 6px 10px 0 0;
  font-size: 12px;
  font-weight: 500;
  color: rgba(137, 137, 137, 0.6);
}

.action {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  // padding-right: 20px;

  > * {
    flex-shrink: 0;
    &:not(:last-child) {
      margin-right: 18px;
    }
  }

  .icon {
    // margin-right: 18px;
    width: 16px;
    cursor: pointer;
  }

  .btn {
    // margin-right: 18px;
    padding: 0 9px;
    height: 26px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
  }

  .assistant {
    position: relative;
    cursor: pointer;

    img {
      display: block;
      width: 24px;
      height: 32px;
    }
  }

  :global {
    .ant-spin-nested-loading {
      margin-right: 18px;
      font-size: 0;
    }
  }
}

.assign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 35px;
  border-radius: 35px;
  border: 1px solid var(--primary-color, var(--brand-color));
  color: var(--primary-color, var(--brand-color));
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  svg {
    margin-left: 8px;
    width: 10px;

    [fill] {
      fill: currentColor;
    }
  }
}

.warn {
  width: 24px;
}

.alert {
  margin-top: 6px;
  padding: 6px 0;
  border-radius: 6px;
  background: rgba(255, 97, 97, 0.1);
  font-size: 12px;
  line-height: 1.2;
  color: #ff6161;

  p {
    padding: 0 12px;
  }

  &:hover p {
    display: block;
  }
}

.dangerBtn {
  width: 120px;
  line-height: 38px;
  text-align: center;
  border-radius: 8px;
  background: rgba(255, 97, 97, 0.1);
  color: #ff6161;
  font-size: 16px;
  font-weight: 600;
}

.dropdown {
  &.assignDropdown :global(.ant-dropdown-menu-item) {
    color: inherit;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
  :global {
    .ant-dropdown-menu {
      padding: 6px 0;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(58, 87, 78, 0.2);
      max-height: 300px;
      overflow: auto;

      &-item {
        padding: 8px;
        color: var(--primary-color, var(--brand-color));

        &-disabled {
          color: #b6b6b6 !important;
        }
      }
    }
  }

  .menuItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2px;
    font-size: 16px;
    font-weight: 600;
  }

  .auto {
    width: 16px;
  }

  .lock {
    margin-left: 8px;
    margin-right: 2px;
    width: 12px;
  }

  [fill] {
    fill: currentColor;
  }
}

.budgetInput {
  margin-left: -15px;
  width: 130px;
  height: 35px;
  border-radius: 35px;

  &::after {
    content: '';
    @include icon('~@/assets/icons/lock.svg', 16px, 21px);
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  :global {
    .ant-input-number-input-wrap,
    .ant-input-number-input {
      height: 100%;
      font-size: 20px;
      font-weight: 600;
    }
    .ant-input-number-input {
      padding-left: 16px;
      padding-right: 35px;
    }
  }
}

.budgetConfirm {
  display: flex;
  justify-content: flex-end;

  &.tx {
    transform: translateX(15px);
  }

  .budgetManual::after {
    // width: 16px;
    // height: 16px;
    background-image: url('~@/assets/icons/manual.svg');
  }

  button {
    margin-left: 14px;
    padding: 0 14px;
    height: 35px;
    border-radius: 35px;
    font-size: 17px;
    font-weight: 600;
  }
}

.pauseSpent {
  > span {
    display: none;
  }
}

.current {
  margin: 0 4px;
  padding: 0 10px;
  line-height: 30px;
  border-radius: 8px;
  background: #000;
  box-shadow: 0px 1px 15px 0px rgba(157, 157, 157, 0.25);
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}

.ellipsis {
  padding: 4px 0;
  font-size: 0;
  cursor: pointer;

  > svg {
    width: 20px;
  }
}
.actionDropdown {
  :global {
    .ant-dropdown-menu {
      padding: 6px 0;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(58, 87, 78, 0.2);
      max-height: 300px;
      width: 130px;
      overflow: auto;

      &-item {
        display: flex;
        align-items: center;
        padding: 8px;
        color: #3a574e;
        font-size: 14px;
        font-weight: 600;

        &-active {
          background: rgba(61, 217, 148, 0.1);
          color: var(--brand-color);
        }

        img,
        svg {
          height: 12px;
        }
      }
    }
  }
}

.tooltip {
  line-height: 1.4;
  max-width: 450px;
  min-width: 60px;

  :global(.ant-tooltip-inner) {
    color: var(--brand-color);
  }
}

.buyer {
  width: 937px;
  padding: 30px 0 33px;

  .title {
    display: flex;
    align-items: center;
    padding-left: 56px;
    font-size: 22px;
    font-weight: 700;
    color: #000;

    &::before {
      content: '';
      margin-right: 12px;
      width: 5px;
      height: 18px;
      border-radius: 10px;
      background: #000;
    }
  }

  .content {
    margin: auto;
    width: 828px;
    padding: 20px 40px 14px;
  }

  .left {
    flex: 1;
    min-width: 0;
    margin-right: 23px;
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;

    :global {
      .ant-btn-primary {
        width: 111px;
        height: 40px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 600;
      }

      .ant-btn-text {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
        color: #3A574E;

        span {
          text-decoration: underline;
        }
      }
    }
  }

  .message {
    font-size: 20px;
    font-weight: 500;
    color: #979da3;

    .status {
      display: inline-flex;
      align-items: center;
      margin-right: 8px;
      padding: 0 11px;
      height: 24px;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 600;
      color: #3a574e;

      &.ok {
        background: rgba(61, 217, 148, 0.1);
      }
      &.bad {
        background: rgba(255, 97, 97, 0.10);
      }

      svg {
        height: 12px;
        margin-left: 7px;

        &.sm {
          height: 9px;
        }
      }
    }

    .platform {
      margin-left: 9px;
      width: 18px;
      vertical-align: middle;
    }
  }

  .data {
    display: flex;
    align-items: center;
    margin-top: 23px;
    font-size: 18px;
    font-weight: 600;
    color: #000;

    svg {
      margin-left: 19px;
      width: 22px;
      cursor: pointer;
      transform: rotate(180deg);
    }
  }

  .bottom {
    display: none;
    margin-top: 13px;
    padding-top: 11px;
    border-top: 1px dashed #E0E0E0;
  }

  .desc {
    padding: 12px;
    border-radius: 8px;
    background: #f5f5f5;
    font-size: 18px;
    font-weight: 500;
    color: #3A574E;
  }

  &.open {
    .data svg {
      transform: none;
    }

    .bottom {
      display: block;
    }
  }

  :global {
    .ant-skeleton {
      padding: 0 56px;
    }
  }
}
