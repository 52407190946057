// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.item {
  display: flex;

  &Addon {
    align-self: flex-end;
    margin-left: 20px;
    margin-bottom: 40px;
    font-size: 0;

    svg {
      width: 15px;
      cursor: pointer;

      &:hover [fill] {
        fill: #FF6161;
      }
    }
  }
  // &:first-child .itemAddon {
  //   margin-bottom: 24px;
  // }
}

.btn {
  margin-bottom: 30px;
  display: inline-flex;
  align-items: center;
  height: 48px;
  padding: 0 18px;
  border-radius: 10px;
  background: #f9f9f9;
  color: #313131;
  font-size: 14px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    background: var(--brand-color);
    color: #fff;
  }

  .plus {
    margin-left: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid currentColor;
    font-size: 24px;
    font-weight: bold;
  }
}

.list .item :global {
  .ant-input,
  .ant-input-affix-wrapper {
    border: none;
    background: #f9f9f9 !important;
    border-radius: 10px;
    font-size: 22px;
  }

  textarea {
    height: 88px !important;
  }

  .ant-input-textarea {
    position: relative;
    &::after {
      position: absolute;
      right: 10px;
      bottom: 17px;
      margin-bottom: 0;
      line-height: 1;
      font-size: 18px;
      color: #c4c4c4;
    }
  }

  .overlimit {
    .ant-input-show-count-suffix,
    .ant-input-textarea::after {
      color: #ff4d4f;
    }

    textarea.ant-input,
    .ant-input-affix-wrapper {
      background: rgba(255, 97, 97, 0.1) !important;
    }
    textarea.ant-input:focus,
    .ant-input-affix-wrapper-focused {
      box-shadow: 0 0 0 0.01042rem rgba(255, 77, 79, 0.2);
    }

    input.ant-input {
      background: none !important;
    }
  }
}
