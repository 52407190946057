// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.plan{
  min-height: calc(100vh - 350px);
  &.modal{
    padding: 50px 30px;
  }
  .tabs{
    @include flexStart();
    svg{
      width: 40px;
      margin: 0 20px;
    }
    .item{
      border-radius: 30px;
      border: 2px solid #3DD994;
      background: #FFF;
      @include flexCenter();
      padding: 12px 33px;  
      font-family: Poppins-SemiBold;
      @include sizeColor(18px,#3DD994);
      cursor: pointer;
      &.disable{
        background: #D9D9D9;
        border: 2px solid #D9D9D9;
        @include sizeColor(18px,#fff);

        em{
          background: #fff;
          color:#D9D9D9;
        }
      }
      &.active{
        border: 2px solid #3DD994;
        background: #3DD994;
        color: #FFF;
        em{
          background: #FFF;
          color: #3DD994;
        }
      }
      &.idea{
        border: 2px solid #FFD234;
        @include sizeColor(18px,#FFD234);

        em{
          background: #FFD234;
        }
        &.active{
          border: 2px solid #FFD234;
          background: #FFD234;
          color: #FFF;
          em{
            background: #FFF;
            color: #FFD234;
          }
        }
      }
   
      em{
        @include wh(33px,33px);
        background: #3DD994;
        @include flexCenter();
        @include sizeColor(21px,#fff);
        border-radius: 50%;  
        margin-right: 28px;      
      }
    }
  }
  nav{
    @include flexBetween();
    .datas{
      border-radius: 20px;
      background: #FFF;
      box-shadow: 0px 6px 22px 0px rgba(58, 87, 78, 0.10);
      @include flexCenter();
      padding: 20px 25px;
      span{
        @include flexCenter();
        &:first-of-type{
          @include sizeColor(26px,rgba(137, 137, 137, 0.60));
          padding-right: 25px;
          margin-right: 25px;
          border-right: 1px solid rgba(58, 87, 78, 0.20);
        }
        &:last-of-type{
          strong{
            border-radius: 12px;
            background: #F0FCF6;
            @include sizeColor(28px,#3DD994);
            font-family: Poppins-SemiBold;
            padding: 2px 22px;
          }
        }
        b{
          font-family: Poppins-SemiBold;
          @include sizeColor(30px,#3A574E);
          margin-left: 12px;
        }
      }
    }
    .tip{
      font-size: 0.11458rem;
      color: #3A574E;
      font-family: Poppins-SemiBold;
    }
  }
  &Main{
    margin-top: 40px;
    header{
      border-radius: 10px;
      background: #F3F6F8;
      @include flexBetween();
      padding: 30px;
      span{
        @include sizeColor(22px,#3A574E);
        font-family: Poppins-SemiBold;
        &:nth-of-type(1), &:nth-of-type(2){
          width: 40%;
        }
        &:nth-of-type(3){
          width: 20%;
        }
      }
    }
    .continue{
      display: block;
      @include wh(340px, 74px);
      margin: 100px auto;
      &[disabled]{
        border:none;
        span{
          color:rgba(137, 137, 137, 0.6);
        }
      }
      span{
        @include sizeColor(30px,#fff);
        font-family: Poppins-SemiBold;
      }
    }
  }
}
.infinite{
  animation: breath 2s ease-in-out infinite;
}
@keyframes breath {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;  
  }
  100% {
    opacity: 0.2;
  }
}