// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.select {
  position: relative;
  z-index: 9999;
  @include flexStart();
  button{
    @include wh(130px,50px);
    margin-left: 15px;
    span{
      font-family: Poppins-SemiBold;
      font-size: 18px;
    }
  }
  &Popup {
    z-index: 9999;
    border-radius: 10px;
    background: #fff;
    padding: 16px 0;
    box-shadow: 0px 8px 24px 0px rgba(72, 102, 93, 0.19);
    overflow-y: scroll;

    .name {
      @include flexStart();
      span {
        &:first-of-type {
          @include sizeColor(16px, rgba(70, 79, 96, 1));
          font-weight: 600;
          margin-right: 20px;
        }
        &:last-of-type {
          padding: 2px 10px;
          font-size: 14px;
          font-weight: 600;
          border-radius: 25px;
          text-transform: capitalize;
          &.active {
            color: #3dd994;
            background: rgba(61, 217, 148, 0.2);
          }
          &.pause {
            color: #ff6161;
            background: rgba(255, 97, 97, 0.2);
          }
          &.poor {
            @include sizeColor(14px, #bababa);
            background: rgba(217, 217, 217, 0.4);
          }
        }
      }
    }
    .add {
      @include flexStart();
      span {
        @include sizeColor(14px, #3a574e);
        margin-right: 20px;
      }
      svg {
        width: 26px;
      }
    }
    :global {
      .ant-spin {
        @include abLt(50%, 50%);
        transform: translate(-50%, -50%);
        z-index: 9;
      }
      .ant-select-item {
        // padding: 10px 25px;
        background: #fff;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
        &:hover,
        &.ant-select-item-option-active {
          background: rgba(48, 217, 136, 0.1) !important;
        }
        .ant-select-item-option-content {
          @include flexBetween();
        }
      }
    }
  }
  :global {
    .ant-select {
      .ant-select-selector {
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        height: 50px !important;
      }
      .ant-select-selection-search-input {
        @include sizeColor(14px, #3a574e);
        height: 48px !important;
      }
      .ant-select-selection-placeholder {
        line-height: 48px;
        @include sizeColor(14px, #3a574e);
      }
      .ant-select-selection-item {
        line-height: 48px;
        @include sizeColor(14px, #3a574e);
      }
    }
  }
}

.import {
  .label {
    @include sizeColor(16px, #b6b6b6);
    margin: 30px 0 10px 0;
    span {
      color: #ff4747;
    }
  }

  .confirm {
    display: block;
    margin: 120px auto 50px auto;
    @include wh(338px, 74px);
    border-radius: 80px;
    &[disabled] {
      border: none;
      background: #d9d9d9;
      span {
        color: #fff;
      }
    }
    span {
      @include sizeColor(30px, #fff);
      font-weight: 700;
    }
  }
}