// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.tab {
  @include flexCenter();
  padding: 150px 200px;
  div {
    border-radius: 12px;
    border: 2px solid #d9d9d9;
    @include wh(240px, 200px);
    @include flexCenter();
    flex-direction: column;
    margin-right: 80px;
    @include sizeColor(20px, #d9d9d9);
    cursor: pointer;
    position: relative;
    text-transform: capitalize;
    &.active {
      border: 2px solid #3dd994;
      color: #3dd994;
      svg {
        &:first-of-type {
          path,
          circle {
            fill: currentColor;
          }
        }
        &:last-of-type {
          display: block;
        }
      }
    }
    &:last-of-type {
      margin-right: 0;
    }
    svg {
      &:first-of-type {
        width: 85px;
        margin-bottom: 20px;
      }
      &:last-of-type {
        display: none;
        background: #fff;
        z-index: 2;
        width: 40px;
        @include abBl(-20px, 50%);
        transform: translate(-50%, 0);
      }
    }
  }
}

.title {
  @include sizeColor(34px, #121928);
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 6px;
  line-height: 1;
}

.desc {
  @include sizeColor(17px, #909090);
}

.modal {
  padding: 50px 55px;
  border-radius: 20px;
  width: 1580px;

  .listHeader {
    margin-top: 50px;

    header {
      border-radius: 10px;
      background: #f3f6f8;
      @include flexBetween();
      padding: 30px;
      line-height: 1;
      span {
        @include sizeColor(22px, #3a574e);
        font-family: Poppins-SemiBold;
        &:nth-of-type(1) {
          flex: 1;
        }
        &:nth-of-type(2) {
          flex: 0.6;
        }
        &:nth-of-type(3) {
          flex: 1.2;
        }
        &:nth-of-type(4) {
          flex: 0.5;
        }
      }
    }
  }
  .listWarp {
    min-height: 100px;
    max-height: calc(100vh - 500px);
    overflow-y: scroll;
  }

  .empty {
    height: 300px;
  }
}

.fullscreen {
  max-width: none;

  :global {
    .ant-modal-content {
      border-radius: 0;
    }
  }
}
