// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap {
  display: flex;
  justify-content: center;
  padding: 70px 0 112px;
  width: 1286px;
}

.inner {
  width: 818px;
  &.compact {
    width: 670px;
  }
}

.title {
  display: flex;
  align-items: center;
  font-size: 42px;
  font-weight: 600;
  line-height: 1;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 13px;
    width: 42px;
    height: 42px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    svg {
      width: 26px;
    }
  }
}

.subtitle {
  margin-top: 30px;
  line-height: 1;
  font-size: 18px;
  font-style: italic;
  color: #a3a9af;
}

.content {
  margin-top: 80px;
  max-height: calc(95vh - 182px - 170px - 169px);
  overflow: auto;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  height: 134px;
  border-radius: 12px;
  border: 2px solid #3dd994;
  background: #fff;
  cursor: pointer;

  + .item {
    margin-top: 26px;
  }

  &.inactive {
    border: 1px solid #F3F4F7;
    background: #F3F6F8;

    .check [fill] {
      fill: #C9D4E4;
    }
  }

  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .left {
    flex-wrap: wrap;
    font-size: 28px;
    font-weight: 700;
    color: #8F8F8F;

    > span {
      margin-right: 35px;
    }
  }

  .right {
    flex-shrink: 0;
    margin-left: 30px;
    font-size: 18px;
    font-weight: 600;
    color: #C9D4E4;
  }

  .tip {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #FF6161;

    svg {
      margin-right: 6px;
      width: 18px;
    }
  }

  .check {
    margin-left: 35px;
    width: 52px;
  }
}

.compact .item {
  padding: 0 14px 0 28px;
  height: 78px;

  + .item {
    margin-top: 58px;
  }

  .left {
    font-size: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.80);

    > svg {
      margin-right: 18px;
      width: 28px;
    }
  }

  &.inactive {
    pointer-events: none;

    .check {
      visibility: hidden;
    }
  }
}

.btns {
  margin-top: 111px;
  font-size: 0;
  text-align: center;

  :global {
    .ant-btn {
      width: 184px;
      height: 58px;
      border-radius: 58px;
      font-size: 24px;
      font-weight: 500;
  
      &-default {
        background: #E7E8E9;
        border-color: #E7E8E9;
        color: #3A574E;
        box-shadow: none;
      }
  
      + .ant-btn {
        margin-left: 42px;
      }
    }
  }
}
