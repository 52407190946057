// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.modalMain{
  background: #FFFFFF;
  border-radius: 20px;
  padding: 30px 60px;
  width: 870px;
  .fb{
    &Account{
      width: 210px;
      padding: 8px 0;
      border: 1px solid #F2F2F2;
      border-radius: 8px;
      @include flexCenter();
      cursor: pointer;
      margin:0 auto 10px auto;
      span{
        @include sizeColor(18px,rgba(0,0,0,0.78));
        font-weight: 700;
        margin: 0 10px;
      }
      em{
        @include wh(20px,20px);
        border:1px solid #3DD994;
        border-radius: 50%;
        @include flexCenter();
        svg{
          width: 12px;
        }
        &.active{
          background: #3DD994;
          
        }
      }
      img{
        width: 30px;
      }
    }
    h3{
      text-align: center;
      @include sizeColor(30px,#3A574E);
      font-family: Poppins-SemiBold; 
      padding: 35px 0;
    }
    :global{
      .ant-radio-inner{
        span.ant-radio + *{
          font-family: Poppins-SemiBold; 
        }
        &::after{
          top:50%;
          left: 50%;
          @include wh(8px,8px);
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  :global{
    
    .ant-form-item{
      height: 90px;
      margin-bottom: 0.125rem!important;
    }
    .ant-input-affix-wrapper{
      height: 45px;
      border: 1px solid rgba(213, 222, 236, 0.72);
      border-radius: 5px;
      @include sizeColor(16px,#9D9D9D);
      font-family: Poppins-SemiBold; 
      .ant-input{
        height: auto;
      }
    }
    .ant-form-item-label{
      @include sizeColor(16px,#505050);
      font-family: Poppins-SemiBold; 
    }
    
    .ant-select-selection-item{
      @include sizeColor(16px,#9D9D9D);
      font-family: Poppins-SemiBold; 
    }
    .ant-select{
      padding: 6px 0;
      border: 1px solid rgba(213, 222, 236, 0.72);
      border-radius: 5px;
    }
    .ant-select-focused .ant-select-selector{
      box-shadow: none!important;
    }
    .ant-input{
      height: 45px;
      border-radius: 5px;
      @include sizeColor(16px,#9D9D9D);
      font-family: Poppins-SemiBold; 
    }
  }
  &Desc{
    @include sizeColor(16px,#505050);
    font-family: Poppins-SemiBold; 
    margin-bottom: 15px;
  }
  &Sad{
    :global{
      .ant-form-item{
        height: auto;
      }
      .ant-space-item{
        margin-bottom: 8px;
      }
      .ant-radio-wrapper{
        @include flexStart();
        .ant-radio-inner {
          @include wh(28px,28px);
          top: 0;
        }
      }
      span.ant-radio + *{
        @include sizeColor(20px,#6E746F);
        font-weight: 700;
        margin-left: 10px;
      }
      .ant-radio-checked .ant-radio-inner{
        border:none;
        &::after{
          display: none;
        }
        &::before{
          content: '';
          background: url('~@/assets/images/profile/sad-success.png');
          background-size: 100%;
          @include wh(28px,28px);
          display: inline-block;
        }
      }
      
    }
  }
  &Total{
    text-align: right;
    margin-bottom: 30px;
    @include sizeColor(16px,#505050);
    font-family: Poppins-SemiBold; 
    span{
      color:#5DC899;
      margin-left: 20px;
    }
  }
  &Card{
    padding: 10px 5px 10px 10px;
    border: 1px solid rgba(213, 222, 236, 0.72);
    border-radius: 5px;
  }
  &Title{
    position: relative;
    @include flexStart();
    margin-bottom: 20px;
    &::before{
      content: '';
      @include wh(6px,24px);
      background: #30D988;
      border-radius: 17px;
      display: inline-block;
    }
    span{
      @include sizeColor(24px,#000);
      font-family: Poppins-SemiBold; 
      margin-left: 10px;
    }
  }
  &Footer{
   @include flexCenter();
   margin-top: 50px;
    &Tip{
      margin-right: 20px;
      @include sizeColor(16px,#3DD994);
      font-family: Poppins-SemiBold;
      cursor: pointer;
    }
    span{
      font-size: 16px;
      font-family: Poppins-SemiBold;
    }
    &Save{
      color:#fff;
    }
    &Cancel{
      margin-right: 30px;
      background: #EFEFF3;
      border:1px solid #EFEFF3;
      color:#6E746F;
      
      &:hover{
        background: #EFEFF3;
        border:1px solid #EFEFF3;
        color:#6E746F;
      }
    }
  }
  .billingSuccess{
    text-align: center;
    svg{
      width: 100px;
    }
    p{
      @include sizeColor(30px,#3A574E);
      font-family: Poppins-SemiBold; 
      margin: 80px 0;
    }
    button{
      span{
        font-family: Poppins-SemiBold; 
      }
    }
  }
  .shopify{
    margin-top: 10px;
    position: relative;
    &Lable{
      @include flexStart();
      svg{
        width: 20px;
        margin-right: 10px;
      }
    }
    :global{
      .ant-form-item-control-input{
        position: relative;
        .ant-input{
          padding-left: 45px;
        }
        &::before{
          content: '';
          @include wh(25px,25px);
          @include bgCover('~@/assets/images/profile/website.png');
          @include abLt(10px,50%);
          transform: translate(0,-50%);
          z-index: 2;
        }
      }
    }
  }
}
