// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: #3A574E;
  font-family: Poppins-Regular;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img,
svg {
  vertical-align: top;
}

p {
  margin: 0;
}

input {
  padding: 0;
  border-radius: 0;
  border: none;
  outline: none;
  font-size: inherit;
  background: none;
  color: inherit;
}

textarea {
  resize: none;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

iframe {
  width: 100%;
  height: 100%;
}

.ripple {
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.5) 10%,
      transparent 10.01%
    );
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.3s, opacity 0.5s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }
}

.primary {
  color: var(--brand-color);
}
.text-center {
  text-align: center;
}
.underline {
  text-decoration: underline !important;
}
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &-2 {
    @include multipleLine(2);
  }
  &-3 {
    @include multipleLine(3);
  }
}
.pr {
  position: relative;
}
.flex-ac {
  display: flex;
  align-items: center;
}
.flex-box {
  display: flex;
}
.fx1 {
  flex: 1;
}
.hide {
  display: none !important;
}

svg.fill-color,
svg.fill-color [fill],
svg.fill-color [stroke] {
  fill: currentColor;
  stroke: currentColor;
}
.fill-color-strict [fill] {
  fill: currentColor;
}

.scroll-x {
  position: relative;
  width: 100%;
  margin:0 auto;
  &:hover{
    .next,
    .prev{
      opacity: 1;
      @include transition();
    }
  }
  .next,
  .prev{
    position: absolute;
    opacity: 0.1;
    top:50%;
    z-index: 5;
    background: rgba(0,185,124,1);
    @include wh(60px,60px);
    border-radius: 50%;
    transform: translate(0,-50%);
    cursor: pointer;
    &.next{
      left:80px;
    }
    &.prev{
      right: 80px;
      transform: translate(0,-50%) rotate(180deg);
    }
    img{
      @include wh(36px,36px);
      @include abLt(50%,50%);
      transform: translate(-50%,-50%);
    }
  
    &:after{
      font-size: 28px;
    }
  }
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
.nice-scrollbar {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #d9d9d9;
    &:hover {
      background: #999;
    }
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}
.tiny-scrollbar {
  overflow: auto;
  &::-webkit-scrollbar {
    display: block;
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #d9d9d9;
    &:hover {
      background: #999;
    }
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}
.swiper-scrollbar {
  background: none;

  &-drag {
    background: #d9d9d9;
  }
}
