@font-face {
  font-family: Poppins-SemiBold;
  src: url(../assets/fonts/THICCCBOI-Bold.ttf) format('truetype'),
    url(../assets/fonts/THICCCBOI-Bold.woff2) format('woff2');
}

@font-face {
  font-family: Poppins-Regular;
  src: url(../assets/fonts/THICCCBOI-Regular.ttf) format('truetype'),
    url(../assets/fonts/THICCCBOI-Regular.woff2) format('woff2');
}

@font-face {
  font-family: Poppins;
  src: url(../assets/fonts/THICCCBOI-Black.ttf) format('truetype'),
    url(../assets/fonts/THICCCBOI-Black.woff2) format('woff2');
}

@font-face {
  font-family: sponge;
  src: url(../assets/fonts/spongeboy.ttf) format('truetype'),
    url(../assets/fonts/spongeboy.woff2) format('woff2');
}

@font-face {
  font-family: Inter;
  src: url(../assets/fonts/Inter.ttf) format('truetype');
}
