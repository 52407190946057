// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 30px 0;
  min-height: 100vh;
  background: #F5F7F9;
  overflow: hidden;
}

.iconA,
.iconB {
  position: absolute;
  left: 0;
  bottom: 0;
}
.iconC,
.iconD {
  position: absolute;
  right: 0;
}
.iconA {
  width: 256px;
}
.iconB {
  width: 474px;
}
.iconC {
  top: 301px;
  width: 160px;
}
.iconD {
  top: 49px;
  width: 448px;
}

.container {
  position: relative;
  padding: 118px 162px 0;
  width: 858px;
  height: 837px;
  border-radius: 16px;
  background: #fff;
  z-index: 2;
  &SM {
    height: 673px;

    .top {
      text-align: center;
      svg {
        margin-bottom: 30px;
        width: 40px;
      }
    }
    .title,
    .subTitle {
      margin-left: 0;
      line-height: 1;
    }
    .title {
      justify-content: center;
    }

    .form {
      margin-top: 78px;
    }

    .submit {
      margin-top: 26px;
    }
  }
}

.title,
.subTitle {
  margin-left: 4px;
}
.title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: #000;
  svg {
    width: 24px;
  }
}
.subTitle {
  margin-top: 26px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
}
.stars {
  @include icon('~ico/login/stars.png', 24px);
}

.form {
  margin-top: 135px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
      min-height: 108px;
    }
    .ant-form-item-label > label {
      font-size: 14px;
    }
    .ant-form-item-control-input-content {
      height: 50px;
    }
    .ant-input-password,
    .ant-input {
      height: 100%;
      border-radius: 8px;
    }
  }
}

.link {
  margin-bottom: 24px;
  text-align: right;
  font-size: 14px;
  color: #000;
}

.transfer {
  margin-top: -16px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.submit {
  margin-top: 16px;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
}

.back {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 174px;
  top: 125px;
  width: 153px;
  height: 60px;
  border-radius: 8px;
  font-size: 24px;
  z-index: 2;
  svg {
    margin-right: 24px;
    width: 19px;
  }
}
