// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap {
  width: 1580px;
  padding: 60px 68px 83px;
}

.title {
  font-size: 34px;
  font-weight: 600;
  color: #121928;
  margin-bottom: 6px;
  line-height: 1;
}

.subtitle {
  font-size: 17px;
  color: #909090;
}

.object {
  margin-top: 38px;

  &Header {
    border-radius: 10px;
    background: #f3f6f8;
    padding: 25px 45px;
    @include flexBetween();
    span {
      flex: 1;
      &:nth-child(2) {
        flex: 1.5;
      }
      @include sizeColor(22px, #3a574e);
      font-family: Poppins-SemiBold;
      &:last-of-type {
        opacity: 0;
      }
    }
  }

  &Body {
    margin-bottom: 80px;

    &.auto li .edit {
      b {
        color: var(--brand-color);
      }
      svg {
        display: none;
      }
    }
    ul {
      li {
        @include flexBetween();
        border-bottom: 1px dashed #d9d9d9;
        padding: 25px 45px;
        text-align: left;
        background: rgba(243, 246, 248, 0.40);

        &.active {
          span {
            &:nth-of-type(3) {
              color: #3dd994;
            }
            &:nth-of-type(4) {
              button {
                &:first-of-type {
                  background: rgba(61, 217, 148, 0.1);
                  span {
                    color: #3dd994;
                  }
                }
                &:last-of-type {
                  background: #3dd994;
                  span {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        .done {
          width: 50px;
        }
        > span {
          flex: 1;
          font-family: Poppins-SemiBold;

          &:nth-child(2) {
            flex: 1.5;
          }
          em {
            display: inline-block;
            font-style: normal;
          }
          &.edit {
            em {
              input {
                width: 110px;
                @include sizeColor(24px, #3dd994);
              }

              svg {
                width: 20px;
                vertical-align: middle;
                margin-left: 10px;
              }
              padding: 2px 22px;
              border-radius: 77px;
              background: #fff;
              box-shadow: 0px 6px 22px 0px rgba(58, 87, 78, 0.12);
            }
            .mode,
            .lock {
              width: 30px;
              vertical-align: middle;
              margin-left: 10px;
              &.lock {
                width: 20px;
              }
            }
          }
          &:nth-of-type(1) {
            @include sizeColor(20px, #3a574e);
            svg {
              width: 22px;
              vertical-align: middle;
              margin-left: 10px;
              path,
              circle {
                fill: rgba(58, 87, 78, 1);
              }
            }
          }
          &:nth-of-type(2),
          &:nth-of-type(3) {
            @include sizeColor(24px, #d9d9d9);
            b {
              @include flexStart();
              color: #b6b6b6;
              .grey {
                width: 20px;
                vertical-align: middle;
                margin-left: 10px;
                path,
                circle {
                  fill: #b6b6b6;
                }
              }
            }
          }
          &:nth-of-type(4) {
            text-align: right;
            &.hide {
              visibility: hidden;
            }
            button {
              @include wh(140px, 50px);
              border: none;
              text-align: center;
              text-shadow: none;
              &.toggleBtn {
                width: 250px;
              }
              span {
                font-size: 22px;
                width: 100%;
              }
              &:first-of-type {
                margin-right: 12px;
                background: rgba(183, 183, 183, 0.1);
                span {
                  color: #d9d9d9;
                }
              }
              &:last-of-type {
                background: #d9d9d9;
                span {
                  color: #f3f6f8;
                }
              }
            }
          }
        }
      }
    }
  }
}

.btnWrap {
  margin-top: 83px;
  text-align: center;

  :global(.ant-btn) {
    width: 184px;
    height: 58px;
    border-radius: 50px;
    font-size: 24px;
  }
}
