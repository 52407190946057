// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.profile{
  padding: 0.10417rem 0.15625rem;
  :global{
    .ant-table-thead > tr > th{
      background: #fff;
      @include sizeColor(16px,#6E746F);
      font-family: Poppins-SemiBold; 
      border-bottom: 1px solid #F3F6F8;
      padding: 0 0.08333rem 0.08333rem;
      &::before{
        display: none;
      }
    }
    .ant-table-tbody > tr > td{
      border-bottom: none;
    }
    .ant-table-row{
      &:nth-child(odd){
        background: linear-gradient(0deg, #F9FDF9, #F9FDF9), linear-gradient(0deg, #F9FDF9, #F9FDF9), linear-gradient(0deg, #F9FDF9, #F9FDF9), #F9FDF9;
      }
    }
   
  }
  &Cancel{
    text-align: center;
    button,button:hover{
      background: #fff!important;
      border: 1px solid #fff!important;
      box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05);
     
      span{
        @include sizeColor(16px,#5DC899);
        font-family: Poppins-SemiBold; 
      }
    }
  }
  &Email{
    @include flexStart();
    color: #2DCC98;
    img{
      width: 16px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  &Line{
    @include flexBetween();
  }
  // &Flex1{
  //   width: 500px;
  //   margin-right: 30px;
  //   &:last-of-type{
  //     margin-right: 0;
  //   }
  // }
  // &Flex2{
  //   flex: 1;
  // }
  &Flex1{
    flex: 1;
    &:first-of-type{
      margin-right: 30px;
    }
  }
  &Header{
    @include flexBetween();
    margin-bottom: 16px;
    span{
      &:nth-of-type(1){
        @include sizeColor(16px,#2DCC98);
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
  &Title{
    @include flexCenter();
    margin:0 0 15px 20px;
    &::before{
      content: '';
      @include wh(5px,17px);
      border-radius: 10px;
      background: #3A574E;
      display: inline-block;
      margin-right: 6px;
    }
    span{
      &:nth-of-type(1){
        @include sizeColor(16px,#3A574E);
        font-family: Poppins-SemiBold; 
      }
      &.name{
        border-radius: 22px;
        background: #E0EAE5;
        margin-left: 15px;
        @include sizeColor(16px,#3A574E);
        padding: 2px 10px;
        font-weight: 700;
      }
      &.date{
        @include sizeColor(14px,#3A574E);
        background: #E3FFF2;
        margin-left: 15px;
        border-radius: 10px;
        font-weight: 700;
        padding: 1px 13px;
      }
      // &:nth-of-type(2){
      //   background: #E0EAE5;
      //   border-radius: 22px;
      //   @include sizeColor(16px,#3A574E);
      //   font-weight: 700;
      //   margin-left: 20px;
      //   padding: 3px 15px;
      // }
    }
  }
  &Box{
    background: #FFFFFF;
    box-shadow: 5px 10px 12px 0px rgba(58, 87, 78, 0.10);
    border-radius: 20px;
    overflow: hidden;
    padding: 25px 20px;
    margin-bottom: 35px;
    position: relative;
    &Bm{
      @include abBl(0,0);
      background: #5DC899;
      box-shadow: 5px 5px 10px #F1FAF9;
      @include flexBetween();
      padding: 10px 35px;
      width: 100%;
      span{
        font-family: Poppins-SemiBold; 
        @include sizeColor(16px,#fff);
        &:first-of-type{
          margin-right: 25px;
        }
      }
    }
    &Hg270{
      height: 270px;
      .stripeEdit{
        @include flexCenter();
        color:#3DD994;
        svg{
          cursor: pointer;
          width: 25px;
          margin-left: 10px;
        }
      }
    }
    &Hg350{
      height: 350px;
      
      &::nth-of-type(1){
        ul{
          margin-top:10px;
        }
      }
    }
    ul{
      li{
        padding: 12px 15px;
        &:nth-child(odd){
          background: linear-gradient(0deg, #F9FDF9, #F9FDF9), linear-gradient(0deg, #F9FDF9, #F9FDF9), linear-gradient(0deg, #F9FDF9, #F9FDF9), #F9FDF9;
        }
        @include sizeColor(16px,#505050);
        font-family: Poppins-SemiBold; 
        @include flexBetween();
      }
    }
  }
  .table{
    &Platform{
      @include flexStart();
      img{
        width: 14px;
        margin-right: 10px;
      }
      span{
        @include sizeColor(16px,#505050);
        font-family: Poppins-SemiBold; 
      }
    }
    &Btn{
      width: 160px;
      cursor: pointer;
    }
    &Account{
      @include sizeColor(16px,#505050);
      font-family: Poppins-SemiBold; 
    }
    &Connection{
      font-style: normal;
      background: #E8FFF5;
      border-radius: 22px;
      padding: 2px 10px;
      font-family: Poppins-SemiBold; 
      @include sizeColor(16px,#2DCC98);
      display: inline-block;
      cursor: pointer;
      &Fail{
        background: #FFF3F3;
        color:#ED7B7B;
      }
    }
    &Reconnect{
      button{
        border: none;
        span{
          font-family: Poppins-SemiBold; 
        }
        &:first-of-type{
          background: #f2dc4e;
          margin-right: 10px;
        }
        &:last-of-type{
          background: #FF4747;
        }
      }
    }

  }
  .bill{
    &On{
      border-radius: 22px;
      padding: 2px 8px;
      font-size: 16px;
    }
    &Success{
      background: #E2FFF1;
      color:#2DCC98;
    }
    &Fail{
      background: #FFF3F3;
      color:#ED7B7B;
    }
  }
}
.profileBusiness{
  :global{
    .ant-drawer-title{
      @include sizeColor(16px,#6E746F);
      font-family: Poppins-SemiBold; 
    }
    .ant-drawer-body{
      @include flexStart();
      white-space: nowrap;
      overflow-x:auto;
      width: 90%;
      margin: 0 auto;
      &::-webkit-scrollbar {
        height: 10px;
        display: block;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 6px;
      }

      /*Handle*/
      &::-webkit-scrollbar-thumb {
        background-color: #a0a0a5;
        border-radius: 6px;
      }
    }
  }
  &List{
    display: table;
  }
  &Add{
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    background: #FFFFFF;
    border: 3px dashed #E3E9F1;
    box-shadow: 0px 6px 8px rgba(219, 219, 219, 0.25);
    border-radius: 20px;
    cursor: pointer;
    @include wh(450px,320px);
    flex-direction:column;
    padding-top: 90px;
    &Icon{
      @include wh(56px,56px);
      border-radius: 50%;
      margin: 0 auto;
      @include flexCenter();
      img{
        width: 48px;
      }
    }
    p{
      margin-top: 30px;
      @include sizeColor(16px,#6E746F);
      font-family: Poppins-SemiBold; 

    }
  }
  ul{
    display: inline-block;
    margin-right: 30px;

    li{
      display: inline-block;
     vertical-align: middle;
      cursor: pointer;
      @include wh(450px,320px);
      background: #FFFFFF;
      box-shadow: 0px 6px 8px rgba(219, 219, 219, 0.25);
      border-radius: 20px;
      text-align: center;
      margin-right: 30px;
      border:1px solid rgba(0, 0, 0, 0.06);
      padding-top: 50px;
      position: relative;
      overflow: hidden;
      &.active{
        border: 2Px solid #3DD994;
      }
      &:last-of-type{
        margin-right: 0;
      }
      .profileBusinessPhone{
        @include wh(84px,84px);
      }
      .profileBusinessName{
        font-family: Poppins-SemiBold; 
        @include sizeColor(22px,#5DC899);
        margin: 10px 0;
      }
      .profileBusinessStatus{
        @include sizeColor(16px,#B6B6B6);
      }
      .profileBusinessSuccess{
        background: #3DD994;
        @include wh(100px,100px);
        transform: rotate(45deg);
        @include abTr(-50px,-50px);
        img{
          @include wh(31px,23px);
          @include abLt(30px,70px);
          transform: rotate(-45deg);
        }
      }
    }
  }
}
.profileSpin{
  @include abLt(50%,50%);
}
:global{
  .profileModal{
    .ant-modal-content{
      background: none;
    }
    .business-main{
      width: 1150px; 
      @include abLt(50%,50%);
      transform: translate(-50%,-50%);
      background: #fff;
      border-radius: 16px;
      z-index: 2;
   
      .item-box_lable{
        @include sizeColor(20px,#000);
        &.required{
          &::before{
            content: '*';
            @include sizeColor(16px,#FF4747);
            padding-right: 8px;
  
          }
        }
      }
      &_footer{
        @include abRb(80px,100px);
      }
      &_btn{
        background: #30D988;
        border-radius: 8px;
        border:1px solid #30D988;
        width: 534px;
        height: 50px;
        margin: 120px auto 0 auto;
        display: block;
        span{
          font-size: 16px;
          font-family: Poppins-SemiBold;

        }
      }
      &_type{
        width: 45%;
        .type-title{
          @include sizeColor(20px,#000);
          margin-bottom: 20px;
        }
     
        .ant-radio-group{
          @include flexBetween();
          span.ant-radio + *{
            @include sizeColor(20px,#000);
          }
          .ant-radio-wrapper{
            width: 42%;
            &.ant-radio-wrapper-checked{
              span.ant-radio + *{
                @include sizeColor(20px,#30D988);
              }
            }
            .ant-radio{
              @include abTr(50%,0);
              transform: translate(0, -50%);
            }
          }
        }
      }
      &_item{
        @include flexBetween();
        .item{
          &.w50{
            flex: 0.5;
          }
          .ant-input{
            height: 0.255rem !important;
          }
        }
        .item-box{
          flex: 1;
          &:first-of-type{
            margin-right: 30px;
          }
        }
      }
      &_header{
        position: relative;
        padding: 80px 0 50px 0;
        .header-title{
          text-align: center;
          font-family: Poppins-SemiBold;
          @include sizeColor(24px,#000);
        }
        .header-desc{
          text-align: center;
          margin-top: 20px;
          span{
            display: inline-block;
            padding: 2px 14px;
            background: #ECECEC;
            font-weight: 700;
            border-radius: 5px;
            text-align: center;
            @include sizeColor(18px,rgba(0,0,0,0.8));
          }
        }
  
      }
    }
    .m-start_content-main{
      position: relative;
      @include wh(100%,100%);

      padding: 20px 80px;
      height: 700px;
      position: relative;
      .ant-input-group-addon{
        display: none!important;
      }
      .item{
        padding: 0 5px;
        height: 140px!important;
        position: relative;
        .m-select .ant-select{
          position: relative;
          .ant-select-selection-search{
            width: 100%;
            z-index: 2;
          }
          input{
            height: 100%;
          }
          &::before{
            content: '';
            background: url('~@/assets/images/onboard/select.png') no-repeat;
            @include wh(10px,6px);
            @include abTr(50%,16px);
            background-size: 100%;
            transform: translate(0,-50%);
            z-index: 4;
          }
          &::after{
            content: '';
            @include wh(34px,34px);
            background: #EFEFF3;
            border-radius: 50%;
            @include abTr(50%,5px);
            transform: translate(0,-50%);
          }
          .ant-select-arrow{
            display: none;
          }
        }
        &-before{
          &_hide{
            .beforeInput{
              padding-left: 15px!important;;
            }
            .before{
              display: none!important;
            }
          }
        }
        .before{
          @include abLt(0,0.05208rem);
          z-index: 2;
          padding: 0 12px;
          color:#92929d;
          height: 0.255rem!important;
          border-radius: 0.05208rem 0 0  0.05208rem;
          background-color: #fafafa;
          @include flexCenter();
          border: 1px solid #e2e2ea;
          font-size: 18px;
    
        }
        .ant-spin-dot{
          top:41%!important;
        }
        &-title{
          @include sizeColor(22px,#92929d);
          font-family: Poppins-SemiBold;
          position: relative;
          margin-bottom: 5px;
          @include flexStart();
          img{
            &.small{
              @include wh(20px,auto);
            }
           
            &.industry{
              @include wh(17px,auto);
            }
            @include wh(22px,auto);
            margin-left: 5px;
          }
        }
        .ant-select-selection-item,.ant-select{
          font-size: 0.09333rem!important;
    
        }
        .ant-input,.search-select,.ant-input-number-input{
          margin-top: 0.05208rem!important;;
          height: 0.26958rem;
          border-radius: 0.05208rem;
          border: 1px solid #e2e2ea!important;
          font-size: 0.09333rem;
          font-family: Poppins-Regular, Poppins;
          font-weight: 400;
          color: #92929d;
          &.beforeInput{
            padding-left: 103px;
          }
        }
      
      }
    }
  }
}
.accountMain{
  background: #fff;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  width: 800px;
  padding: 45px 45px;
  overflow: hidden;
  .accountTitle{
    @include flexStart();
    border-radius: 12px;
    border: 2px solid #3DD994;
    padding: 12px 25px;
    margin-bottom: 40px;
    text-transform:capitalize;
    img{
      width: 28px;
      margin-right: 15px;
    }
    font-weight: 700;
    @include sizeColor(22px,rgba(0, 0, 0, 0.80));
  }
  button{
    display: block;
    margin: 20px auto;
    @include wh(140px, 45px);
    span{
      @include sizeColor(16px,#fff);
      font-family: Poppins-SemiBold; 
    }
  }
  .accountContent{
    .label{
      @include sizeColor(16px,#5F6871);
      font-weight: 700;
    }
    
  }
}