// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.chat{
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;

  .left{
    width: 344px;
    border-radius: 0 20px 20px 0;
    height: 100%;
  
    &Main{
      display: flex;
      flex-direction: column;
      padding: 26px 0;
      background: #fff;
      height: 100%;

      .agents{
        padding:0 20px 25px;
        @include flexBetween();
        border-bottom:1px solid #E8E8E8;
        .name{
          @include sizeColor(16px,#000);
          font-family: Poppins-SemiBold;
        }
        .fair{
          @include sizeColor(12px,#000);
          b{
            font-family: Poppins-SemiBold;
            position: relative;
            padding-left:10px;
            margin:0 10px;
            &::before{
              content: '';
              @include wh(1px,20px);
              background: rgba(58, 87, 78, 0.10);
              @include abLt(0,50%);
              transform: translate(0,-50%);
            }
          }
          svg{
            cursor: pointer;
            width: 20px;
          }
        }
      }
      
      .actions{
        &.unactivated{
          // .line{
          //   &.inuse{
          //     &::after{
          //       background: #FFAE4F;
          //     }
          //   }
          //   &::after{
          //     content: '';
          //     @include wh(18px,18px);
          //     @include abTr(50%,15px);
          //     transform: translate(0,-50%);
          //     background: #FF6161;
          //     border:1px solid #fff;
          //     border-radius: 50%;
          //     display: block;
          //   }
          // }
          .btn{
            -webkit-filter: grayscale(.95);
          }
        }
        .line{
          position: relative;
          margin-bottom: 15px;
          &:last-of-type{
            margin-bottom: 0;
          }
        
          .btn{
            @include flexStart();
            margin-top: 30px;
            padding: 0 25px;
            @include sizeColor(16px,#fff);
            font-family: Poppins-SemiBold;
            cursor: pointer;
            color: #BABABA;
            position: relative;
            span{
              margin-left: 15px;
            }
            &:hover,&.active{
              opacity: 1;
              color: var(--brand-color);
              svg{
                -webkit-filter: none;
              }
            }
            .icons{
              position: relative;
              .new{
                @include abLt(27px,-5px);
                width: 16px;
                z-index: 2;
              }
            }
            svg{
              // &:first-of-type{
              //   width: 22px;
              // }
              // &:last-of-type{
                -webkit-filter: grayscale(.95);
                &:first-of-type{
                  width: 22px;
                  margin: 0 10px 0 10px;
                }
            
              // }
            }
          }
        }
      }

      .notice{
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-top: 35px;
        border-top: 1px solid #E8E8E8;
        padding: 35px 17px 0 17px;

        &Title{
          @include flexBetween();
          margin-bottom: 25px;

          .tleft{
            @include sizeColor(14px,#000);
            font-weight: 700;
            span{
              min-width:24px;
              height: 24px;
              background:#FF6161;
              border-radius: 50%;
              text-align: center;
              display: inline-block;
              color:#fff;
              margin-left: 15px;
              line-height: 24px;
            }
          }

          .tright{
            @include flexStart();
            @include sizeColor(14px,#000);
            font-weight: 700;

            :global{
              .ant-select{
                width: 130px;
                .ant-select-selector{
                  height: 36px;
                  border-radius: 12px;
                  border: 1px solid #D9D9D9;
                  background: #FFF;
                  .ant-select-selection-item{
                    line-height: 34px;
                    @include sizeColor(16px,#3A574E);
                   
                  }
                  input{
                    height: 36px;
                  }
                }
                .ant-select-arrow{
                  span{
                    display: none;
                  }
                  @include bgCover('~@img/arrow-2.png');
                  @include abTr(50%, 20px);
                  transform: translate(0, -50%);
                  @include wh(10px, 6px);
                  margin-top: 0;
                }
              }
            }

            > span{
              margin: 0 10px;
            }

            svg{
              width: 14px;
            }

          }
        }

        .list{
          flex: 1;
          overflow-y: scroll;
          // padding-bottom: 80px;
          padding: 10px 14px 0 0;
          position: relative;

          &::-webkit-scrollbar {
            display: block; 
            width: 5px;
            height: 5px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #d9d9d9;
            &:hover {
              background: #999;
            }
          }
          &::-webkit-scrollbar-button {
            display: none;
          }

          :global{
            .ant-empty-image{
              width: 150px;
              height:auto;
              margin: 0 auto;
              display: block;
              svg{
                width: 100%;
              }
            }

            .ant-empty-description{
              @include sizeColor(16px,#000);
              font-family: Poppins-SemiBold;
            }
          }

          &.loading{
            opacity: 0.6;
          }

          .li{
            margin-bottom: 12px;
            border-radius: 16px;
            padding: 20px;
            cursor: pointer;
            position: relative;
            border:1px solid #fff;
            background: #FBFBFB;

            // &.cut{
            //   background:#EEE;
            // }

            // &.iscampaign{
            //   background: rgba(61, 217, 148, 0.10);
            // }

            // &.read{
            //   background: #FBFBFB;
            // }

            &:hover{
              .delete{
               display: block;
              }
            }

            // &.email{
            //   background: #FBFBFB;
            // }

            .delete{
              @include wh(24px,24px);
              @include abTr(-8px,-8px);
              cursor: pointer;
              display: none;
            }
          
            // &.cut,&.read,&.email{
            //   .name,.text,.type{
            //     span{
            //       color: #BABABA;
            //     }
            //   }
            //   .type{
            //     svg{
            //       -webkit-filter: grayscale(.95);
            //     }
            //   }
            // }
           
            // .iscampaign{
            //   @include wh(16px,16px);
            //   @include abTr(24px,24px);
            // }

            &.unread{
              background: rgba(121, 142, 255, 0.10);
              &::after{
                content: '';
                @include wh(14px,14px);
                background: #FF6161;
                border-radius: 50%;
                @include abTr(25px, 25px);
              }
            }

            .title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 15px;

              .name {
                font-size: 16px;
                font-weight: 700;
                color: #000;
              }

              svg {
                width: 30px;
              }
            }

            .type{
              @include flexBetween();
              @include sizeColor(12px,#000);
              &Icon{
                padding: 5px 15px;
                border-radius: 26px;
                background: #FFF;
                @include sizeColor(14px,var(--brand-color));
                @include flexCenter();
                font-family: Poppins-SemiBold;
                font-style: italic;
                .idea{
                  width: 16px;
                  margin-right: 5px;
                  path{
                    fill: var(--brand-color);
                  }
                }
                .status{
                  @include wh(16px,16px);
                  margin-left: 15px;
                }
              }
              // svg{
              //   display: block;
              //   width: 114px;
              //   margin-left: auto;
              // }
            }

            .text{
              @include flexBetween();
              margin: 15px 0;
              span{
                @include sizeColor (14px,#000);
                white-space: nowrap;  
                text-overflow:ellipsis; 
                overflow:hidden;
                width: 90%;
                font-family: Poppins-SemiBold;
                display: inline-block;
              }
              // svg{
              //   display: none;
              //   cursor: pointer;
              //   width: 17px;
              //   margin-right: 12px;
              //   position: absolute;
              //   right: -30px;
              //   top:10px;
              // }
          
            }

            .date{
              text-align: right;
              @include sizeColor(14px,#BABABA);
              margin-top: 12px;
            }

            &.active{
              border:1px solid #3DD994;
              background: #ECFBF4;
              .name,.text,.type{ 
                > span{
                  color:#000!important;
                }
              }
            }

     
            &.campaignTheme{
              &.active{      
                border:1px solid #798EFF;
              }
              background: rgba(121, 142, 255, 0.10);
              .type{
                &Icon{
                  color:#798EFF;
                  .idea{
                    path{
                      fill: #798EFF;
                    }
                  }
                  .status{
                    path{
                      fill: #798EFF;
                    }
                  }
                }
              }
            }

            &.campaignGreen{
              background: rgba(61, 217, 148, 0.10);
              // border:1px solid var(--brand-color);
              .type{
                &Icon{
                  color:var(--brand-color);
                  .idea{
                    path{
                      fill: var(--brand-color);
                    }
                  }
                  .status{
                    path{
                      fill: var(--brand-color);
                    }
                  }
                }
              }
            }
          }

          .sessionLoading{
            @include abLt(50%,50%);
            transform: translate(-50%,-50%);
            width: 120px;
          }
        }
      }
    }
  }

  .right{
    flex: 1;
    position: relative;

    .main{
      padding: 60px 0;
      height: 100%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: block; 
        width: 15px;
        height: 15px;
      }
      
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #d9d9d9;
        &:hover {
          background: #999;
        }
      }

      &::-webkit-scrollbar-button {
        display: none;
      }
    }
  }

  .sendLoading{
    padding: 25px 21px!important;

    svg{
      width: 100px!important;
      transform: scale(2)!important;
    }
  }

  &Popover{
    :global{
      .ant-popover-inner{
        border-radius: 12px;
        .ant-popover-inner-content{
          padding: 5px;
        }
      }
      
      .ant-popover-arrow{
        .ant-popover-arrow-content{
          background: #fff;
          &::before{
            content: '';
            display: none;
          }
        }
      }
    }

    .tipWarp{
      background: rgba(61, 217, 148, 0.10);
      padding: 5px;
      @include flexBetween();
      padding: 25px 15px 10px 15px;
      border-radius: 12px;
      position: relative;

      > .days{
        @include abTr(0,0);
        @include wh(88px,20px);

        span{
          @include abLt(50%,50%);
          transform: translate(-50%,-50%);
          white-space:nowrap;
          font-weight: 700;
          color:#fff;
        }

        svg{
          @include abLt(0,0);
          @include wh(100%,100%);
        }
      }

      .box{
        margin-right: 20px;

        &:last-of-type{
          margin-right: 0;
        }

        div{
          &:first-of-type{
            @include sizeColor(18,#3A574E);
            font-family: Poppins-SemiBold;
          }
          &:last-of-type{
            color:#3A574E;
          }
        }
      }
    }
  }

  .strategistLoad{
    @include abLt(0,0);
    @include wh(100%,100%);
    z-index: 101;
    &::before{
      content: '';
      @include wh(100%,100%);
      @include abLt(0,0);
      background:rgba(0, 0, 0, 0.1);
    }

    .cont{
      width: calc(100% - 342px - 344px);
      height: 100%;
      margin-left: 344px;
      background: #fff;
      @include flexCenter();
      .text{
        @include flexCenter();
        position: relative;
        flex-direction: column;
        z-index: 102;
        svg{
          width: 60px;
          margin-bottom: 20px;
        }
        color:#000;
        @include sizeColor(20px,#000);
        font-weight: 700;
        font-family: Poppins-SemiBold;
      }
    }
  }
}

.loading2{
  @include abLt(50%,50%);
  transform: translate(-50%,-50%);
  width: 120px;
  z-index: 999;
}

.filterDropdown{
  border-radius: 12px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  padding: 0;

  :global{
    .ant-cascader-menu{
      min-width: 130px;
    }
    .ant-cascader-menu-item{
      .ant-cascader-menu-item-content{
        @include sizeColor(16px,#000);
        text-align: center;
        padding: 8px 0;

      }
      .ant-cascader-menu-item-expand-icon{
        .anticon svg{
          margin-top: -6px;
        }
      }
      &.ant-cascader-menu-item-active,&:hover{
        background: #E3FFF3!important;
        .ant-cascader-menu-item-content{
          color:var(--antd-wave-shadow-color);
        }
        .ant-cascader-menu-item-expand-icon{
          .anticon svg{
            path{
              fill: var(--antd-wave-shadow-color);
            }
          }
        }
      }
    }
  }

}

.robot{
  width: 100%;
  margin: 35px 0 55px 0;
  position: relative;

  .txt{
    border-radius: 12px;
    background: #FFF;
    @include sizeColor(20px,#3A574E);
    font-family: Poppins-SemiBold;
    display: inline-block;
    padding: 45px 21px;
    word-wrap:break-word; 
    word-break: keep-all;
    width: calc(100% - 100px);
    position: relative;
    &.center{
      display: block!important;
      margin: 0 auto;
      width: 100%!important;
    }
    .icon2{
      @include wh(64px,64px);
      @include abLt(50%,-32px);
      z-index: 4;
      transform: translate(-50%,0);
    }
    .name{
      @include abLt(5px,-35px);
      @include sizeColor(16px,#000);
      font-weight: 500;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }

    .asset{
      text-align: center;
      img{
        width: 40%;
      }
    }
  }

  > svg{
    width: 74px;
    margin:0 23px 0 0;
  }
}