// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.m-select {
  margin-top: 9px;
 
  .ant-form-item {
    margin-bottom: 0;
    height: 100%;
  }


  .ant-form-item-control,
  .ant-form-item-control-input-content {
    height: 100%;
  }

  .ant-form-item-control-input {
   
    // overflow: hidden;
    &:hover {
      border-color: #00b97c !important;
    }
  }

  .ant-select {
    @include wh(100%, 100%);
    position: relative;
    height: 48px;
    border: 1px solid #e2e2ea;
    border-radius: 7px;
    background: #fff;
    .ant-select-selector {
      height: 100%;
      border-radius: 7px;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      @include sizeColor(17px, rgba(0, 0, 0, 0.85));
    }

    .ant-select-arrow {
      @include bgCover('~@img/arrow-2.png');
      @include abTr(50%, 20px);
      transform: translate(0, -50%);
      @include wh(16px, 7px);
      margin-top: 0;
      // background-color: #e2e2ea;
      // top: 0;
      // margin-top: 0;
      // right: 0;
      // @include flexCenter();
      // color: #212123;
      // border-radius: 0 7px 7px 0;
      span{
        display: none;
      }
    }
   
  }

  &_down {
    box-shadow: 0px 5px 12px 1px rgba(0, 0, 0, 0.15) !important;

    .rc-virtual-list-holder-inner {
      background-color: #fff;
    }
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
  .ant-select-disabled{
    background: #f5f5f5;
    .ant-select-selection-item{
      color: #CACACA;
    }
  }
}
.m-select_down .ant-select-item-option-content{
  position: relative;
  span{
    @include abTr(50%,0);
    transform: translate(0,-50%);
    color:#8E8E8E;
    em{
      font-style: normal;
      color:#000000;
      font-weight: 600;
    }
  }
}