// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.plus {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 5px 9px rgba(135, 135, 135, 0.15);
  cursor: pointer;

  svg {
    width: 30px;
  }
}

.wrap {
  position: relative;
  display: flex;
  // padding: 27px 20px 16px;
  border-radius: 8px;
  border: 1px solid #dee3ea;
  background: #fff;

  .previewBtn {
    position: absolute;
    right: 0;
    top: -55px;
    width: 119px;
    height: 42px;
    border-radius: 8px;
    background: #E1E4EB;
    border-color: #E1E4EB;
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
}

.assets {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 27px 20px;
  // margin-bottom: 46px;
}

.asset {
  position: relative;
  margin-right: 16px;
  margin-bottom: 16px;
  width: 68px;
  height: 68px;
  border-radius: 16px;
  border: 1px solid transparent;
  transition: border-color 0.3s;

  &:last-child {
    margin-right: 0;
  }

  &::before {
    position: absolute;
    right: -5px;
    top: -6px;
    @include icon('~@/assets/icons/delete.svg', 20px);
    cursor: pointer;
    z-index: 2;
  }

  &:hover,
  &.error {
    &::before {
      content: '';
    }
  }

  &:hover {
    border-color: #ff6161;
  }

  &.error::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 16px;
    background: rgba(255, 97, 97, 0.5);
    pointer-events: none;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  :global {
    .ant-image {
      display: block;
      width: 100%;
      height: 100%;

      &-mask {
        border-radius: 16px;
        font-size: 0;
        
        .anticon {
          margin-right: 0;
          font-size: 20px;
        }
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }

  :global(.ant-spin) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 0;
  }
}

.suggestions {
  display: flex;
  flex-direction: column;
  padding: 19px 14px 22px;
  width: 476px;
  height: 457px;
  border-radius: 0px 8px 8px 0px;
  border-left: 1px solid #dee3ea;
  background: #f9f9f9;

  .title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #000;

    > svg {
      width: 17px;
      margin-left: 8px;
    }
  }

  .desc {
    margin-top: 10px;
    height: 73px;
    font-size: 14px;
    line-height: 1.2;
  }

  .cover {
    position: relative;
    align-self: center;
    margin: 26px 0 35px;
    height: 215px;

    > img {
      height: 100%;
      // border-radius: 16px;
      // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    > svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 52px;
    }
  }

  button {
    align-self: center;
    width: 119px;
    height: 42px;
    border-radius: 8px;
    // border-color: #E1E4EB;
    // background: #E1E4EB;
    // color: #000;
    font-size: 18px;
    font-weight: 700;
  }
}

.sample {
  width: 1248px;
  padding: 56px 74px;

  .download {
    position: absolute;
    right: 74px;
    top: 36px;
    width: 164px;
    height: 44px;
    border-radius: 40px;
    font-size: 16px;
    color: #D9D9D9;
    &:hover {
      color: var(--brand-color);
    }

    > svg {
      margin-right: 16px;
      width: 24px;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 700;
    color: #3a574e;
  }

  .desc {
    margin: 12px 0 40px;
    font-size: 14px;
    font-weight: 500;
    color: #606f7f;
    line-height: 1.2;
  }

  .img {
    display: block;
    margin: auto;
    height: 679px;
    border-radius: 16px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .btns {
    margin-top: 50px;
    font-size: 0;
    text-align: center;

    button {
      width: 164px;
      height: 44px;
      border-radius: 44px;
      font-size: 16px;
      font-weight: 600;

      + button {
        margin-left: 43px;
      }
    }
  }
}

:global {
  .ant-image-preview-mask {
    z-index: 1079;
  }
}
