// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.wrap {
  background: #fff;
}

.row {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;

  > svg{
    width: 170px;
    margin-right: 50px;
  }

  &:nth-child(1){
    background: #1A2024;
  }

  &:nth-child(1),
  &:nth-child(2) {
    padding: 0 26px;
    height: 74px;
  }

  &:nth-child(2) {
    justify-content: center;
  }

  &:nth-child(3) {
    justify-content: center;
    height: 52px;
  }
}

// .logo {
//   margin-right: 88px;
//   padding-left: 50px;
//   // line-height: 36px;
//   // font-size: 20px;
//   // font-weight: 700;
//   // color: var(--brand-color);
//   background: url('~@img/logo-black.png') no-repeat;
//   background-size: contain;
//   @include wh(230px,75px);
// }

.groups {
  display: flex;
  height: 100%;
  min-width: 0;
  overflow-x: auto;
  // max-width: 90%;

  .group {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 35px 0 25px;
    min-width: 130px;
    height: 100%;
    text-align: center;
    font-size: 18px;
    font-family: Poppins-SemiBold;
    color: #bababa;
    cursor: pointer;

    &.active {
      flex-shrink: 0;
      // padding-left: 25px;
      color: #fff;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 5px;
        background: #f3f3f3;
      }

      // &::after {
      //   display: none;
      // }

      > svg:first-child path {
        fill: #3DD994;
      }
    }

    .warn{
      margin-left: 10px;
    }

    &.loading::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 5px;
      background: var(--brand-color);
      animation: loading .8s linear infinite both;
      transform-origin: 50%;
    }
    @keyframes loading {
      0% {
        transform: scaleX(0);
      }
      20% {
        transform: scaleX(0);
      }
      80% {
        transform: scaleX(1);
      }
      100% {
        transform: scaleX(1);
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 25px;
      background: #fff;
      opacity: 0.6;
      transform: translate(1px, -50%);
    }
    &:last-child::after {
      display: none;
    }

    > svg:first-child {
      flex-shrink: 0;
      margin-right: 6px;
      width: 22px;
    }

    .edit {
      @include abTr(50%, 12px);
      transform: translate(0, -50%);
      width: 12px;
      display: none;
      cursor: pointer;
    }
    &:hover .edit {
      display: block;
    }

    :global {
      .ant-input {
        padding: 0;
        width: 200px;
        font-size: inherit;
        color: inherit;
        text-align: center;
      }
    }
  }
}

.addSpin {
  flex-shrink: 0;
  margin-top: 8px;
  margin-left: 16px;
  margin-right: 100px;

  :global {
    .ant-spin-dot {
      top: 45% !important;
    }
  }
}

.add {
  width: 46px;
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
  }

  [fill='#3DD994'] {
    fill: var(--brand-color);
  }
}

.user {
  @include flexStart();
  margin-left: auto;
  flex-shrink: 0;
  cursor: pointer;
  div{
    @include wh(42px,42px);
    border-radius: 50%;
    background: var(--brand-color);
    color:#fff;
    @include flexCenter();
    @include sizeColor(16px,#fff);
    font-family: Poppins-SemiBold;
    margin-right: 15px;
  }
  span{
    font-size: 16px;
    font-weight: 700;
    color: var(--brand-color);
  }
}

.out{
  margin-left: auto;
  white-space:nowrap; 
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  color: var(--brand-color);
  margin-left: 15px;
}

.tabs {
  display: flex;
  height: 100%;
}
.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  min-width: 192px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  position: relative;

  em{
    @include wh(13px, 13px);
    background: #FF6161;
    border-radius: 50%;
    @include abTr(15px,15px);
  }

  &:hover {
    color: rgba(61, 217, 148, 0.65);

    .tabIcon {
      filter: grayscale(0.35);
    }
  }

  &.active {
    background: rgba(48, 217, 136, 0.1);
    color: var(--brand-color);

    .tabIcon {
      filter: none;
    }
  }

  .tabIcon {
    margin-right: 16px;
    height: 20px;
    filter: grayscale(1);
  }
}

.unassigned {
  position: absolute;
  right: 35px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;

  .left {
    font-size: 14px;
    font-weight: 600;
    color: #000;

    > span {
      margin: 0 5px;
      font-weight: bold;
      color: var(--brand-color);
    }
  }

  .right {
    // margin-left: 9px;
    display: flex;
    align-items: center;
    // padding: 0 8px 0 14px;
    height: 31px;
    // border-radius: 47px;
    // background: #fff5f5;
    // box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    // &::before {
    //   content: '';
    //   margin: 0 13px;
    //   width: 1px;
    //   height: 20px;
    //   background: rgba(58, 87, 78, 0.10);
    // }

    .badge {
      margin-left: 8px;
      padding: 0 2px;
      min-width: 22px;
      line-height: 22px;
      border-radius: 22px;
      text-align: center;
      background: #FF6161;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
    }
  }
}

.optimizing {
  display: flex;
  align-items: center;
  margin-left: 30px;
  padding-left: 12px;
  padding-right: 16px;
  height: 46px;
  border-radius: 47px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  &.off {
    border: 1px solid rgba(255, 97, 97, 0.1);
    background: rgba(255, 97, 97, 0.1);
    color: #ff6161;

    .countdown {
      background: #ff6161;
    }
  }

  &.on {
    border: 1px solid rgba(61, 217, 148, 0.1);
    background: rgba(61, 217, 148, 0.1);
    color: var(--brand-color);
  }

  svg {
    margin-right: 8px;
    width: 30px;
  }

  .countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 78px;
    height: 34px;
    border-radius: 10px;
    background: var(--brand-color);
    color: #fff;
    font-size: 16px;
  
    .processing {
      font-size: 12px;
      width: 5em;
      &::after {
        content: '';
        animation: processing 2s steps(1) infinite;
      }
    }
  
    @keyframes processing {
      0% {
        content: '';
      }
      25% {
        content: '.';
      }
      50% {
        content: '..';
      }
      75% {
        content: '...';
      }
    }
  }
}

.modal2 {
  width: 937px !important;

  :global {
    .ant-modal-content {
      border-radius: 20px;
    }

    .ant-modal-body {
      padding: 0 0 42px;
    }

    .ant-modal-confirm-body,
    .ant-modal-confirm-btns {
      text-align: center;
    }

    .ant-modal-confirm-title {
      display: flex;
      align-items: center;
      padding-top: 12px;
      padding-left: 56px;
      height: 74px;
      line-height: 1;
      font-size: 16px;
      font-weight: 700;

      &::before {
        content: '';
        margin-right: 12px;
        width: 5px;
        height: 17px;
        border-radius: 5px;
        background: currentColor;
      }
    }

    .ant-modal-confirm-content {
      margin: 44px 0 78px;
    }

    .ant-modal-confirm-btns {
      .ant-btn {
        width: 164px;
        height: 44px;
        border-radius: 58px;
        font-size: 16px;
        font-weight: 700;
        box-shadow: none;

        &-default {
          background: #d9d9d9;
          border-color: #d9d9d9;
          color: #fff;
        }

        + .ant-btn {
          margin-left: 42px;
        }
      }
    }
  }

  &success {
    :global {
      .ant-modal-confirm-title {
        background: rgba(61, 217, 148, 0.1);
        color: var(--brand-color);
      }
    }

    .modal2content .status {
      &Icon {
        border: 1px solid #3dd994;
        background: rgba(61, 217, 148, 0.1);
      }

      &Text {
        color: #3dd994;
      }
    }
  }
  &danger {
    :global {
      .ant-modal-confirm-title {
        background: rgba(255, 97, 97, 0.1);
        color: #ff6161;
      }

      .ant-btn-primary {
        border-color: #ff6161;
        background: #ff6161;
      }
    }

    .modal2content .status {
      &Icon {
        border: 1px solid rgba(255, 97, 97, 0.1);
        background: rgba(255, 97, 97, 0.1);
      }

      &Text {
        color: #ff6161;
      }
    }
  }

  &content {
    display: flex;
    justify-content: center;
    align-items: center;

    .status {
      position: relative;
      margin-right: 46px;

      &Icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 142px;
        height: 142px;
        border-radius: 47px;

        svg {
          width: 80px;
        }
      }

      &Text {
        position: absolute;
        bottom: -19px;
        left: 50%;
        transform: translate(-50%, 100%);
        font-size: 16px;
        font-weight: 700;
      }
    }

    .message {
      font-size: 18px;
      font-weight: 600;
      color: #000;
    }
  }
}
