// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.customModal{
  :global{
    .ant-modal-content{
      border-radius: 12px;
      background: #FFF;
      padding: 40px;
    }
  }
  .header{
    @include sizeColor(14px,#3A574E);
    font-family: Poppins-SemiBold;
    margin-bottom: 20px;
    span{
      @include sizeColor(16px,#2DCC98);
      em{
        margin:0 5px;
        font-style: normal;
      }
    }
  }
  .main{
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 10px 12px 0px rgba(58, 87, 78, 0.10);
    padding: 20px 40px;
    .title{
      position: relative;
      @include sizeColor(16px,#3A574E);
      font-family: Poppins-SemiBold;
      margin-bottom: 30px;
      &::before{
        content: '';
        border-radius: 10px;
        background: #3A574E;
        margin-right: 12px;
      }
    
    }
    .warp{
      @include flexCenter();
      &Left{
        margin-right: 50px;
      }
      :global{
        .ant-form{
          @include flexCenter();
        }
        .ant-form-item{
          width: 700px;
          label{
            @include sizeColor(14px,#3A574E);
            font-family: Poppins-SemiBold;
          }
          input{
            height: 48px;
            border-radius: 8px;
            border: 1px solid rgba(58, 87, 78, 0.20);
            background: #FFF;
            @include sizeColor(14px,#747776);
          }
        }
      }
    }
  }
  .btns{
    text-align: center;
    margin-top: 40px;
    button{
      @include wh(165px,44px);
      &:first-of-type{
        margin-right: 30px;
        background: #D9D9D9; 
        border:#D9D9D9; 
        &:hover{
          background: #D9D9D9; 
        }
      }
      span{
        @include sizeColor(16px,#fff);
        font-family: Poppins-SemiBold;
      }
    }
  }
}