// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
:global{
  .onboard-select{
    .ant-select-item{
      padding: 8px 10px;
      @include sizeColor(16px,#000);
      font-family: Poppins-Regular, Poppins;

      &:hover{
        background: #ECFBF4;
      }
      border:none;
    }
  }
}
.help{
  &Btn{
    @include wh(130px,50px);
    border-radius: 8px;
    @include flexCenter();
    @include sizeColor(16px,#fff);
    background: #30D988;
    margin-left: 15px;
    cursor: pointer;
  }
  &Main{
    width: 88%;
    margin: 0 auto;
    padding-top: 150px;
    position: relative;
    .cpr,.spend{
      border-radius: 8px;
      background: #FFF;
      @include sizeColor(20px,#3DD994);
      @include flexCenter();
      padding: 2px 8px;
      font-family: Poppins-SemiBold;
      &.cpr{
        @include abLt(292px,188px);
      }
      &.spend{
        @include abTr(240px,15px);
      }
    }
    &Txt{
      margin-top: 30px;
      text-align: center;
      @include sizeColor(34px,#3A574E);
      font-family: Poppins-SemiBold;
      @include flexCenter();
      span{
        color:#3DD994;
        padding: 0 5px;
      }
    }
    img{
      width: 100%;
    }
  }
}
.loginOut{
  @include abRb(100px,100px);
  z-index: 2;
  border-radius: 0.04167rem;
  cursor: pointer;
  font-family: Poppins-SemiBold;
  z-index: 5;
  background: #3DD994;
  border: 0.01563rem solid #3DD994;
}
.back{
  @include abLt(100px,100px);
  cursor: pointer;
  @include wh(160px,60px);
  @include flexCenter();
  @include sizeColor(24px,#000);
  z-index: 5;
  background: #FFFFFF;
  border: 1px solid #C6C6C6;
  border-radius: 8px;
  img{
    width: 20px;
    margin-right: 25px;
  }
}
.onboard{
  padding: 20px 0;
  position: relative;
  background: #F5F7F9;
  width: 100vw;
  min-height: 100vh;
  &Pop{
    background: none;
    .onboardMain{
      height: 1100px;
     
      :global {
        .ant-btn{
          top:720px;
        }
      }
      .cancel{
        top:800px;
      }
    }
    &::before,&::after{
      display: none;
    }
  }
  &::before{
    content: '';
    background: url('~@/assets/images/onboard/onboard-left-bg.png') no-repeat;
    @include wh(474px,489px);
    @include abBl(0,0);
    background-size: 100%;
  }
  &::after{
    content: '';
    background: url('~@/assets/images/onboard/onboard-right-bg.png') no-repeat;
    @include wh(448px,646px);
    @include abTr(0,0);
    background-size: 100%;
  }
 
  h3{
    text-align: center;
    font-family: Poppins-SemiBold;
    @include sizeColor(24px,#000);
  }
  .mask{
    @include abLt(0,0);
    @include wh(100%,100%);
    background: rgba(0,0,0,0.4);
    z-index: 8;
  }
  .hidden{
    display: none;
  }
  &Main{
    width: 1350px; 
    height: 1000px;
    background: #fff;
    box-shadow: 0px 4px 18px #F0F2F3;
    border-radius: 16px;
    z-index: 2;
    padding: 50px 100px;
    @include abLt(50%,50%);
    transform: translate(-50%,-50%);
 
    .finsh{
      text-align: center;
      @include sizeColor(34px,#3A574E);
      font-family: Poppins-SemiBold;
      img{
        width: 500px;
        margin-bottom: 80px;
      }
      :global{
        .ant-progress{
          margin-top: 90px;
        }
      }
    }
    .platform{
      position: relative;
      .average{
        position: relative;
        transform: translate(0,-10px);
        img{
          width: 474px;
        }
        &Txt{
          @include abLt(60px,50%);
          transform: translate(0,-50%);
          @include sizeColor(22px,#3A574E);
          font-weight: bold;
          span{
            background: #FFFFFF;
            border-radius: 8px;
            padding: 5px 17px;
            @include sizeColor(26px,#3DD994);
            font-weight: bold;
            margin: 5px 0 0 30px;
          }
        }
      }
      &Error{
        text-align: center;
        @include sizeColor(18px,#FF6161);
        font-weight: bold;
        margin-top: -15px;
      }
      &Name{
        @include sizeColor(18px,#fff);
        cursor: pointer;
        font-weight: bold;
        @include wh(210px,50px);
        @include flexCenter();
        background: #3DD994;
        border: 1px solid #F2F2F2;
        border-radius: 8px;
        margin: 25px auto;
        &.active{
          background: #F1FFF8;
          border:none;
        }
        svg{
          margin-right: 15px;
          @include wh(25px,25px);
        }
      }
    }
    .connected{
      &Main{
        margin-top: 100px;

      }
    }
    &Item{
      display: flex;
      justify-content: space-between;
      height: 140px;
      .selectLabel{
        @include sizeColor(16px,#000);
        font-weight: 700;
        span{
          color:#FF6161;
        }
      }
      .selectItem{
        flex: 1;
        position: relative;
        margin:0 35px 0 0;
        p{
          font-family: Poppins-SemiBold;
        
          svg{
            width: 25px;
            margin-right: 5px;
            vertical-align: middle;
          }
          &.fail{
            color: #ff4d4f;
          }
          &.success{
            color:#3DD994;
          }
          padding-top: 5px;
        }
        :global{
          .m-select{
            margin-bottom: 0!important;  
          }
        }
        &:nth-of-type(2n){
          margin-right: 0;
        }
        .ant-form-item{
          margin-right: 0;
        }
      }
      :global{
        .ant-form-item{
          margin-right:35px;
          &:nth-of-type(2n){
            margin-right: 0;
          }
        }
      }
    }
    :global {
      .ant-form{
        margin-top: 60px;
        .ant-radio-group-outline{
          margin-top: 25px;
          @include flexStart();
          .ant-radio-wrapper{
            &:first-of-type{
              margin-right: 80px;
            }
            span.ant-radio + *{
              transform: translate(0,0);
              @include sizeColor(18px,#000);
            }
            .ant-radio{
              .ant-radio-inner{
                @include wh(20px,20px);
                vertical-align: middle;
                &::after{
                  @include wh(12px,12px);
                  transform: translate(-50%,-50%);
                }
              }
            }
          }
        }
      }
      .ant-btn{
        width: 2.76042rem;
        height: 0.26042rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.04167rem;
        cursor: pointer;
        font-family: Poppins-SemiBold;
        z-index: 5;
        background: #3DD994;
        @include abLt(50%,580px);
        transform: translate(-50%,0);
        border: 0.01563rem solid #3DD994;
        span{
          @include sizeColor(18px,#fff);
        }
        &:hover{
          background: #3DD994;
          border: 0.01563rem solid #3DD994;
        }
      }
      .ant-form-item{
        flex: 1;
      }
      .ant-input,.search-select,.ant-input-number-input{
        height: 0.26958rem;
        border-radius: 0.05208rem;
        border: 1px solid #e2e2ea!important;
        font-size: 0.09333rem;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #000;
      }
      .ant-form-item-required{
        @include sizeColor(16px,#000);
        padding-left: 3px;
        font-weight: bold;
      }
      .ant-input-group-addon{
        height: 0.26958rem;
        border-radius: 0.05208rem 0 0 0.05208rem;
        font-size: 18px;
      }
      .m-select{
        margin-top: 0;
        .ant-form-item-control-input{
          height: 0.26958rem;
        }
        .ant-select{
          position: relative;
          .ant-select-arrow{
            display: none;
          }
          input{
            height: 100%;
          }
          .ant-select-selection-search{
            width: 100%;
            z-index: 2;
          }
          &::before{
            content: '';
            background: url('~@/assets/images/onboard/select.png') no-repeat;
            @include wh(10px,6px);
            @include abTr(50%,16px);
            background-size: 100%;
            transform: translate(0,-50%);
            z-index: 4;
          }
          &::after{
            content: '';
            @include wh(34px,34px);
            background: #EFEFF3;
            border-radius: 50%;
            @include abTr(50%,5px);
            transform: translate(0,-50%);
          }
        }
      }
    }
    .disabled{
      background: #C6C6C6;
      border:none;
      &:hover{
        background: #C6C6C6;
        border:none;
      }
    }
    .cancel{
      top:640px;
      background: #C6C6C6;
      border:none;
    }
  }
}