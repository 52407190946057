// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
.empty{
  @include flexCenter();
  flex-direction: column;
  @include abLt(50%,35%);
  transform: translate(-50%,-50%);

  &.strategy{
    position: static;
    top:0;
    left: 0;
    transform: none;
  }

  .icon{
    width: 224px;
  }
  .title{
    @include sizeColor(24px,#3A574E);
    font-family: Poppins-SemiBold;
    margin: 10px 0;
  }
  .desc{
    @include sizeColor(16px,#3A574E);
    font-weight: 700;
  }
  .strategy{
    margin-top: 30px;
    position: relative;
    .label{
      @include sizeColor(14px, rgba(73, 73, 73, 0.45));
      font-weight: 700;
      margin-right: 10px;
    }

    :global{
      .ant-select{
        .ant-select-selector{
          height: 50px;
          width: 125px;
          border-radius: 12px;
          border: 1px solid #D9D9D9;
          background: #FFF;
          .ant-select-selection-item{
            line-height: 48px;
            text-align: center;
            @include sizeColor(16px,#3A574E);
          }
          input{
            height: 50px;
          }
        }
        .ant-select-arrow{
          span{
            display: none;
          }
          @include bgCover('~@img/arrow-2.png');
          @include abTr(50%, 20px);
          transform: translate(0, -50%);
          @include wh(12px, 6px);
          margin-top: 0;
        }
      }
    }

    .reportDone{
      :global{
        .ant-select-arrow{
          display: none;
        }
        .ant-select-selector{
          border:none;
          .ant-select-selection-item{
            color:var(--brand-color);
          }
        }
      }
    }
    .chatIcon{
      @include abTr(50%,15px);
      transform: translate(0,-50%);
      @include wh(16px,16px);
    }
  }
  .creative{
    @include flexCenter();
    margin-top: 35px;
    &Box{
      @include wh(130px,50px);
      border-radius: 12px;
      border: 1px solid #D9D9D9;
      background: #FFF;
      @include flexCenter();
      @include sizeColor(14px,#3A574E);
      white-space:nowrap;
      cursor: pointer;
      &:hover{
        border: 1px solid #D9D9D9;
        background: #D9D9D9;
        color:#fff;
        svg{
          path{
            fill:#fff;
          }
        }
      }
      svg{
        width: 18px;
        margin-right: 12px;
      }
      &:first-of-type{
        margin-right: 35px;
      }
    }
  }
  .media{
    border-radius: 10px;
    background: #FFF;
    padding: 16px 14px;
    margin-top: 35px;
    @include flexCenter();
    .txt{
      &1{
        border-radius: 7px;
        background: rgba(61, 217, 148, 0.10);
        padding: 5px 10px;
        @include flexCenter();
        font-family: Poppins-SemiBold;
        color: #2DCC98;
      }
      &2{
        width: 150px;
        margin-top: 15px;
        @include flexCenter();
        color:#000;
        font-family: Poppins-SemiBold;
        text-transform:uppercase;
        :global{
          .ant-switch{
            margin-left: 10px;
          }
        }
      }
      svg{
        width: 12px;
        margin-left: 10px;
      }
    }
    .robot{
      width: 31px;
      margin-right: 25px;
    }
  }
}
.chatDropdown{
  border-radius: 12px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  padding: 0;
  :global{
    .ant-select-item {
      &.ant-select-item-option-active, &.ant-select-item-option-selected{
        background: #D9D9D9!important;
        .ant-select-item-option-content{
          color:#3A574E;
          font-family: Poppins-SemiBold;
        }
      }
      .ant-select-item-option-content{
        @include sizeColor(14px,#A8A9A9);
        text-align: center;
        padding: 5px 0;
      }
    }
  }
}

.modal {
  width: 1008px !important;

  :global {
    .ant-modal-content {
      border-radius: 20px;
    }

    .ant-modal-body {
      padding: 98px 0 93px;
    }

    .ant-modal-confirm-body,
    .ant-modal-confirm-btns {
      text-align: center;
    }

    .ant-modal-confirm-title {
      line-height: 1;
      font-size: 42px;
      font-weight: 600;
      color: #374551;
    }

    .ant-modal-confirm-content {
      margin-top: 30px;
      margin-left: 0;
      line-height: 1;
      font-size: 18px;
      font-style: italic;
      color: #a3a9af;
    }

    .ant-modal-confirm-btns {
      margin-top: 52px;

      .ant-btn {
        width: 184px;
        height: 58px;
        border-radius: 58px;
        font-size: 24px;
        font-weight: 500;

        &-default {
          background: #e7e8e9;
          border-color: #e7e8e9;
          color: #3a574e;
          box-shadow: none;
        }

        + .ant-btn {
          margin-left: 42px;
        }
      }
    }
  }
}