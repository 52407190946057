// 字体大小颜色
@mixin sizeColor($fontSize, $fontColor) {
  font-size: $fontSize;
  color: $fontColor;
}

// 单行省略
@mixin oneline() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 背景图片设置
@mixin bgCover($url) {
  background-image: url($url);
  background-size: 100% auto;
  background-repeat: no-repeat;
}

// 宽高
@mixin wh($width, $height) {
  width: $width;
  height: $height;
}

// 绝对定位
@mixin abLt($left, $top) {
  position: absolute;
  left: $left;
  top: $top;
}

@mixin abTr($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin abRb($right, $bottom) {
  position: absolute;
  right: $right;
  bottom: $bottom;
}

@mixin abBl($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}

// 字体对齐
@mixin center($lineHeight) {
  text-align: center;
  line-height: $lineHeight;
}

// flex 水平垂直对齐
@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex 水平对齐 两边布局
@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// flex 水平垂直对齐
@mixin flexStart() {
  display: flex;
  align-items: center;
}

@mixin lineHeight($height) {
  line-height: $height;
  text-align: center;
}

@mixin multipleLine($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin m-box {
  padding: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 19px;
  margin-top: 28px;
  background-color: #fff;
}

@mixin hoverOpacity {
  opacity: 0.65;
  @include transition()
}

@mixin transition {
  transition: all 0.5s;
}

@mixin icon($url, $width, $height: $width) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  background-size: contain;
}

@mixin waveEffect($shadow-color) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 $shadow-color;
    animation: fadeEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &:active::after {
    animation: none;
  }

  &:hover::after {
    animation-duration: 2s, 0.4s;
  }

  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 6px $shadow-color;
    }
  }

  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
}
:root {
  --swiper-navigation-size: 44px;
  color: #3A574E;
}

.swiper {
  display: block;
}

.app-modal{
  .ant-modal-content{
    width: 900px;
    border-radius: 20px;
    @include abLt(50%,50%);
    transform: translate(-50%,-50%);
    .ant-modal-confirm-btns{
      display: none;
    }
  }
}

#root {
  height: 100%;
  background: #F3F6F8;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-main {
    flex: 1;
    overflow: hidden;

    > div:first-child,
    .ka-wrapper,
    .ka-content {
      height: 100%;
    }
    .ka-content {
      position: relative;
      overflow: auto;
    }
  }
}

.mgb0 {
  margin-bottom: 0 !important;
}

.pd0 {
  padding-bottom: 0 !important;
}

.mgt15 {
  margin-top: 15px;
}

.mgt29 {
  margin-top: 29px;
}

.mgt25 {
  margin-top: 25px;
}

.mgt9 {
  margin-top: 9px;
}

.mgt24 {
  margin-top: 24px;
}

.mgt19 {
  margin-top: 19px;
}

.mgt46 {
  margin-top: 46px;
}

.page-loading{
  @include wh(200px,200px);
  @include abLt(50%,50%);
  transform: translate(-50%,-50%);
}

.fade0{
  opacity: 0;
}

.global-empty {
  margin: 30px 0;
  font-size: 18px;

  svg {
    margin-bottom: 16px;
    width: 60px;
  }
}

.onBoardTitle{
  border-radius: 10px;
  background: #F3F6F8;
  padding: 20px 12px;
  @include flexBetween();
  &Name{
    @include sizeColor(24px,#9A9A9A);
    font-weight: 700;
    @include flexStart();
    svg{
      @include wh(42px,42px); 
      margin-right:22px;
    }
  }
  .today{
    @include sizeColor(20px,#3A574E);
    font-weight: 700;
    margin-right: 40px;
  }
}
.onBoardSteps{
  li{
    @include flexBetween();
    @include sizeColor(22px,#3A574E);
    font-family: Poppins-Regular;
    padding: 40px 0 40px 12px;
    border-bottom: 1px dashed #8DC1AA;
    span{
      b{
        border-radius: 34px;
        background: rgba(61, 217, 148, 0.10);
        @include sizeColor(22px,#3DD994);
        font-family: Poppins-SemiBold;
        padding: 5px 13px;
        margin-left: 60px;
      }
    }
    &:last-of-type{
      border:none;
    }
    &.doing{
      font-family: Poppins-SemiBold;
      button{
        background: #3DD994;
        &:hover,&:focus{
          background: #3DD994!important;
        }
        span{
          color:#fff;
        }
      }
    }
    .finsh{
      @include flexStart();
      svg{
        width: 52px;
        margin-left: 20px;
      }
      button{
        @include wh(96px,38px);
        &:hover{
          background: #F3F6F8!important;
        }
        span{
          @include sizeColor(18px,#4A4A4A);
          font-weight: 700;
        }
      }
    }
    button{
      @include wh(144px,52px);
      background: #E7E8E9;
      border:none;
      &:hover,&:focus{
        background: #E7E8E9!important;
      }
      span{
        @include sizeColor(22px,#3A574E);
        font-family: Poppins-SemiBold;

      }
    }
  }
}
.create-chat{
  position: fixed;
  right:80px;
  bottom:150px;
  width: 155px;
  cursor: pointer;
}